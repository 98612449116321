import useSegment from "hooks/useSegment";
import _isEmpty from "lodash/isEmpty";
import { ELEMENT_IDS } from "pages/zen/constants";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import { TPathParams } from "pages/zen/routes";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuestionnaire from "../hooks/useQuestionnaire";
import ComponentProcessor from "../utils/componentProcessor";

type QuestionnairePageProps = {
  name: string;
  slug: string;
  children: JSX.Element;
};

/** Simpler version of `QuestionnairePage`
 * Use this to build a custom page in `Questionnaire` without the need of using form container
 */
const QuestionnairePageWithoutForm = (props: QuestionnairePageProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const segment = useSegment();
  const project = useZenProject();
  const answers = project.onboardingQuestionnaire?.answers || {};
  const { currentPage, setLoaderVisible } = useQuestionnaire();
  const { taskId } = useParams<TPathParams>();
  const task = useTask(Number(taskId));

  const [moreInfoBlock, setMoreInfoBlock] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [pageComponents, setPageComponents] = useState<
    JSX.Element | JSX.Element[]
  >(children);

  const handleButtonActions = async (action: string) => {
    if (action.startsWith("task-action#")) {
      // Long (time consuming) action, display an overlay loader
      setLoaderVisible(true);
      await task.handleTaskAction(action.replace("task-action#", ""));
      setLoaderVisible(false);
    }
  };

  const updateContextData = (eventTarget: any) => {
    const { innerText } = eventTarget as HTMLInputElement;

    if (eventTarget.tagName !== "INPUT") {
      segment.track("zen task cta: clicked", {
        has_siren: project.hasCompanySiren,
        task_id: task.id,
        task_category: task.type.category,
        task_class: task.taskClass,
        task_slug: task.type.slug,
        task_type: task.type.kind,
        task_name: task.type.title,
        answer_label: null,
        button_label: innerText || null,
      });
    }

    const action = (eventTarget as Element).getAttribute("action");
    if (action) {
      handleButtonActions(action);
    }

    const nextDestination = (eventTarget as Element).getAttribute("to");
    if (nextDestination) {
      navigate(`..${nextDestination}`);
    }
  };

  // Handle click event if it was fired on a button
  const onClickHandler = (event: any) => {
    let { target } = event;

    do {
      const clickedOnButton = target.type === "button";

      if (clickedOnButton) {
        updateContextData(target);
        target = undefined;
      } else {
        target = target.parentNode;
      }
    } while (target);
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandler);

    return () => {
      document.removeEventListener("click", onClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!_isEmpty(answers)) {
      const { processedComponents } = ComponentProcessor.injectChildrenData(
        children,
        answers
      );
      setPageComponents(processedComponents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffect(() => {
    const MoreInfoComponent =
      ComponentProcessor.extractMoreInfoComponent(children);
    if (MoreInfoComponent) {
      setMoreInfoBlock(MoreInfoComponent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id={ELEMENT_IDS.QUESTIONNAIRE.FORM}>{pageComponents}</div>
      {moreInfoBlock}
    </>
  );
};

export default QuestionnairePageWithoutForm;
