import { Title } from "@yolaw/ui-kit-components";
import {
  UniversalAdminFees2,
  UniversalAgreement,
  UniversalCapitalIncrease,
  UniversalEconomy,
  UniversalStatuts,
} from "@yolaw/ui-kit-icons";
import styled from "styled-components";

import { CardsList } from "components/CardsListStyles";
import { useSegment } from "hooks";
import FeatureCard from "../../components/FeatureCard";

const Section = styled.section``;

const SectionTitle = styled(Title)`
  margin-bottom: ${(props) => props.theme.spacing.m}px;
`;

const StyledFeatureCard = styled(FeatureCard)`
  height: 100%;
`;

type CardConfigs = {
  Icon: JSX.Element;
  title: string;
  description: string;
  tertiaryButtonRedirectURL: string;
  primaryButtonRedirectURL: string;
};

const CARD_LIST: CardConfigs[] = [
  {
    Icon: <UniversalEconomy size="32" />,
    title: "Compte courant d’associé",
    description: "Financez simplement l’activité de votre société",
    tertiaryButtonRedirectURL:
      "https://www.legalstart.fr/financement/compte-courant-associes/",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/workflow/start?p=160&q=44",
  },
  {
    Icon: <UniversalAdminFees2 size="32" />,
    title: "Dépôt de marque",
    description: "Protégez votre nom et votre logo",
    tertiaryButtonRedirectURL:
      "https://www.legalstart.fr/propriete-intellectuelle/depot-marque/",
    primaryButtonRedirectURL: "https://forms.legalstart.fr/marque/",
  },
  {
    Icon: <UniversalStatuts size="32" />,
    title: "Modifications statutaires",
    description: "Mettez à jour votre société",
    tertiaryButtonRedirectURL:
      "https://www.legalstart.fr/statut-entreprise/modification-statutaire/",
    primaryButtonRedirectURL:
      "https://forms.legalstart.fr/modifications-societe",
  },
  {
    Icon: <UniversalAgreement size="32" />,
    title: "Pacte d’actionnaires",
    description:
      "Anticipez les situations délicates qui peuvent survenir entre actionnaires",
    tertiaryButtonRedirectURL:
      "https://www.legalstart.fr/creation-entreprise/pacte-actionnaires/",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/workflow/start?p=112&q=4",
  },
  {
    Icon: <UniversalCapitalIncrease size="32" />,
    title: "Augmentation du capital",
    description: "Développez votre activité avec Legalstart",
    tertiaryButtonRedirectURL:
      "https://www.legalstart.fr/financement/augmentation-capital/",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/workflow/start?p=158&q=41",
  },
];

enum CardButtonType {
  Tertiary = "tertiary",
  Primary = "primary",
}

const CARD_BUTTON_LABEL = {
  [CardButtonType.Tertiary]: "En savoir plus",
  [CardButtonType.Primary]: "J'en profite",
};

const RecommendationCard = ({ configs }: { configs: CardConfigs }) => {
  const segment = useSegment();

  const {
    Icon,
    title,
    description,
    tertiaryButtonRedirectURL,
    primaryButtonRedirectURL,
  } = configs;

  const onCardButtonClicked =
    (buttonType: CardButtonType, redirectionURL: string) => () => {
      segment.track("formality recommendation: clicked", {
        title: title,
        "button label": CARD_BUTTON_LABEL[buttonType],
      });

      window.open(redirectionURL);
    };

  return (
    <li>
      <StyledFeatureCard
        icon={Icon}
        title={title}
        description={description}
        tertiaryButtonOptions={{
          text: CARD_BUTTON_LABEL[CardButtonType.Tertiary],
          onClick: onCardButtonClicked(
            CardButtonType.Tertiary,
            tertiaryButtonRedirectURL
          ),
        }}
        primaryButtonOptions={{
          text: CARD_BUTTON_LABEL[CardButtonType.Primary],
          onClick: onCardButtonClicked(
            CardButtonType.Primary,
            primaryButtonRedirectURL
          ),
        }}
      />
    </li>
  );
};

const RecommendationsSection = () => {
  return (
    <Section>
      <SectionTitle text="Nos recommandations" type="h2" />
      <CardsList>
        {CARD_LIST.map((cardConfig) => (
          <RecommendationCard key={cardConfig.title} configs={cardConfig} />
        ))}
      </CardsList>
    </Section>
  );
};

export default RecommendationsSection;
