import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import ChatButton from "../components/ChatButton";
import useZenProject from "../hooks/useZenProject";

/** Display the ChatButton (currently Zendesk Chat) at the bottom right of page */
const LegalAssistantAvailableArea = () => {
  const project = useZenProject();
  const shouldDisplayChatButton = !!project.info.zen_subscription_status;

  useEffect(() => {
    if (shouldDisplayChatButton) {
      // Show the chat button
      window.$zopim?.livechat.button.show();
    } else {
      // Ensure the chat is hidden
      window.$zopim?.livechat.hideAll();
    }

    return () => {
      // Hide the chat when moving out of the support area
      window.$zopim?.livechat.hideAll();
    };
  }, [shouldDisplayChatButton]);

  return (
    <>
      <Outlet />
      {shouldDisplayChatButton && <ChatButton />}
    </>
  );
};

export default LegalAssistantAvailableArea;
