import { Route, Routes } from "react-router-dom";

import DecisionsApp from "pages/zen/pages/mandatory-records/DecisionsApp";
import ZenAppProvider from "./ZenAppProvider";
import ZenProjectInitializer from "./ZenProjectInitializer";
import ZenSubscriptionPaymentModal from "./components/Modals/ZenSubscriptionPayment";
import LegalAssistantAvailableArea from "./layout/LegalAssistantAvailableArea";
import { OrderTitleRecordsPage } from "./pages/order-title-records";

const RegistriesApp = () => {
  return (
    <ZenAppProvider>
      <Routes>
        <Route element={<LegalAssistantAvailableArea />}>
          <Route element={<ZenProjectInitializer />}>
            <Route path="titres" element={<OrderTitleRecordsPage />} />
          </Route>
          <Route path="decisions/*" element={<DecisionsApp />} />
        </Route>
      </Routes>

      {/* Modals */}
      <ZenSubscriptionPaymentModal />
    </ZenAppProvider>
  );
};

export default RegistriesApp;
