import React from 'react';
import AskForPostponeReason from './AskForPostponeReason';
import DocumentPreview from '../DocumentPreview';
import InputForm from '../InputForm';
import Succeed from './Succeed';

const configs: DocumentTask.LocalConfig = {
  InputForm: <InputForm />,
  DocumentPreview: <DocumentPreview />,
  AskForPostponeReason: <AskForPostponeReason />,
  SucceedPage: <Succeed />
};

export default configs;
