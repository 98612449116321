import fernet from "fernet";
import _get from "lodash/get";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import downloadFile from "pages/zen/utils/downloadFile";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DocumentGenerationContext } from "../context";
import { getAccessToken } from "utils/cookies";

const formatTextValue = (value: string, type: string) => {
  if (type === "date") {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      // Can not parse the date. Often happens when an invalid range value is entered
      return "";
    }
    const formattedDate = Intl.DateTimeFormat("fr-FR").format(date);
    return formattedDate;
  }

  return value;
};

const decryptDocumentTemplate = (template: string) => {
  try {
    const accessToken = getAccessToken();
    if (!accessToken) throw new Error("Can not decrypt document template");

    // Get the key based on BE encoding formula
    const key = Buffer.from(accessToken.substring(0, 32)).toString("base64");
    // Prepare Fernet secret and token
    const secret = new fernet.Secret(key);
    const token = new fernet.Token({ secret: secret, token: template, ttl: 0 });

    return token.decode();
  } catch (error) {
    console.error(error);
    // Couldn't decrypt the template => return the raw one
    return template;
  }
};

const useDocumentGenerationTask = () => {
  const navigate = useNavigate();
  const project = useZenProject();
  const task = useTask();
  const { currentTaskPath } = useZenRoutes();
  const [isFormReady, setIsFormReady] = useState(false);

  const documentContext = useContext(DocumentGenerationContext);

  const { formData, formFields } = documentContext.state;

  const {
    form: { questions },
    document_template,
  } = (task.details || {}) as DocumentTask.Details;

  useEffect(() => {
    if (questions) {
      documentContext.dispatch({
        type: "UPDATE_FORM_FIELDS",
        payload: questions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  useEffect(() => {
    if (formFields && formData) {
      // Check every field should be filled
      const hasEveryFieldBeenFilled = formFields.every(
        (fieldName) => !!formData[fieldName]
      );
      // update form ready status
      setIsFormReady(hasEveryFieldBeenFilled);
    }
  }, [formData, formFields]);

  const getDecryptedDocumentTemplate = () =>
    decryptDocumentTemplate(document_template);

  const getQuestionType = (
    questionType: DocumentTask.Question["variable_type"]
  ) => {
    switch (questionType) {
      case "date":
      case "number":
      case "time":
        return questionType;
      case "computed_number":
        return "number";
      default:
        return "text";
    }
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    documentContext.dispatch({
      type: "PATCH_FORM_DATA",
      payload: {
        [event.target.name]: formatTextValue(
          event.target.value,
          event.target.type
        ),
      },
    });
  };

  const handleSignatureChange = (keyName: string, signature: string) => {
    documentContext.dispatch({
      type: "PATCH_FORM_DATA",
      payload: {
        [keyName]: signature,
      },
    });
  };

  const generateAndDownloadDocument = async () => {
    documentContext.dispatch({
      type: "PATCH_CONTEXT_STATE",
      payload: {
        isGeneratingDocument: true,
      },
    });

    try {
      // Try to get the action is declared in `main_cta` button
      const actionSlug = _get(task.details, ["buttons", "main_cta", "action"]);
      if (actionSlug) {
        const taskActionResponse = (await task.handleTaskAction(
          actionSlug,
          documentContext.state.formData
        )) as PromiseFulfilledResult<Task.Action.RedirectTo.ToDocumentGeneratedSuccessfully>["value"];

        // Assign generated document url
        const documentUrl = taskActionResponse.document_url;
        documentContext.dispatch({
          type: "PATCH_CONTEXT_STATE",
          payload: {
            isGeneratingDocument: false,
            documentUrl,
          },
        });

        // Download document
        const fileName = `${task.type.slug}-${(
          project.info.company_name || "__NONAME__"
        )
          .replace(" ", "-")
          .toLowerCase()}.pdf`;

        downloadFile(documentUrl, fileName);

        // Go to task's success page
        navigate(`${currentTaskPath}/succeed`);
      } else {
        throw new Error("Missing task action configuration for the button!");
      }
    } catch (error) {
      console.debug(error);
      documentContext.dispatch({
        type: "PATCH_CONTEXT_STATE",
        payload: {
          isGeneratingDocument: false,
        },
      });
      toast.error(
        <>
          <b>Quelque chose s&apos;est mal passé&nbsp;!</b>
          <p>Votre document n&apos;a pas pu être généré&nbsp;!</p>
        </>
      );
    }
  };

  return {
    isFormReady,
    getDecryptedDocumentTemplate,
    getQuestionType,
    handleTextInputChange,
    handleSignatureChange,
    generateAndDownloadDocument,
  };
};

export default useDocumentGenerationTask;
