import { Column, Text, Title } from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import { FC } from "react";
import styled, { css, useTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: 0.5rem;
    padding: ${theme.spacing.s}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing.xs}px;
  flex-grow: 1;
`;

const HeaderImage = styled.figure`
  flex-basis: 62px;
  flex-shrink: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-basis: 100px;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const HeaderBody = styled(Column)`
  && {
    row-gap: 0.5rem;
  }
`;

type PlaceholderProps = {
  image: JSX.Element;
  title: string;
  description: string | JSX.Element;
};

const Placeholder: FC<PlaceholderProps> = ({ image, title, description }) => {
  const theme = useTheme();
  const isMobileM = useIsMobile(theme.breakpoints.m);

  const DescriptionComponent =
    typeof description === "string" ? <Text text={description} /> : description;

  return (
    <Container>
      <Header>
        <HeaderImage>{image}</HeaderImage>
        <HeaderBody>
          <Title text={title} type="h3" />
          {!isMobileM && DescriptionComponent}
        </HeaderBody>
      </Header>
      {isMobileM && DescriptionComponent}
    </Container>
  );
};

export default Placeholder;
