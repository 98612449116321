import { PaymentIntent, SetupIntent } from "@stripe/stripe-js";
import { LegalEntity } from "./legal-entities";

export enum LegacySubscriptionSlug {
  // Assistance Juridiques
  AssistanceJuridique = "assistance-juridique",
  WebConformite = "web-conformite",
  WebAssistanceJuridique = "web-assistance-juridique",
  WebAe = "web-ae",

  // Comptastart
  ComptastartClementine = "comptastart-clementine",
  WebComptastart = "web-comptastart",
  // LS Compta
  LSComptaBasic = "ls-compta-basic",

  // SOJ
  SOJ = "soj",
  SOJ_Asso = "soj-asso",
  SOJ_Modifs = "soj-modifs",
  SOJ_SCI = "soj-sci",
  SOJ_TUP = "soj-tup",

  // Surveillance Marque
  SurveillanceMarque = "surveillance-marque",
  WebSurveillanceMarque = "web-surveillance-marque",

  // Zen
  Zen = "zen",
}

export enum LegacySubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  PausedCollection = "paused_collection",
  Trialing = "trialing",
  Unpaid = "unpaid",
}

declare namespace LegacySubscription {
  type PeriodUnit = "day" | "week" | "month" | "year";

  interface Plan {
    id: number;
    slug: string;
    type_slug: string;
    period: number;
    period_unit: PeriodUnit;
    price_et: number;
    price_it: number;
    trial_period: number;
    trial_period_unit: PeriodUnit;
  }

  interface SubscriptionDetails {
    id: number;
    name: string;
    slug: LegacySubscriptionSlug;
    description: string;
    icon: string;
    version: number;
    plans: Array<Plan>;
  }

  interface SubscriptionItem extends Pick<SubscriptionDetails, "id" | "name"> {
    plan: Plan;
    legal_entity_id: number;
    redirection_url: string;
    status: LegacySubscriptionStatus;
  }

  namespace StripePayment {
    type InitialRequestData = {
      stripe_payment_method_id:
        | PaymentIntent["payment_method"]
        | SetupIntent["payment_method"];
      subscription_type_slug: string;
      subscription_plan_slug: string;
      legal_entity_id?: LegalEntity["id"];
    };

    interface SucceedResponse {
      core_subscription_id: number;
      stripe_subscription_status: LegacySubscriptionStatus;
      stripe_subscription_id: string;
    }

    interface DirectPaymentResponse extends SucceedResponse {
      payment_intent_id: string;
      payment_intent_status: PaymentIntent.Status;
      stripe_client_secret?: string; // Only if payment_intent_status is "requires_action"
    }

    interface IndirectPaymentResponse extends Partial<SucceedResponse> {
      setup_intent_id: string;
      setup_intent_status: SetupIntent.Status;
      stripe_client_secret?: string; // Only if setup_intent_status is "requires_action"
    }

    type InitialRequestResponse =
      | DirectPaymentResponse
      | IndirectPaymentResponse;

    type DirectPaymentConfirmRequestData = InitialRequestData & {
      stripe_payment_intent_id: string;
    };

    type IndirectPaymentConfirmRequestData = InitialRequestData & {
      stripe_setup_intent_id: string;
    };

    type ConfirmRequestData =
      | DirectPaymentConfirmRequestData
      | IndirectPaymentConfirmRequestData;
  }
}

export default LegacySubscription;
