import { PageContentHeader } from "pages/components";
import { PageContentBody } from "pages/components/PageStyles";
import { Outlet, Route, Routes } from "react-router-dom";
import Appointment from "./Appointment";
import LawyerAppointmentBanner from "./LawyerAppointmentBanner";
import QuestionForm from "./QuestionForm";

const PageLayout = () => {
  return (
    <>
      <PageContentHeader
        title="Prendre rendez-vous avec votre juriste"
        subTitle="Grâce à votre abonnement, posez toutes les questions à votre juriste."
      />
      <PageContentBody>
        <LawyerAppointmentBanner />

        <Outlet />
      </PageContentBody>
    </>
  );
};

export const ContactLawyerPage = () => {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route index element={<QuestionForm />} />
        <Route path="appointment" element={<Appointment />} />
      </Route>
    </Routes>
  );
};
