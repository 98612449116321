import { ChevronLeft } from "@yolaw/ui-kit-icons";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledButton = styled.button`
  ${({ theme }) => css`
    background: none;
    border: none;

    display: flex;
    align-items: center;
    column-gap: ${theme.spacing.xxxs}px;
    text-decoration: underline;
    color: ${theme.colors.neutral.darker};
  `}
`;

const FooterBackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <StyledButton onClick={goBack}>
      <ChevronLeft size="0.6rem" /> Précédent
    </StyledButton>
  );
};

export default FooterBackButton;
