import { Row, Text } from "@yolaw/ui-kit-components";
import { ChevronDownFlat } from "@yolaw/ui-kit-icons";
import { useIsMobile } from "hooks";
import { ELEMENT_IDS } from "pages/zen/constants";
import { PATH_TEMPLATES } from "pages/zen/routes";
import React, { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { QuestionnaireContext } from "../context";
import useQuestionnaire from "../hooks/useQuestionnaire";

const NavButton = styled(Row)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxxs}px;
  cursor: pointer;

  .previous-button-icon {
    transform: rotate(90deg);
  }

  .next-button-icon {
    transform: rotate(-90deg);
  }
`;

const NavContainer = styled(Row)`
  ${({ theme }) => css`
    padding: ${theme.spacing.xxxs}px ${theme.spacing.xs}px;

    ${NavButton}.disabled {
      cursor: default;
      color: ${theme.colors.neutral.light};
    }
  `}
`;

const PreviousButton = () => {
  const {
    state: { currentPage },
  } = useContext(QuestionnaireContext);

  const [canGoBack, setCanGoBack] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage) {
      setCanGoBack(!!currentPage.prevPageSlug);
    }
  }, [currentPage]);

  const backToPreviousPage = () => {
    if (!canGoBack || !currentPage?.prevPageSlug) return;
    navigate(currentPage.prevPageSlug);
  };

  return (
    <NavButton
      className={canGoBack ? "" : "disabled"}
      onClick={backToPreviousPage}
    >
      <ChevronDownFlat
        className="previous-button-icon"
        size="0.5em"
        color="currentColor"
      />
      <Text type="SMALL">précédent</Text>
    </NavButton>
  );
};

const NextButton = () => {
  const {
    state: { currentPage },
  } = useContext(QuestionnaireContext);
  const questionnaire = useQuestionnaire();

  const navigate = useNavigate();

  const goToNextPage = () => {
    if (currentPage?.nextPageSlug) {
      navigate(currentPage?.nextPageSlug);
    } else {
      // last question page
      questionnaire?.onComplete && questionnaire.onComplete();
    }
  };

  return (
    <NavButton onClick={goToNextPage}>
      <Text type="SMALL">suivant</Text>
      <ChevronDownFlat
        className="next-button-icon"
        size="0.5em"
        color="currentColor"
      />
    </NavButton>
  );
};

type QuestionnaireNavigatorProps = {
  navigatorVisible: boolean;
};

const QuestionnaireNavigator = ({
  navigatorVisible,
}: QuestionnaireNavigatorProps) => {
  const isMobile = useIsMobile();
  const {
    state: { QuestionnaireComponent, timelinePages },
    dispatch: questionnaireDispatch,
  } = useContext(QuestionnaireContext);
  const { updateCurrentPage } = useQuestionnaire();

  const matchOnboardingQuestionnaire = useMatch(
    `${PATH_TEMPLATES.ONBOARDING}/:pageSlug`
  ); // without task id
  const matchNormalTaskQuestionnaire = useMatch(
    `${PATH_TEMPLATES.TASK_VIEW}/:pageSlug`
  ); // with task id
  const matchPath =
    matchOnboardingQuestionnaire || matchNormalTaskQuestionnaire;

  const buildTimeline = () => {
    let prevPage,
      currentPage,
      nextPage = undefined;
    const pages = React.Children.map(
      QuestionnaireComponent?.props.children,
      (page, index): QuestionnaireTimelinePage => {
        prevPage = QuestionnaireComponent?.props.children[index - 1];
        currentPage = page;
        nextPage = QuestionnaireComponent?.props.children[index + 1];

        return {
          index,
          name: currentPage.props.name,
          slug: currentPage.props.slug,
          nextPageSlug: nextPage?.props.slug,
          prevPageSlug: prevPage?.props.slug,
        };
      }
    );

    questionnaireDispatch({ type: "SET_TIMELINE_PAGES", payload: pages });
  };

  useEffect(() => {
    if (QuestionnaireComponent) {
      buildTimeline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QuestionnaireComponent]);

  useEffect(() => {
    if (timelinePages && matchPath) {
      const currentPage = timelinePages.find(
        (page) => page.slug === matchPath.params.pageSlug
      );
      currentPage && updateCurrentPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, timelinePages]);

  if (isMobile || (timelinePages?.length || 0) <= 1 || !navigatorVisible)
    return null;

  return (
    <NavContainer id={ELEMENT_IDS.QUESTIONNAIRE.NAVIGATOR}>
      <PreviousButton />
      <NextButton />
    </NavContainer>
  );
};

export default QuestionnaireNavigator;
