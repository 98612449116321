import Cookies from "js-cookie";
import { getDomainName } from "./url";

const lsSharedDomain = `.${getDomainName()}`;
const cookieAccessTokenName = "accessToken";
const cookieRefreshTokenName = "refreshToken";

const tokenCookiesOptions = {
  domain: lsSharedDomain,
  path: "/",
}

export const setAccessToken = (accessToken: string) =>
  Cookies.set(cookieAccessTokenName, accessToken, tokenCookiesOptions);
export const setRefreshToken = (refreshToken: string) =>
  Cookies.set(cookieRefreshTokenName, refreshToken, tokenCookiesOptions);

export const getAccessToken = () => Cookies.get(cookieAccessTokenName);
export const getRefreshToken = () => Cookies.get(cookieRefreshTokenName);

export const removeAuthTokens = () => {
  Cookies.remove(cookieAccessTokenName, tokenCookiesOptions);
  Cookies.remove(cookieRefreshTokenName, tokenCookiesOptions);
};

// Admin
const adminCookieAccessTokenName = "adminAccessToken";
const adminCookieRefreshTokenName = "adminRefreshToken";

export const setAdminAccessToken = (accessToken: string) =>
  Cookies.set(adminCookieAccessTokenName, accessToken, tokenCookiesOptions);
export const setAdminRefreshToken = (refreshToken: string) =>
  Cookies.set(adminCookieRefreshTokenName, refreshToken, tokenCookiesOptions);

export const getAdminAccessToken = () => Cookies.get(adminCookieAccessTokenName);
export const getAdminRefreshToken = () => Cookies.get(adminCookieRefreshTokenName);

export const removeAdminAuthTokens = () => {
  Cookies.remove(adminCookieAccessTokenName, tokenCookiesOptions);
  Cookies.remove(adminCookieRefreshTokenName, tokenCookiesOptions);
};
