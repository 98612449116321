import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { injectStyle as injectToastifyStyle } from "react-toastify/dist/inject-style";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

process.env.REACT_APP_SENTRY_DSN &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: process.env.REACT_APP_ENVIRONMENT_NAME !== "production",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 50% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

injectToastifyStyle();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const {
  REACT_APP_CORE_API_URL,
  REACT_APP_WEB_APP_DOMAIN,
  REACT_APP_ZEN_API_URL,
} = process.env;

const envJson = {
  CORE_API_URL: REACT_APP_CORE_API_URL,
  WEB_APP_DOMAIN: REACT_APP_WEB_APP_DOMAIN,
  LEGALZEN_API_URL: REACT_APP_ZEN_API_URL,
};

const renderApp = () => {
  return window.location.pathname.startsWith("/env_var_configuration") ? (
    <p id="env-variables">{JSON.stringify(envJson)}</p>
  ) : (
    <App />
  );
};

root.render(<React.StrictMode>{renderApp()}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
