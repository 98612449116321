import { Accent, Column, Title } from "@yolaw/ui-kit-components";
import { PageContainer } from "pages/components/PageStyles";
import styled, { css, DefaultTheme } from "styled-components";
import thumbnailSrc from "./assets/undraw_time_management_re_tk5w.svg";

const InnerContainer = styled(Column)`
  ${({ theme }: { theme: DefaultTheme }) => css`
    && {
      align-items: center;
      row-gap: ${theme.spacing.m}px;
      text-align: center;
    }
  `}
`;

export const LSCompteProWaitingForSirenPage = () => {
  return (
    <PageContainer>
      <InnerContainer>
        <img
          src={thumbnailSrc}
          alt="LS Compte Pro Waiting For SIREN"
          width={200}
        />
        <Column>
          <Title type="H2">
            Votre <Accent color="information.dark">Compte Pro</Accent> est
            bientôt prêt !
          </Title>
          <Title type="H3">
            Il sera accessible dès que votre société sera immatriculée.
          </Title>
        </Column>
      </InnerContainer>
    </PageContainer>
  );
};
