import coreApiInstance from "../coreApi";

export const getFormalities = async () =>
  coreApiInstance.get<Formality[]>("/my/formalities/");

export const archiveFormalityFunnel = async (funnelId: number) =>
  coreApiInstance.post(`/my/formalities/F${funnelId}/archive/`);

export const unarchiveFormalityFunnel = async (funnelId: number) =>
  coreApiInstance.post(`/my/formalities/F${funnelId}/unarchive/`);
