import { Column } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

export const PageContainer = styled.div`
  max-width: 944px;
  height: 100%;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.l}px) {
      margin: ${theme.spacing.m}px ${theme.spacing.s}px;
    }

    @media (min-width: ${theme.breakpoints.l}px) {
      margin: ${theme.spacing.l}px;
    }
  `}
`;

export const PageContentBody = styled(Column)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.l}px) {
      && {
        row-gap: ${theme.spacing.m}px;
      }
    }

    @media (min-width: ${theme.breakpoints.l}px) {
      && {
        row-gap: ${theme.spacing.l}px;
      }
    }
  `}
`;
