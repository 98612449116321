import { Button, Column } from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import { ELEMENT_IDS } from "pages/zen/constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import GenerateAndDownloadButton from "./GenerateAndDownloadButton";

const MainContainer = styled(Column)`
  white-space: pre-line;
`;

const PreviewContainer = styled(Column)`
  width: fit-content;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.lightest};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.l}px ${theme.spacing.xl}px;
    }
    @media (max-width: ${theme.breakpoints.m}px) {
      max-width: 100%;
      padding: ${theme.spacing.xs}px;
    }
    color: ${theme.colors.secondary.main};
    row-gap: ${theme.spacing.s}px;
  `}
`;

const StyledButton = styled(Button)`
  margin-bottom: 80px;
`

const MobileBottomStickyContainer = styled.div`
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;

  ${({ theme }) => css`
    padding: ${theme.spacing.xs}px;
    box-shadow: ${theme.shadows.blue.stronger};
    border-radius: ${theme.borderRadius.m}px ${theme.borderRadius.m}px 0 0;
  `};
`;

type DocumentPreviewContainerProps = {
  id?: string;
};

const DocumentPreviewContainer = ({
  id,
  children,
}: React.PropsWithChildren<DocumentPreviewContainerProps>) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <MainContainer>
      <PreviewContainer id={id}>{children}</PreviewContainer>

      {isMobile && (
        <>
          <StyledButton variant="tertiary" onClick={() => navigate(-1)}>
            Modifier mes informations
          </StyledButton>
          <MobileBottomStickyContainer id={ELEMENT_IDS.STICKY_BOTTOM_BAR}>
            <Column>
              <GenerateAndDownloadButton />
            </Column>
          </MobileBottomStickyContainer>
        </>
      )}
    </MainContainer>
  );
};

export default DocumentPreviewContainer;
