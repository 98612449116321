import { FC, memo } from "react";
import styled from "styled-components";

import { HEADER_MOBILE_MIN_HEIGHT } from "./header/HeaderMobile";

const HEADER_DESKTOP_MIN_HEIGHT = 72;

type StyledSidebarProps = {
  $isOpen?: boolean;
};

const StyledSidebar = styled.div<StyledSidebarProps>`
  z-index: 4000;
  grid-column: sidebar-start / main-content-end;
  grid-row: sidebar-start / sidebar-end;
  background-color: ${(props) => props.theme.colors.common.white};
  box-shadow: ${(props) => props.theme.shadows.blue.medium};
  translate: ${(props) => (props.$isOpen ? 0 : "-105%")};
  transition: translate 0.3s ease;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-column: sidebar-start / sidebar-end;
  }
`;

const SidebarInner = styled.div`
  position: sticky;
  top: ${HEADER_MOBILE_MIN_HEIGHT}px;
  height: calc(100dvh - ${HEADER_MOBILE_MIN_HEIGHT}px);
  overflow-y: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    top: ${HEADER_DESKTOP_MIN_HEIGHT}px;
    height: calc(100vh - ${HEADER_DESKTOP_MIN_HEIGHT}px);
  }
`;

type SidebarProps = {
  open?: boolean;
} & React.PropsWithChildren;

export const Sidebar: FC<SidebarProps> = memo(({ open, children }) => {
  return (
    <StyledSidebar $isOpen={open} className="sidebar">
      <SidebarInner>{children}</SidebarInner>
    </StyledSidebar>
  );
});
