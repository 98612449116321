import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { format, getMonth, getYear, setMonth } from "date-fns";
import styled, { css } from "styled-components";
import { ChevronDownFlat } from "@yolaw/ui-kit-icons";
import { Row, TextField } from "@yolaw/ui-kit-components";
registerLocale("fr", fr);

const Navigation = styled(Row)`
  && {
    align-items: center;
    column-gap: ${(props) => props.theme.spacing.xxxs}px;
  }
`;

const PrevButton = styled.button`
  padding: ${(props) => props.theme.spacing.xxxxs}px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    rotate: 90deg;
  }
`;

const NextButton = styled(PrevButton)`
  scale: -1;
`;

const PagerBox = styled.div`
  display: flex;
  margin-left: auto;
`;

const SelectBox = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 50%;
    right: 8px;
    translate: 0 -50%;
    pointer-events: none;
  }
`;

const SelectInput = styled.select`
  ${({ theme }) => css`
    appearance: none;
    padding: ${theme.spacing.xxxxs}px ${theme.spacing.s}px
      ${theme.spacing.xxxxs}px ${theme.spacing.xxxs}px;
    border: none;
    border-radius: ${theme.borderRadius.xs}px;
    background-color: ${theme.colors.neutral.lightest};
    font-size: 16px;
    line-height: 1.5;
  `}
`;

const Container = styled.div`
  z-index: 1;
  ${({ theme }) => css`
    .react-datepicker-wrapper {
      display: block;
    }

    .react-datepicker__tab-loop {
      position: fixed;
    }

    .react-datepicker__input-container {
      svg {
        max-width: none;
        color: inherit;

        path {
          fill: currentColor;
        }
      }
    }

    .react-datepicker {
      min-height: 274px;
      border: none;
      color: inherit;
      font-family: inherit;
      border-radius: ${theme.borderRadius.s}px;
      box-shadow: ${theme.shadows.blue.small};
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      padding-top: 0;
    }
    .react-datepicker-popper[data-placement^="top"] {
      padding-bottom: 0;
    }

    .react-datepicker__header--custom {
      padding: ${theme.spacing.xs}px ${theme.spacing.xs}px 0
        ${theme.spacing.xs}px;
      background-color: ${theme.colors.common.white};
      border-bottom: 0;
      border-top-left-radius: ${theme.borderRadius.s}px;
      border-top-right-radius: ${theme.borderRadius.s}px;
    }

    .react-datepicker__month {
      display: flex;
      flex-direction: column;
      row-gap: 1px;
      margin: 0 ${theme.spacing.xs}px ${theme.spacing.xs}px
        ${theme.spacing.xs}px;
      margin-top: 1px;
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
      display: flex;
      column-gap: 1px;
    }

    .react-datepicker__day-names {
      margin-bottom: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: unset;
      min-width: 2rem;
      margin: 0;
      padding: ${theme.spacing.xxxxs}px;
      color: inherit;
      line-height: unset;
    }

    .react-datepicker__day-name {
      font-size: 0.75rem;
      line-height: 1.33;
      text-transform: capitalize;
    }

    .react-datepicker__day {
      min-height: 1.875rem;
      font-size: 0.875rem;
      outline: 1px solid ${theme.colors.neutral.lightest};

      &:hover,
      &--keyboard-selected {
        border-radius: 0;
        background-color: ${theme.colors.neutral.lightest};
      }

      &--today {
        color: ${theme.colors.common.main};
      }

      &--selected,
      &--selected:hover {
        background-color: ${theme.colors.common.main};
        color: ${theme.colors.common.white};
        border-radius: 0;
      }

      &--outside-month {
        visibility: hidden;
        pointer-events: none;
      }
    }
  `}
`;

type LsDatePickerProps = {
  onChange: (date: Date) => void;
  label?: string;
  minYear?: number;
  maxYear?: number;
  defaultValue?: Date;
  value?: Date;
  className?: string;
};

const LsDatePicker: React.FC<LsDatePickerProps> = ({
  onChange,
  label,
  minYear = new Date().getFullYear() - 1,
  maxYear = new Date().getFullYear() + 1,
  defaultValue = new Date(),
  value = new Date(),
  className = "ls-date-picker",
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const years = Array(maxYear - minYear + 1)
    .fill(null)
    .map((_, i) => minYear + i);

  const months = new Array(12).fill(null).map((_, i) => {
    const month = format(setMonth(new Date(), i), "MMMM", {
      locale: fr,
    });
    return `${month.charAt(0).toUpperCase()}${month.substring(1)}`;
  });

  const handleSelectMonthChange =
    (changeMonth: (month: number) => void) =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      changeMonth(months.indexOf(event.target.value));
    };

  const handleSelectYearChange =
    (changeYear: (year: number) => void) =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      changeYear(Number(event.target.value));
    };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <Container className={className}>
      <DatePicker
        locale="fr"
        dateFormat="dd/MM/yyyy"
        customInput={
          <TextField
            name="decision-date"
            label={label}
            showLabel={!!label}
            placeholder="Placeholder"
            leftIcon="CalendarFlat"
          />
        }
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
            fn: () => new Promise(() => null),
          },
        ]}
        showPopperArrow={false}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <Navigation>
            <SelectBox>
              <SelectInput
                value={months[getMonth(date)]}
                onChange={handleSelectMonthChange(changeMonth)}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </SelectInput>
              <ChevronDownFlat size="8" />
            </SelectBox>
            <SelectBox>
              <SelectInput
                value={getYear(date)}
                onChange={handleSelectYearChange(changeYear)}
              >
                {years.map((option: any) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </SelectInput>
              <ChevronDownFlat size="8" />
            </SelectBox>

            <PagerBox>
              <PrevButton
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ChevronDownFlat size="12" />
              </PrevButton>
              <NextButton
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ChevronDownFlat size="12" />
              </NextButton>
            </PagerBox>
          </Navigation>
        )}
        selected={selectedDate}
        onChange={handleDateChange}
      />
    </Container>
  );
};

export default LsDatePicker;
