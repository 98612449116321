import { Row } from "@yolaw/ui-kit-components";
import { css, styled } from "styled-components";

const BottomStickyBar = styled(Row)`
  background-color: white;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.neutral.lightest};
    box-shadow: ${theme.shadows.blue.medium};

    @container (max-width: ${theme.breakpoints.l}px) {
      padding: ${theme.spacing.xxxs}px ${theme.spacing.xs}px;

      && {
        flex-direction: column-reverse;
        row-gap: 8px;
      }
    }

    @container (min-width: ${theme.breakpoints.l}px) {
      padding: ${theme.spacing.xxxs}px ${theme.spacing.s}px;
    }
  `}
`;

export default BottomStickyBar;
