import { BurgerPointFlat } from "@yolaw/ui-kit-icons";
import Popover from "components/Popover/Popover";
import PopoverContent from "components/Popover/PopoverContent";
import PopoverTrigger from "components/Popover/PopoverTrigger";
import { FormalityStatus } from "contexts/formalities";
import { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { useFormality } from "../useFormality";

const MenuTriggerButton = styled.button`
  flex-shrink: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  background-color: white;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.lightest};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
  `}
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  padding: ${(props) => props.theme.spacing.xxxs}px;

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.neutral.lightest};
  }
`;

const MenuItemButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.dark};
    width: 100%;
    padding: ${theme.spacing.xxxs}px;
    background-color: transparent;
    border: none;
    border-radius: ${theme.borderRadius.xs}px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.neutral.lightest};
    }
  `}
`;

type OptionsMenuContentProps = {
  closeMenu: VoidFunction;
} & FormalityOptionsMenuProps;

const OptionsMenuContent = ({
  formality: rawFormality,
  closeMenu,
}: OptionsMenuContentProps) => {
  const formality = useFormality(rawFormality)
  const archiveFormality = () => {
    formality.archiveFormalityFunnel();
  };

  return (
    <MenuContainer>
      <MenuList>
        <MenuItem>
          <MenuItemButton onClick={archiveFormality}>
            Archiver la démarche
          </MenuItemButton>
        </MenuItem>
      </MenuList>
    </MenuContainer>
  );
};

type FormalityOptionsMenuProps = {
  formality: Formality;
};

const FormalityOptionsMenu = ({ formality }: FormalityOptionsMenuProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { status } = formality;

  // Currently, this menu is only available for "Draft" formality
  if (status !== FormalityStatus.Draft) return null;

  const closeMenu = () => setOpen(false);
  const openMenu = () => {
    setOpen(true);
  };

  return (
    <Popover placement="bottom-end" open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <MenuTriggerButton onClick={openMenu}>
          <BurgerPointFlat size="16" color={theme.colors.neutral.dark} />
        </MenuTriggerButton>
      </PopoverTrigger>
      <PopoverContent>
        <OptionsMenuContent formality={formality} closeMenu={closeMenu} />
      </PopoverContent>
    </Popover>
  );
};

export default FormalityOptionsMenu;
