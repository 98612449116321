import { Button, Text } from "@yolaw/ui-kit-components";
import { CancelButton } from "components/FormStyles";
import Modal from "components/modal/Modal";
import React from "react";
import styled from "styled-components";
import useDecisionViewContext from "../decision-view/hooks/useDecisionViewContext";
import useDocument from "../decision-view/hooks/useDocument";
import useDecision from "../hooks/useDecision";

const ContentContainer = styled.div`
  text-align: center;
`;

type DocumentDeleteModalProps = {
  open: boolean;
};

const DocumentDeleteModal: React.FC<DocumentDeleteModalProps> = ({ open }) => {
  const decision = useDecision();
  const { openingModal, closeModal } = useDecisionViewContext();
  const document = useDocument(openingModal?.context.document?.id || 0);

  const deleteDocument = () => {
    if (decision && document) {
      decision.deleteDocument(document.id);
    }
    closeModal();
  };

  const FooterContent = (
    <>
      <CancelButton
        formMethod="dialog"
        onClick={closeModal}
        leftIcon="ChevronDownFlat"
      >
        Annuler
      </CancelButton>
      <Button type="submit" onClick={deleteDocument}>
        Supprimer
      </Button>
    </>
  );

  return (
    <Modal
      onClose={closeModal}
      isOpen={open}
      title="Supprimer le document"
      FooterContent={FooterContent}
    >
      <ContentContainer>
        <Text>
          Êtes-vous sûr de vouloir supprimer votre document{" "}
          <strong>"{document?.name}"</strong> ?
        </Text>
      </ContentContainer>
    </Modal>
  );
};

export default React.memo(DocumentDeleteModal);
