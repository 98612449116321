import { isBefore } from "date-fns";
import _compact from "lodash/compact";
import _set from "lodash/set";
import React from "react";
import { hasSeenZenIntro, setHasSeenZenIntro } from "./utils/project";

export enum ZenDashboard {
  LegalObligationCalendar = "zen legal obligation calendar",
  AdministrativeDashboard = "zen administrative dashboard",
}

export type ProjectContextState = {
  currentDashboard: ZenDashboard;
  onboardingQuestionnaire: OnboardingQuestionnaire | null;
  shouldDisplayZenIntro: boolean;
  info: Project;
  tasks: Array<TaskItem>;
  legalObligationTasks: {
    // Pre-SIREN sections
    preSirenTaskIds: Array<TaskItem["id"]>;
    postSirenTaskIds: Array<TaskItem["id"]>;
    // Post-SIREN sections
    doneTaskIds: Array<TaskItem["id"]>;
    overdueTaskIds: Array<TaskItem["id"]>;
    todoTaskIds: Array<TaskItem["id"]>;
  };
  administrativeTasks: {
    remunerationTaskIds: Array<TaskItem["id"]>;
    protectionTaskIds: Array<TaskItem["id"]>;
    administrativeTaskIds: Array<TaskItem["id"]>;
  };
  isGeneratingTasks: boolean;
};

const initialState: ProjectContextState = {
  currentDashboard: ZenDashboard.LegalObligationCalendar,
  onboardingQuestionnaire: null,
  shouldDisplayZenIntro: false,
  info: {
    id: 0,
    company_name: null,
    company_type: null,
    company_siren: "",
    funnel_id: 0,
    has_kbis: false,
    kbis_url: null,
    legalentity_id: null,
    zen_subscription_status: "freemium",
    aj_subscription_status: null,
    cs_subscription_status: null,
    service_id: 0,
    ratings: [],
  },
  tasks: [],
  legalObligationTasks: {
    preSirenTaskIds: [],
    postSirenTaskIds: [],
    doneTaskIds: [],
    overdueTaskIds: [],
    todoTaskIds: [],
  },
  administrativeTasks: {
    remunerationTaskIds: [],
    protectionTaskIds: [],
    administrativeTaskIds: [],
  },
  isGeneratingTasks: false,
};

type SetCurrentDashboard = {
  type: "SET_CURRENT_DASHBOARD";
  payload: ZenDashboard;
};

type SetOnboardingQuestionnaireAction = {
  type: "SET_ONBOARDING_QUESTIONNAIRE";
  payload: OnboardingQuestionnaire;
};

type PatchAnswersAction = {
  type: "PATCH_ANSWERS";
  payload: {
    key: string;
    value: Partial<QuestionnaireAnswers>;
  };
};

type HideZenIntroAction = {
  type: "HIDE_ZEN_INTRO";
};

type ProjectDispatchAction =
  | SetCurrentDashboard
  | SetOnboardingQuestionnaireAction
  | PatchAnswersAction
  | HideZenIntroAction
  | {
      type: "SET_PROJECT_INFO";
      payload: Project;
    }
  | {
      type: "SET_TASKS";
      payload: ProjectContextState["tasks"];
    }
  | {
      type: "UPDATE_TASK";
      payload: TaskItem;
    }
  | {
      type: "SET_IS_GENERATING_TASKS";
      payload: boolean;
    }
  | {
      type: "LOG_ZEN_RATINGS";
      payload: ZenRating;
    };

const today = new Date();

const _isPendingSiren = (
  task: TaskItem,
  company_siren: Project["company_siren"]
) => task.type.need_siren === true && !company_siren;

const _isOverdue = (due_date: TaskItem["due_date"]) =>
  due_date && isBefore(due_date, today);

/** Task available during company creation process (before having SIREN) */
const _extractPreSirenTaskIds = (
  tasks: TaskItem[],
  company_siren: Project["company_siren"]
) =>
  _compact(
    tasks.map(
      (task) =>
        !task.is_done && !_isPendingSiren(task, company_siren) && task.id
    )
  );

/** Task available after the company is created successfully (after having SIREN) */
const _extractPostSirenTaskIds = (
  tasks: TaskItem[],
  company_siren: Project["company_siren"]
) =>
  _compact(
    tasks.map(
      (task) => !task.is_done && _isPendingSiren(task, company_siren) && task.id
    )
  );

/** Tasks overdue and not done are listed in "Overdue tasks" section. */
const _extractOverdueTaskIds = (tasks: TaskItem[]) =>
  _compact(
    tasks.map((task) => !task.is_done && _isOverdue(task.due_date) && task.id)
  );

/** Task has been marked as done */
const _extractDoneTaskIds = (tasks: TaskItem[]) =>
  _compact(tasks.map((task) => task.is_done && task.id));

/** Task hasn't been marked as done and not overdue*/
const _extractTodoTaskIds = (tasks: TaskItem[]) =>
  _compact(
    tasks.map((task) => !task.is_done && !_isOverdue(task.due_date) && task.id)
  );

const _groupLegalObligationTasks = (
  tasks: TaskItem[],
  company_siren: Project["company_siren"]
): ProjectContextState["legalObligationTasks"] => {
  const legalObligationTasks = tasks.filter((task) =>
    ["LEGAL_OBLIGATION", "FISCALITÉ"].includes(task.type.category.toUpperCase())
  );

  return {
    preSirenTaskIds: _extractPreSirenTaskIds(
      legalObligationTasks,
      company_siren
    ),
    postSirenTaskIds: _extractPostSirenTaskIds(
      legalObligationTasks,
      company_siren
    ),
    doneTaskIds: _extractDoneTaskIds(legalObligationTasks),
    overdueTaskIds: _extractOverdueTaskIds(legalObligationTasks),
    todoTaskIds: _extractTodoTaskIds(legalObligationTasks),
  };
};

const _groupAdministrativeTasks = (
  tasks: TaskItem[]
): ProjectContextState["administrativeTasks"] => {
  return {
    remunerationTaskIds: _compact(
      tasks.map(
        (task) =>
          ["TASK_REMUNERATION", "RÉMUNÉRATION"].includes(
            task.type.category.toUpperCase()
          ) && task.id
      )
    ),
    protectionTaskIds: _compact(
      tasks.map(
        (task) =>
          ["TASK_PROTECTION", "ASSURANCE"].includes(
            task.type.category.toUpperCase()
          ) && task.id
      )
    ),
    administrativeTaskIds: _compact(
      tasks.map(
        (task) =>
          ["TASK_ADMINISTRATIVE", "ADMINISTRATIF"].includes(
            task.type.category.toUpperCase()
          ) && task.id
      )
    ),
  };
};

const reducer = (
  state: ProjectContextState,
  action: ProjectDispatchAction
): ProjectContextState => {
  switch (action.type) {
    case "SET_CURRENT_DASHBOARD":
      return {
        ...state,
        currentDashboard: action.payload,
      };
    case "SET_ONBOARDING_QUESTIONNAIRE":
      return {
        ...state,
        onboardingQuestionnaire: action.payload,
        // as of now, we don't need to display the zen intro again
        // so use the `hasSeenZenIntro` checker instead
        // shouldDisplayZenIntro: action.payload.status !== 'done'
      };
    case "PATCH_ANSWERS":
      return {
        ...state,
        onboardingQuestionnaire: {
          ...((state.onboardingQuestionnaire || {}) as OnboardingQuestionnaire),
          answers: _set(
            state.onboardingQuestionnaire?.answers || {},
            action.payload.key,
            action.payload.value
          ),
        },
      };
    case "HIDE_ZEN_INTRO":
      // Set a value in localstorage to check again in the future
      setHasSeenZenIntro(state.info.id);
      return {
        ...state,
        shouldDisplayZenIntro: false,
      };
    case "SET_PROJECT_INFO":
      return {
        ...state,
        info: action.payload,
        shouldDisplayZenIntro:
          !hasSeenZenIntro(action.payload.id) && !!action.payload.company_siren,
      };
    case "SET_TASKS":
      return {
        ...state,
        tasks: action.payload,
        legalObligationTasks: _groupLegalObligationTasks(
          action.payload,
          state.info.company_siren
        ),
        administrativeTasks: _groupAdministrativeTasks(action.payload),
      };
    case "UPDATE_TASK": {
      const newTasks = state.tasks.length
        ? state.tasks.map((task) =>
            task.id === action.payload.id ? action.payload : task
          ) // There is some task or task list is fetched
        : [action.payload]; // There is no task in the list at all
      return {
        ...state,
        tasks: newTasks,
        legalObligationTasks: _groupLegalObligationTasks(
          newTasks,
          state.info.company_siren
        ),
        administrativeTasks: _groupAdministrativeTasks(newTasks),
      };
    }
    case "SET_IS_GENERATING_TASKS":
      return {
        ...state,
        isGeneratingTasks: action.payload,
      };
    case "LOG_ZEN_RATINGS":
      return {
        ...state,
        info: {
          ...state.info,
          ratings: [...(state.info.ratings || []), action.payload],
        },
      };
    default:
      throw new Error("[ZenContext] unexpected action type");
  }
};

type ProjectContextType = {
  state: ProjectContextState;
  dispatch: React.Dispatch<ProjectDispatchAction>;
};

const Context = React.createContext<ProjectContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "ZenContext";

const ZenContext = {
  Context,
  initialState,
  reducer,
};
export default ZenContext;
