import LSUser from "types/user";
import coreApiInstance from "../coreApi";

export const login = (username: string, password: string) =>
  coreApiInstance.post(
    "/auth/login/",
    {
      username,
      password,
    },
    {
      validateStatus: (status) => status < 500,
    }
  );

export const changePassword = (newPassword: string) =>
  coreApiInstance.post<ChangePasswordResponse | undefined>(
    "/users/me/password/",
    {
      password: newPassword,
    },
    {
      validateStatus: (status) => status < 500,
    }
  );

export const initialPassword = (data: InitialPasswordData) =>
  coreApiInstance.post<ChangePasswordResponse | undefined>(
    "/users/me/initial-password/",
    data,
    {
      validateStatus: (status) => status < 500,
    }
  );

export const getMe = () =>
  coreApiInstance.get<LSUser.Info | undefined>("/users/me/", {
    headers: { "cache-control": "no-cache" },
  });

export const getPaymentMethods = () =>
  coreApiInstance.get<LSUser.PaymentMethodItem[]>("/users/me/payment-methods");

export const patchMe = (data: Partial<LSUser.Info>) =>
  coreApiInstance.patch<LSUser.Info | undefined>("/users/me/", data);

export const refreshToken = (refreshToken: string) =>
  coreApiInstance.post<RefreshTokenResponse>("/auth/token/refresh/", {
    refresh: refreshToken,
  });

export const passwordResetRequest = (data: PasswordResetRequestData) =>
  coreApiInstance.post<PasswordResetRequestResponse>(
    "/users/password-recovery/",
    data,
    {
      validateStatus: (status) => status < 500,
    }
  );

export const passwordResetSave = (data: PasswordResetSaveData) =>
  coreApiInstance.post<PasswordResetSaveResponse>(
    "/users/set-password/",
    data,
    {
      validateStatus: (status) => status < 500,
    }
  );
