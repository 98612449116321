import { IconName, Row, ServiceItems } from '@yolaw/ui-kit-components';
import documentIllustrationSrc from 'pages/zen/assets/svgs/document-illustration.svg';
import React from 'react';
import styled, { css } from 'styled-components';

const MainContainer = styled(Row)`
  width: 100%;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      margin: ${theme.spacing.m}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      .service-items-wrapper {
        padding: ${theme.spacing.m}px 0;
      }
    }
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  img {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: none;
  }
`;

const FeaturesContainer = styled.div`
  flex: 1;
`;

type ServiceItemsWithThumbnailProps = {
  items: Array<{
    title: string;
    description: string;
    icon: IconName;
  }>;
};

const ServiceItemsWithThumbnail = ({ ...props }: ServiceItemsWithThumbnailProps) => {
  return (
    <MainContainer>
      <ImageContainer>
        <img src={documentIllustrationSrc} alt="task-cards-preview" />
      </ImageContainer>
      <FeaturesContainer>
        <ServiceItems {...props} />
      </FeaturesContainer>
    </MainContainer>
  );
};

export default React.memo(ServiceItemsWithThumbnail);
