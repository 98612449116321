import React from "react";
import { usePopover } from "./usePopover";

type ContextType = ReturnType<typeof usePopover> | null;

export const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped inside <Popover />");
  }

  return context;
};
