import { ChevronDownFlat } from "@yolaw/ui-kit-icons";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { css, styled } from "styled-components";

import { getNavigationLinkBaseStyles } from "./helper";

const NavigationLinkIcon = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  width: var(--nav-item-icon-width);
  height: 24px;

  > svg {
    margin: auto;
  }
`;

type MultiLevelNavigationToggleIconProps = {
  $isOpen: boolean;
};

const MultiLevelNavigationToggleIcon = styled(
  NavigationLinkIcon
)<MultiLevelNavigationToggleIconProps>`
  margin-left: auto;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      rotate: 180deg;
    `};
`;

const MultiLevelNavigationLink = styled.button`
  ${getNavigationLinkBaseStyles()};

  width: 100%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

type MultiLevelNavigationListBoxProps = {
  $isOpen: boolean;
};

const MultiLevelNavigationListBox = styled.div<MultiLevelNavigationListBoxProps>`
  ${({ $isOpen }) =>
    css`
      display: ${$isOpen ? "block" : "none"};
    `};
`;

type MultiLevelNavigationItemProps = {
  label: string;
  icon: JSX.Element;
  children: ReactNode;
  className?: string;
  open?: boolean;
};

const MultiLevelNavigationItem: FC<MultiLevelNavigationItemProps> = ({
  label,
  icon,
  children,
  className,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const location = useLocation();
  const ref = useRef<HTMLLIElement>(null);

  const hasActiveChild = () =>
    !!ref.current?.querySelector(".nav-item-link.active");

  const onMultiLevelNavigationLinkClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (hasActiveChild() && !isOpen) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <li ref={ref} className={className}>
      <MultiLevelNavigationLink onClick={onMultiLevelNavigationLinkClick}>
        <NavigationLinkIcon>{icon}</NavigationLinkIcon>
        {label}
        <MultiLevelNavigationToggleIcon $isOpen={isOpen}>
          <ChevronDownFlat size="16" />
        </MultiLevelNavigationToggleIcon>
      </MultiLevelNavigationLink>
      <MultiLevelNavigationListBox $isOpen={isOpen}>
        {children}
      </MultiLevelNavigationListBox>
    </li>
  );
};

export default React.memo(MultiLevelNavigationItem);
