import { useEffect } from "react";

import { useLegalEntity, useSegment } from "hooks";
import { PageContentHeader } from "pages/components";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import { LoadingPage } from "pages/loading";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import ERecordsWebinarBanner from "./ERecordsWebinarBanner";
import DecisionsContext from "./context";
import { DecisionsSection } from "./decisions-section";
import useDecisionsContext from "./hooks/useDecisionsContext";
import DecisionCreationModal from "./modals/DecisionCreationModal";
import DecisionDeleteModal from "./modals/DecisionDeleteModal";
import DecisionEditModal from "./modals/DecisionEditModal";
import WaitingSirenModal from "./modals/WaitingSirenModal";

export const MandatoryRecordsPage = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();
  const { openingModal, loaded } = useDecisionsContext();

  useEffect(() => {
    const zenSub = currentLegalEntity.findLegacySubscription(LegacySubscriptionSlug.Zen);
    segment.track("e-records decisions listing: displayed", {
      sub_zen: zenSub?.status,
      has_siren: !!currentLegalEntity.siren,
      legalentity_id: currentLegalEntity.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) return <LoadingPage />;

  return (
    <PageContainer>
      <PageContentHeader title="Registre des décisions" />
      <PageContentBody>
        <ERecordsWebinarBanner context="e-records decisions listing" />

        <DecisionsSection />
      </PageContentBody>

      <DecisionCreationModal
        isOpen={
          openingModal?.id === DecisionsContext.DecisionModal.DecisionCreate
        }
      />
      <DecisionEditModal
        isOpen={
          openingModal?.id === DecisionsContext.DecisionModal.DecisionEdit
        }
      />
      <DecisionDeleteModal
        isOpen={
          openingModal?.id === DecisionsContext.DecisionModal.DecisionDelete
        }
      />

      <WaitingSirenModal />
    </PageContainer>
  );
};
