import { useSegment } from "hooks";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { ELEMENT_IDS } from "pages/zen/constants";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import { TPathParams } from "pages/zen/routes";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuestionnaire from "../hooks/useQuestionnaire";
import ComponentProcessor from "../utils/componentProcessor";

type QuestionnairePageProps = {
  name: string;
  slug: string;
  children: JSX.Element;
};

const QuestionnairePage = (props: QuestionnairePageProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const segment = useSegment();
  const project = useZenProject();
  const answers = project.onboardingQuestionnaire?.answers || {};
  const { currentPage, submitAnswers, setLoaderVisible } = useQuestionnaire();
  const { isOnboardingQuestionnaire } = useZenRoutes();
  const { taskId } = useParams<TPathParams>();
  const task = useTask(Number(taskId));

  const [moreInfoBlock, setMoreInfoBlock] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [pageComponents, setPageComponents] = useState<
    JSX.Element | JSX.Element[]
  >(children);
  const [answerLabel, setAnswerLabel] = useState<string>();

  const handleSubmission = () => {
    submitAnswers();
  };
  const handleButtonActions = async (action: string) => {
    switch (action) {
      case "submit":
        handleSubmission();
        break;
      default:
        break;
    }

    if (action.startsWith("task-action#")) {
      // Long (time consuming) action, display an overlay loader
      setLoaderVisible(true);
      await task.handleTaskAction(action.replace("task-action#", ""));
      setLoaderVisible(false);
    }
  };

  const updateContextData = (eventTarget: any) => {
    const { name, value, innerText } = eventTarget as HTMLInputElement;

    const previousValue = _get(answers, String(name || answerLabel));

    if (value) {
      project.patchAnswers(name, value);
    }

    if (eventTarget.tagName !== "INPUT") {
      // Don't track these events on input (text, date, phone number, etc. changes)
      if (isOnboardingQuestionnaire) {
        segment.track("zen onboarding question: completed", {
          sub_aj: project.info.aj_subscription_status,
          sub_zen: project.info.zen_subscription_status,
          sub_cs: project.info.cs_subscription_status,
          has_siren: project.hasCompanySiren,
          page_slug: currentPage?.slug,
          answer_label: value || previousValue || null,
          button_label: innerText || null,
        });
      } else {
        segment.track("zen task cta: clicked", {
          has_siren: project.hasCompanySiren,
          task_id: task.id,
          task_category: task.type.category,
          task_class: task.taskClass,
          task_slug: task.type.slug,
          task_type: task.type.kind,
          task_name: task.type.title,
          answer_label: value || previousValue || null,
          button_label: innerText || null,
        });
      }
    }

    const action = (eventTarget as Element).getAttribute("action");
    if (action) {
      handleButtonActions(action);
    }

    const nextDestination = (eventTarget as Element).getAttribute("to");
    if (nextDestination) {
      navigate(`..${nextDestination}`);
    }
  };
  const handleInputChange = (event: React.FormEvent<HTMLFormElement>) => {
    updateContextData(event.target);
  };

  // Handle click event if it was fired on a button
  const onClickHandler = (event: any) => {
    let { target } = event;

    do {
      const clickedOnButton = target.type === "button";
      const clickedOnNavNextButton =
        target.tagName === "P" && target.innerText === "suivant";

      if (clickedOnButton || clickedOnNavNextButton) {
        updateContextData(target);
        target = undefined;
      } else {
        target = target.parentNode;
      }
    } while (target);
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandler);

    return () => {
      document.removeEventListener("click", onClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!_isEmpty(answers)) {
      const { answerDataKey, processedComponents } =
        ComponentProcessor.injectChildrenData(children, answers);
      setPageComponents(processedComponents);
      setAnswerLabel(answerDataKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffect(() => {
    const MoreInfoComponent =
      ComponentProcessor.extractMoreInfoComponent(children);
    if (MoreInfoComponent) {
      setMoreInfoBlock(MoreInfoComponent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form
        id={ELEMENT_IDS.QUESTIONNAIRE.FORM}
        onChange={handleInputChange}
        onSubmit={handleSubmission}
      >
        {pageComponents}
      </form>
      {moreInfoBlock}
    </>
  );
};

export default QuestionnairePage;
