import { Accent } from "@yolaw/ui-kit-components";
import LegacySubscriptionPaymentModal from "components/Modals/LegacySubscriptionPayment";
import { PaymentModalStep } from "components/Modals/LegacySubscriptionPayment/context";
import { ModalName } from "contexts/app";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import img2 from "./assets/Mask group-1.png";
import img3 from "./assets/Mask group-2.png";
import img1 from "./assets/Mask group.png";

const ZenSubscriptionPaymentModal = () => {
  return (
    <LegacySubscriptionPaymentModal
      modalName={ModalName.ZenSubscriptionPayment}
      subscriptionTypeSlug={LegacySubscriptionSlug.Zen}
      modalConfigs={{
        subscriptionDisplayName: "Zen",
        styles: {
          color: "accentuation.dark",
          tagColor: "purple",
        },
      }}
      stepsConfigs={{
        [PaymentModalStep.Introduction]: {
          title: (monthlyPlan) => (
            <span>
              Débloquez tout le contenu de{" "}
              <Accent color="accentuation.dark">Zen</Accent>
              {monthlyPlan ? (
                <>
                  {" "}
                  pour seulement{" "}
                  <Accent color="accentuation.dark">
                    {NumberUtils.currencyFormat(monthlyPlan?.price_et || 0)}
                    &nbsp;
                    <sup>HT</sup>&nbsp;/
                    {TranslatorUtils.translate(
                      String(monthlyPlan?.period_unit)
                    )}
                  </Accent>
                </>
              ) : (
                ""
              )}
            </span>
          ),
          benefitItems: [
            {
              imgSrc: img1,
              title: "Gérez toutes vos obligations",
              description:
                "Accédez à tous nos guides, rappels et commandez vos registres obligatoires",
            },
            {
              imgSrc: img2,
              title: "Participez aux webinaires",
              description:
                "Différentes sessions hebdomadaires sur des thèmes tel que la rémunération",
            },
            {
              imgSrc: img3,
              title: "Générez vos documents",
              description:
                "Téléchargez vos docs personnalisés  automatiquement dans Zen",
            },
          ],
        },
        [PaymentModalStep.PlanSelection]: {
          displayPlans: [
            {
              yearly: {
                tagText: "30 JOURS OFFERTS",
                description:
                  "Une facturation à l’année pour plus de tranquillité",
              },
              monthly: {
                tagText: "RÉSILIEZ QUAND VOUS VOULEZ",
                description: "Une facturation au mois pour plus de souplesse",
              },
            },
          ],
        },
      }}
    />
  );
};

export default ZenSubscriptionPaymentModal;
