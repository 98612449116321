import { Button, Column, Container, Row, Text, Title } from '@yolaw/ui-kit-components';
import { useIsMobile } from 'hooks/useIsMobile';
import useTask from 'pages/zen/hooks/useTask';
import useZenRoutes from 'pages/zen/hooks/useZenRoutes';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getTaskConfigsBySlug } from './taskConfigs';

const TaskFooter = styled(Container).attrs({
  size: 'l'
})``;

const TaskHeader = styled(Column)`
  padding: ${({ theme }) => theme.spacing.xs}px;
`;
const TaskBody = styled(Row)`
  text-align: left;
  max-width: 100%;
  ${({ theme }) => css`
    column-gap: ${theme.spacing.l}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      padding: 0 ${theme.spacing.xs}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      display: block;
    }
  `}
`;

const RightPanel = styled(Column)`
  flex: 7;
`;

const LeftPanel = styled(Column)`
  flex: 3;
  overflow: auto;
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      min-width: 360px;
    }
  `};
`;

const DocGenPage = () => {
  const task = useTask();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { currentTaskPath } = useZenRoutes();

  const taskLocalConfigs = getTaskConfigsBySlug(task.type.slug);
  const taskServerConfigs = task.details as DocumentTask.Details;

  const askForPostponeReason = () => {
    navigate(`${currentTaskPath}/postpone`);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Routes>
      <Route
        index
        element={
          <>
            <TaskHeader>
              <Title type="H2" color="secondary.main">
                {taskServerConfigs.title || taskLocalConfigs.pageTitle}
              </Title>
              <Container size="s">
                <Text type="BODY" color="neutral.dark">
                  {taskServerConfigs.subtitle || taskLocalConfigs.pageSubtitle}
                </Text>
              </Container>
            </TaskHeader>

            <TaskBody>
              <LeftPanel>{taskLocalConfigs.InputForm}</LeftPanel>
              {!isMobile && <RightPanel>{taskLocalConfigs.DocumentPreview}</RightPanel>}
            </TaskBody>

            {taskLocalConfigs.AskForPostponeReason && (
              <TaskFooter>
                <Button variant="tertiary" onClick={askForPostponeReason}>
                  {"Je n'en ai pas besoin"}
                </Button>
              </TaskFooter>
            )}

            {!!taskServerConfigs.buttons?.second_cta && (
              <TaskFooter>
                <Button
                  variant="tertiary"
                  onClick={() =>
                    task.handleTaskAction(String(taskServerConfigs.buttons?.second_cta?.action))
                  }
                >
                  {taskServerConfigs.buttons?.second_cta.text}
                </Button>
              </TaskFooter>
            )}
          </>
        }
      />
      {isMobile && (
        <Route
          path="preview"
          element={
            <>
              <TaskHeader>
                <Title type="H2" color="secondary.main">
                  {taskServerConfigs.title || taskLocalConfigs.pageTitle}
                </Title>
              </TaskHeader>

              <TaskBody>{taskLocalConfigs.DocumentPreview}</TaskBody>
            </>
          }
        />
      )}
    </Routes>
  );
};

export default DocGenPage;
