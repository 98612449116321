import { Text, Title } from "@yolaw/ui-kit-components";
import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  margin-bottom: ${(props) => props.theme.spacing.m}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    row-gap: ${(props) => props.theme.spacing.xs}px;
    margin-bottom: ${(props) => props.theme.spacing.l}px;
  }
`;

type PageContentHeaderProps = {
  title: string;
  subTitle?: string;
  InformationComponent?: JSX.Element;
};

const PageContentHeader: FC<PageContentHeaderProps> = ({
  title,
  subTitle,
  InformationComponent,
}) => {
  return (
    <Container>
      <Title text={title} type="h1" />
      {subTitle && <Text text={subTitle} />}
      {InformationComponent}
    </Container>
  );
};

export default PageContentHeader;
