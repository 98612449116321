import { css } from "styled-components";

export const getNavigationLinkBaseStyles = () => {
  return css`
    ${({ theme }) => css`
      --nav-item-padding: ${theme.spacing.xxxxs}px;
      --nav-item-icon-width: ${theme.spacing.s}px;
      --nav-item-column-gap: ${theme.spacing.xs}px;
    `}
    display: flex;
    align-items: center;
    min-height: 32px;
    ${({ theme }) => css`
      column-gap: var(--nav-item-column-gap);
      padding: var(--nav-item-padding);
      border-radius: ${theme.borderRadius.xs}px;
    `}
    color: currentColor;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none;

    &:hover {
      background-color: ${(props) => props.theme.colors.neutral.lightest};
      cursor: pointer;
    }

    &.active,
    &.active:hover {
      background-color: ${(props) => props.theme.colors.tertiary.main};
      font-weight: 700;
    }
  `;
};
