import { CompteProFlat, LogOutFlat, PaperclipFlat, SettingsFlat, UserFlat } from "@yolaw/ui-kit-icons";
import { useSegment, useUser } from "hooks";
import NavigationItem, { NavItemProps } from "layout/navigation/NavigationItem";
import { BuiltRoutePath } from "services/router";
import styled, { css, useTheme } from "styled-components";

const PaymentMethodNavItem = ({ onClick }: Pick<NavItemProps, "onClick">) => {
  const theme = useTheme();
  const segment = useSegment();

  const handleClick = () => {
    segment.track("user payment method: displayed");

    onClick?.();

    setTimeout(() => {
      // a tiny timeout to ensure the tracking event is sent before redirecting
      window.location.assign(
        `${process.env.REACT_APP_WEB_APP_DOMAIN}/compte/id/#payment`
      );
    }, 100);
  };

  return (
    <NavigationItem
      icon={<CompteProFlat size="1.5em" color={theme.colors.secondary.main} />}
      label="Moyen de paiement"
      onClick={handleClick}
    />
  );
};

const SubscriptionNavItem = ({ onClick }: Pick<NavItemProps, "onClick">) => {
  const segment = useSegment();

  const handleClick = () => {
    segment.track("subscription listing: displayed");

    onClick?.();

    setTimeout(() => {
      // a tiny timeout to ensure the tracking event is sent before redirecting
      window.location.assign(
        `${process.env.REACT_APP_WEB_APP_DOMAIN}/compte/id/#subscriptions`
      );
    }, 100);
  };

  return (
    <NavigationItem
      icon={<SettingsFlat size="1.5em" />}
      label="Gérer mes abonnements"
      onClick={handleClick}
    />
  );
};

const MenuContainer = styled.ul`
  list-style: none;

  ${({ theme }) => css`
    background-color: ${theme.colors.common.white};
    padding: ${theme.spacing.xs}px;
    > li {
      padding: ${theme.spacing.xxxs}px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  `}
`;

type MyAccountMenuProps = {
  onItemClick: VoidFunction;
};

const MyAccountMenu = ({ onItemClick }: MyAccountMenuProps) => {
  const user = useUser();

  return (
    <MenuContainer>
      <NavigationItem
        icon={<UserFlat size="1.5em" />}
        label="Informations personnelles"
        path={BuiltRoutePath.UserProfilePage}
        onClick={onItemClick}
      />

      <NavigationItem
        icon={<PaperclipFlat size="1.5em" />}
        label="Factures"
        path={BuiltRoutePath.InvoicesPage}
        onClick={onItemClick}
      />

      <PaymentMethodNavItem onClick={onItemClick} />

      <SubscriptionNavItem onClick={onItemClick} />

      <NavigationItem
        label="Me déconnecter"
        icon={<LogOutFlat size="1.5em" />}
        onClick={user.logout}
      />
    </MenuContainer>
  );
};

export default MyAccountMenu;
