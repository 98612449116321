import React from "react";
import HubspotPage from "./HubspotPage";
import QuestionnaireViewer from "../Questionnaire";
import styled from "styled-components";

const OverrideQuestionnaireViewerStyles = styled.div`
  #questionnaire-form {
    min-height: unset;
    flex: 1;
  }

  .hs_page_iframe_container {
    display: grid;
    width: 100vw;
  }
`;

const isSinglePageDetails = (
  details: HubspotPageTaskDetails
): details is SinglePageDetails => !!(details as SinglePageDetails).page_url;

type HubspotPageViewerProps = {
  task: HubspotPageTask;
};

const HubspotPageViewer = ({ task }: HubspotPageViewerProps) => {
  return isSinglePageDetails(task.details) ? (
    <HubspotPage
      page_url={task.details.page_url}
      buttons={task.details.buttons}
    />
  ) : (
    <OverrideQuestionnaireViewerStyles>
      <QuestionnaireViewer
        questions={task.details.pages}
        extraComponents={{
          HubspotPage: HubspotPage as unknown as React.ReactNode,
        }}
      />
    </OverrideQuestionnaireViewerStyles>
  );
};

export default HubspotPageViewer;
