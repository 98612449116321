import { Column, IconName, Row, Tag, Text } from "@yolaw/ui-kit-components";
import { TagColor } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/Tag";
import { ChevronRight } from "@yolaw/ui-kit-icons";
import { formatDate } from "date-fns/format";
import styled, { css } from "styled-components";
import useTask, { TaskItemExtended } from "../../hooks/useTask";
import useZenProject from "../../hooks/useZenProject";

import iconVAT from "./assets/task-icons/$yolaw-icon-universal-VAT.svg";
import iconClosingDate from "./assets/task-icons/$yolaw-icon-universal-closingDate.svg";
import iconEconomy from "./assets/task-icons/$yolaw-icon-universal-economy.svg";
import iconExpense from "./assets/task-icons/$yolaw-icon-universal-expense.svg";
import iconGlassondoc from "./assets/task-icons/$yolaw-icon-universal-glassondoc.svg";
import iconInsurance from "./assets/task-icons/$yolaw-icon-universal-insurance.svg";
import iconKbis from "./assets/task-icons/$yolaw-icon-universal-kbis.svg";
import iconMutuelle from "./assets/task-icons/$yolaw-icon-universal-mutuelle.svg";
import iconOthers from "./assets/task-icons/$yolaw-icon-universal-others.svg";
import iconSearch from "./assets/task-icons/$yolaw-icon-universal-search.svg";
import iconServiceobligationsjuridiques from "./assets/task-icons/$yolaw-icon-universal-serviceobligationsjuridiques.svg";
import iconSheets from "./assets/task-icons/$yolaw-icon-universal-sheets.svg";
import iconSocialaction from "./assets/task-icons/$yolaw-icon-universal-socialaction.svg";
import iconTemplatedoc from "./assets/task-icons/$yolaw-icon-universal-templatedoc.svg";
import iconVideo from "./assets/task-icons/$yolaw-icon-universal-video.svg";

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
`;

const TextContainer = styled(Column)`
  flex: 1;
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.xxxs}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.xxxxs}px;
      }
    }
  `}
`;

const ContentContainer = styled(Row)`
  flex: 1;

  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        column-gap: ${theme.spacing.xxxs}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      && {
        align-items: center;
        column-gap: ${theme.spacing.m}px;
      }
    }
  `}
`;

const ArrowRightContainer = styled(ChevronRight)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      display: none;
    }
  `}
`;

const MainContainer = styled(Row)`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.xxs}px;
    padding: ${theme.spacing.xxs}px;
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    background-color: ${theme.colors.common.white};

    @media (min-width: ${theme.breakpoints.l}px) {
      &:hover {
        box-shadow: ${theme.shadows.blue.medium};
        cursor: pointer;
      }
    }
  `}
`;

const StyledTag = styled(Tag)`
  && {
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
  }
`;

type TagConfigs = {
  color: TagColor;
  text: string;
  icon?: IconName;
};
const getTagConfigs = (
  task: TaskItemExtended,
  hasCompanySiren: boolean
): TagConfigs | null => {
  if (task.isPendingSiren)
    return { icon: "LockFlat", text: "En attente du SIREN", color: "gray" };

  if (!task.isAccessible)
    return { icon: "LockFlat", text: "ZEN", color: "green" };

  if (task.is_done) return { text: "C'est fait !", color: "green" };

  if (!hasCompanySiren)
    return { text: "À faire dès maintenant", color: "blue" };

  if (!task.due_date) return null;

  const formattedDueDate = formatDate(new Date(task.due_date), "dd/MM/yyyy");

  return {
    text: `Recommandé pour le ${formattedDueDate}`,
    color: "blue",
  };
};

const mapTaskIconByTaskSlug: Record<string, string> = {
  review: iconEconomy,
  refill: iconExpense,
  unemployment: iconGlassondoc,
  "insurance-rc-pro-stello": iconInsurance,
  "ape-code-change": iconKbis,
  "insurance-mutuelle-stello": iconMutuelle,
  fraud: iconSearch,
  "protect-brand": iconServiceobligationsjuridiques,
  convention: iconSheets,
  hiring: iconSocialaction,
  conditions: iconTemplatedoc,
  webinaire: iconVideo,
  webinar_obligations_subsidies: iconVideo,
  "valid-vat": iconVAT,
  "discover-zen": iconClosingDate,
};

type AdministrativeTaskCardProps = {
  task: TaskItem;
};

const AdministrativeTaskCard = ({ task }: AdministrativeTaskCardProps) => {
  const project = useZenProject();
  const extendedTask = useTask(task.id);
  const {
    type: { title, subtitle },
  } = extendedTask;

  const tagConfigs = getTagConfigs(extendedTask, project.hasCompanySiren);

  return (
    <MainContainer onClick={extendedTask.handleTaskOpen}>
      <ContentContainer>
        <IconContainer>
          <img
            src={mapTaskIconByTaskSlug[task.type.slug] || iconOthers}
            alt="task-icon"
          />
        </IconContainer>
        <TextContainer>
          <Text color="secondary.main" fontWeightVariant="bold">
            {title}
          </Text>
          <Text color="neutral.main" type="SMALL">
            {subtitle}
          </Text>
          {tagConfigs && (
            <StyledTag color={tagConfigs.color} icon={tagConfigs.icon}>
              {tagConfigs.text}
            </StyledTag>
          )}
        </TextContainer>
      </ContentContainer>
      <ArrowRightContainer />
    </MainContainer>
  );
};

export default AdministrativeTaskCard;
