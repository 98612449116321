import { Title } from "@yolaw/ui-kit-components";
import React from "react";
import styled from "styled-components";

import { BillingInvoice, WebInvoice } from "types/invoices";
import InvoiceCard from "./InvoiceCard";

const Section = styled.section``;

const SectionTitle = styled(Title)`
  margin-bottom: ${(props) => props.theme.spacing.xs}px;
`;

const InvoicesList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  list-style: none;
`;

type InvoicesSectionProps = {
  title: string;
  invoices: {
    webInvoices?: WebInvoice[];
    billingInvoices?: BillingInvoice[];
  };
};

const InvoicesSection: React.FC<InvoicesSectionProps> = ({
  title,
  invoices,
}) => {
  const { billingInvoices, webInvoices } = invoices;
  return (
    <Section>
      <SectionTitle text={title} type="h2" />
      <InvoicesList>
        {billingInvoices?.map((invoice) => (
          <li key={invoice.id}>
            <InvoiceCard
              id={invoice.id}
              referenceDate={invoice.reference_date}
              documentId={invoice.document_id}
              documentName={invoice.document_name}
              downloadUrl={invoice.download_url}
            />
          </li>
        ))}
        {webInvoices?.map((invoice) => (
          <li key={invoice.id}>
            <InvoiceCard
              id={invoice.id}
              referenceDate={invoice.paid_at}
              documentId={invoice.source_id}
              documentName={invoice.document_name}
              downloadUrl={invoice.download_url}
            />
          </li>
        ))}
      </InvoicesList>
    </Section>
  );
};

export default React.memo(InvoicesSection);
