import _assign from "lodash/assign";
import { Column } from "@yolaw/ui-kit-components";
import React, { useEffect, useLayoutEffect, useReducer } from "react";
import styled, { css } from "styled-components";
import OverlayLoader from "../components/OverlayLoader";
import questionnaireComponents from "./components";
import QuestionnaireNavigator from "./components/QuestionnaireNavigator";
import { initialState, QuestionnaireContext, reducer } from "./context";
import { componentBuilder } from "./utils/componentBuilder";
import { CONTENT_MAX_WIDTH, ELEMENT_IDS } from "pages/zen/constants";

const MainContainer = styled.main`
  margin-inline: auto;

  #questionnaire-form {
    max-width: 100%;
    min-height: calc(100vh - 93px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      max-width: ${CONTENT_MAX_WIDTH}px;
      #questionnaire-form {
        padding: 0 ${theme.spacing.xs}px;
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      max-width: 100vw;
      #questionnaire-form {
        padding: ${theme.spacing.xs}px;
      }

      .service-items-wrapper {
        padding-left: unset;
        padding-right: unset;
      }
    }
  `};
`;

const PageContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  ${({ theme }) => css`
    padding: ${theme.spacing.xs}px 0;
  `};
`;

type QuestionnaireViewerProps = {
  questions: QuestionnaireComponents;
  resumePageSlug?: string | null;
  onComplete?: () => void;
  navigatorVisible?: boolean;
  extraComponents?: {
    [componentName: string]: React.ReactNode;
  };
};

const QuestionnaireViewer = ({
  questions,
  resumePageSlug = null,
  onComplete,
  navigatorVisible = false,
  extraComponents,
}: QuestionnaireViewerProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { QuestionnaireComponent, loaderVisible } = state;

  const buildQuestionnaireComponent = async () => {
    const component = await componentBuilder(
      questions,
      _assign(questionnaireComponents, extraComponents)
    );
    dispatch({
      type: "SET_QUESTIONNAIRE_COMPONENT",
      payload: {
        component,
        resumePageSlug,
      },
    });
  };

  useEffect(() => {
    buildQuestionnaireComponent();
    if (onComplete) {
      dispatch({
        type: "SET_ON_COMPLETE",
        payload: onComplete,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const appHeaderElm = document.getElementById(ELEMENT_IDS.HEADER.APP_HEADER);
    const appHeaderHeight = appHeaderElm?.clientHeight || 0;

    const taskNavElm = document.getElementById(ELEMENT_IDS.TASK_VIEW.NAVIGATOR);
    const taskNavHeight = taskNavElm?.clientHeight || 0;

    const questionnaireNavElm = document.getElementById(
      ELEMENT_IDS.QUESTIONNAIRE.NAVIGATOR
    );
    const questionnaireNavHeight = questionnaireNavElm?.clientHeight || 0;

    const preservedHeight =
      appHeaderHeight + taskNavHeight + questionnaireNavHeight;

    const pageContainerElm = document.getElementById(
      ELEMENT_IDS.QUESTIONNAIRE.PAGE_CONTAINER
    );
    pageContainerElm?.style.setProperty(
      "min-height",
      `calc(100vh - ${preservedHeight}px)`
    );

    const lzAppElement = document.getElementById(ELEMENT_IDS.APP_CONTAINER);
    lzAppElement?.style.setProperty("overflow", "hidden");

    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      lzAppElement?.style.setProperty("overflow", "unset");
    };
  }, []);

  return (
    <QuestionnaireContext.Provider value={{ state, dispatch }}>
      <MainContainer>
        <QuestionnaireNavigator navigatorVisible={navigatorVisible} />
        <PageContainer id={ELEMENT_IDS.QUESTIONNAIRE.PAGE_CONTAINER}>
          {QuestionnaireComponent}
        </PageContainer>
      </MainContainer>
      {loaderVisible && <OverlayLoader />}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireViewer;
