import {
  generatePath,
  matchPath,
  NavigateOptions,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RoutePath } from "services/router";
import { PATH_PARAMS, PATH_TEMPLATES, TPathParams } from "../routes";

type ZenNavigateOptions = {
  searchParams?: URLSearchParams | string;
  navigateOptions?: NavigateOptions;
};

const useZenRoutes = () => {
  const navigate = useNavigate();
  const params = useParams();
  const matchBasePath = matchPath<TPathParams, string>(
    PATH_TEMPLATES.BASE,
    window.location.pathname
  );
  const { legalEntityId, dashboardType, taskId } =
    matchBasePath?.params || params;

  const basePathParams = {
    legalEntityId,
    dashboardType,
  };

  const isOnboardingQuestionnaire = !!useMatch(
    `${PATH_TEMPLATES.ONBOARDING}/*`
  );

  const currentTaskPath = taskId
    ? generatePath(PATH_TEMPLATES.TASK_VIEW, {
        ...basePathParams,
        taskId: String(taskId),
      })
    : null;

  const _navigateWithOptions = (
    generatedPath: string,
    options?: ZenNavigateOptions
  ) => {
    let finalPath = generatedPath;

    if (options?.searchParams) {
      if (typeof options.searchParams === "string") {
        finalPath += options.searchParams;
      } else {
        const searchString = options.searchParams.toString();
        finalPath += `?${searchString}`;
      }
    }

    navigate(finalPath, options?.navigateOptions);
  };

  /** Go to current dashboard */
  const goToDashboard = (options?: ZenNavigateOptions) => {
    const dashboardPath = generatePath(PATH_TEMPLATES.BASE, basePathParams);
    _navigateWithOptions(dashboardPath, options);
  };

  const goToLegalObligationDashboard = (options?: ZenNavigateOptions) => {
    const dashboardPath = generatePath(PATH_TEMPLATES.BASE, {
      ...basePathParams,
      [PATH_PARAMS.DASHBOARD_TYPE]: RoutePath.LegalsObligations,
    });
    _navigateWithOptions(dashboardPath, options);
  };

  const goToAdministrativeDashboard = (options?: ZenNavigateOptions) => {
    const dashboardPath = generatePath(PATH_TEMPLATES.BASE, {
      ...basePathParams,
      [PATH_PARAMS.DASHBOARD_TYPE]: RoutePath.AdministrativeDashboard,
    });
    _navigateWithOptions(dashboardPath, options);
  };

  const goToOnboardingQuestionnaire = (options?: ZenNavigateOptions) => {
    const onboardingPath = generatePath(
      PATH_TEMPLATES.ONBOARDING,
      basePathParams
    );
    _navigateWithOptions(onboardingPath, options);
  };

  const goToTask = (
    destTaskId: TaskItem["id"],
    options?: ZenNavigateOptions
  ) => {
    const taskPath = generatePath(PATH_TEMPLATES.TASK_VIEW, {
      ...basePathParams,
      taskId: String(destTaskId),
    });
    _navigateWithOptions(taskPath, options);
  };

  return {
    dashboardType,
    taskId,
    isOnboardingQuestionnaire,
    currentTaskPath,
    goToDashboard,
    goToLegalObligationDashboard,
    goToAdministrativeDashboard,
    goToOnboardingQuestionnaire,
    goToTask,
  };
};

export default useZenRoutes;
