import { Column, Row, Text } from "@yolaw/ui-kit-components";
import { AmericanExpressV3, MastercardV3, VisaV3 } from "@yolaw/ui-kit-icons";
import React from "react";
import { PaymentOption } from "services/payment";
import styled, { css, useTheme } from "styled-components";
import LSUser from "types/user";
import { usePaymentModalContext } from "../../../hooks";
import PaymentMethodDropdown from "./PaymentMethodDropdown";
import StripeCardForm from "./StripeCardForm";

const CHECK_ICON_SIZE = 16;
const CHECKED_ICON_SIZE = CHECK_ICON_SIZE - 8;

const CheckIconOuter = styled.div<{ checked: boolean }>`
  ${({ theme, checked }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${theme.spacing.xxxs}px;

    border: 2px solid
      ${checked ? theme.colors.primary.main : theme.colors.neutral.lighter};
    border-radius: ${CHECK_ICON_SIZE}px;
    width: ${CHECK_ICON_SIZE}px;
    height: ${CHECK_ICON_SIZE}px;
  `}
`;

const CheckIconInner = styled.div`
  ${({ theme }) => css`
    border-radius: ${CHECKED_ICON_SIZE}px;
    width: ${CHECKED_ICON_SIZE}px;
    height: ${CHECKED_ICON_SIZE}px;
    background-color: ${theme.colors.primary.main};
  `}
`;

const OptionItemContainer = styled(Row)`
  text-align: left;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => css`
    column-gap: ${theme.spacing.xxxs}px;

    :hover {
      cursor: pointer;
      ${CheckIconOuter} {
        border-color: ${theme.colors.primary.main};
      }
    }

    @media screen and (max-width: ${theme.breakpoints.m}px) {
      align-items: flex-start;
    }
  `}
`;

const CardBrandIcon = ({ brand }: { brand: string }) => {
  switch (brand) {
    case "amex":
      return <AmericanExpressV3 width="36" height="24" />;
    case "mastercard":
      return <MastercardV3 width="36" height="24" />;
    case "visa":
    default:
      return <VisaV3 width="36" height="24" />;
  }
};

const StyledCardBrandIcon = styled(CardBrandIcon)`
  width: 36px;
`;

const CardLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => css`
    column-gap: ${theme.spacing.xxxs}px;

    @media screen and (max-width: ${theme.breakpoints.m}px) {
      align-items: flex-start;
    }
  `}
`;

type OptionItemProps = {
  card?: LSUser.PaymentMethodItem;
  checked?: boolean;
};

const OptionItem = ({
  card,
  checked,
  children,
}: React.PropsWithChildren<OptionItemProps>) => {
  const paymentModal = usePaymentModalContext();

  const handleClick = () => {
    if (card) {
      paymentModal.action.setSelectedCard(card);
    } else {
      paymentModal.action.setPaymentOption(PaymentOption.NewPaymentMethod);
    }
  };

  return (
    <OptionItemContainer onClick={handleClick}>
      <CheckIconOuter checked={!!checked}>
        {checked && <CheckIconInner />}
      </CheckIconOuter>
      <Column style={{ flex: 1 }}>
        {card ? (
          <CardLabel>
            <StyledCardBrandIcon brand={card.brand} />
            <Text>
              {`Carte se terminant en **** ${card.last4} et expirant le ${card.exp_month}/${card.exp_year}`}
            </Text>
          </CardLabel>
        ) : (
          children
        )}
      </Column>
    </OptionItemContainer>
  );
};

const AlignmentContainer = styled(Column)<{ $alignWithOptionLabel?: boolean }>`
  ${({ $alignWithOptionLabel, theme }) => css`
    margin-left: ${$alignWithOptionLabel ? theme.spacing.m : 0}px;
    row-gap: ${theme.spacing.xxxs}px;
  `}
`;

const LinkedElements = styled(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxs}px;

    :hover {
      ${OptionItemContainer} {
        ${CheckIconOuter} {
          border-color: ${theme.colors.primary.main};
        }
      }
      .payment-method-dropdown {
        > div > div {
          border-color: ${theme.colors.secondary.main};
        }
      }
    }
  `}
`;

const PaymentMethodSelector = () => {
  const theme = useTheme();
  const paymentModal = usePaymentModalContext();
  const { paymentOption, savedPaymentMethods, selectedCard } =
    paymentModal.state;

  const hasSavedPaymentMethods = !!savedPaymentMethods?.length;

  return (
    <Column style={{ rowGap: `${theme.spacing.s}px` }}>
      {hasSavedPaymentMethods &&
        savedPaymentMethods.map((card) => (
          <OptionItem
            key={card.payment_method_id}
            card={card}
            checked={
              paymentOption === PaymentOption.UseSavedCard &&
              card.payment_method_id === selectedCard?.payment_method_id
            }
          />
        ))}

      <Column style={{ rowGap: `${theme.spacing.xxxs}px` }}>
        <LinkedElements>
          {hasSavedPaymentMethods ? (
            <OptionItem
              checked={paymentOption === PaymentOption.NewPaymentMethod}
            >
              <Text>Utiliser un autre moyen de paiement</Text>
            </OptionItem>
          ) : null}
          <AlignmentContainer $alignWithOptionLabel={!!hasSavedPaymentMethods}>
            <PaymentMethodDropdown />
          </AlignmentContainer>
        </LinkedElements>

        <AlignmentContainer $alignWithOptionLabel={!!hasSavedPaymentMethods}>
          <StripeCardForm />
        </AlignmentContainer>
      </Column>
    </Column>
  );
};

export default PaymentMethodSelector;
