import { Button, Row, Text, Title } from "@yolaw/ui-kit-components";
import React from "react";
import styled, { css } from "styled-components";
import LegacySubscription from "types/legacy-subscriptions";
import { PaymentModalStep } from "../context";
import { usePaymentModalContext } from "../hooks";

const FeaturesContainer = styled(Row)`
  ${({ theme }) => css`
    .benefit-item {
      box-shadow: ${theme.shadows.blue.small};
      border-radius: ${theme.borderRadius.m}px;
      overflow: hidden;

      .benefit-item_image-container {
        background-color: #f9fcff;
        img {
          width: 100%;
          max-height: 135px;
          object-fit: contain;
        }
      }

      .benefit-item_text-container {
        padding: ${theme.spacing.xxxs}px ${theme.spacing.s}px;
        > :not(:last-child) {
          margin-bottom: ${theme.spacing.xxxs}px;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      gap: ${theme.spacing.l}px;
      .benefit-item {
        width: 30%;
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-wrap: wrap;
      gap: ${theme.spacing.m}px;

      .benefit-item {
        width: 100%;
      }
    }
  `}
`;

type BenefitItemProps = {
  imgSrc: string;
  title: string;
  description: string;
};
const BenefitItem = ({ imgSrc, title, description }: BenefitItemProps) => {
  return (
    <div className="benefit-item">
      <div className="benefit-item_image-container">
        <img src={imgSrc} alt="benefit-thumbnail" />
      </div>
      <div className="benefit-item_text-container">
        <Title type="h4">{title}</Title>
        <Text type="body">{description}</Text>
      </div>
    </div>
  );
};

export type IntroductionPageProps = {
  title: JSX.Element | string | ((plan?: LegacySubscription.Plan) => JSX.Element);
  benefitItems: BenefitItemProps[];
};

const IntroductionPage = ({ title, benefitItems }: IntroductionPageProps) => {
  const paymentModal = usePaymentModalContext();
  const monthlyPlan = paymentModal.action.getPlan("monthly");

  const goToPlanSelectionPage = () => {
    paymentModal.action.setPaymentModalStep(PaymentModalStep.PlanSelection);
  };

  return (
    <>
      <Title type="H3">
        {typeof title === "function" ? title(monthlyPlan) : title}
      </Title>
      <FeaturesContainer>
        {benefitItems.map((itemProps) => (
          <BenefitItem key={itemProps.title} {...itemProps} />
        ))}
      </FeaturesContainer>

      <Button rightIcon="ArrowRight" onClick={goToPlanSelectionPage}>
        Choisir ma formule
      </Button>
    </>
  );
};

export default React.memo(IntroductionPage);
