import { AppContext } from "contexts";
import { useCallback, useContext, useEffect, useReducer } from "react";

import ZenContext from "./context";

const ZenAppProvider = ({ children }: React.PropsWithChildren) => {
  const { state: appState, dispatch: appDispatch } = useContext(
    AppContext.Context
  );

  const [state, dispatch] = useReducer(ZenContext.reducer, {
    ...ZenContext.initialState,
    ...(appState._cache.zenContextState || {}),
  });

  const cacheState = useCallback(() => {
    if (state !== appState._cache.zenContextState) {
      // Store the partial of Zen state to the App context for caching purposes
      appDispatch({
        type: AppContext.ActionType.UpdateCacheStore,
        payload: {
          zenContextState: state,
        },
      });
    }
  }, [appDispatch, appState._cache.zenContextState, state]);

  useEffect(() => {
    cacheState();
  }, [cacheState]);

  return (
    <ZenContext.Context.Provider value={{ state, dispatch }}>
      {children}
    </ZenContext.Context.Provider>
  );
};

export default ZenAppProvider;
