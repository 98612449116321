import {
  PaymentIntent,
  PaymentMethod as StripePaymentMethod,
  PaymentRequestPaymentMethodEvent,
  SetupIntent,
} from "@stripe/stripe-js";
import { Column, Text } from "@yolaw/ui-kit-components";
import { useState } from "react";
import { toast } from "react-toastify";

import { PaymentModalContextState } from "components/Modals/LegacySubscriptionPayment/context";
import { useApp, useSegment } from "hooks";
import { LegacySubscriptionService } from "services";
import { PaymentError, PaymentMethod } from "services/payment";
import LegacySubscription from "types/legacy-subscriptions";
import useStripePayment from "./useStripePayment";

/**
 * This hook manages the payment process for Legal Start Legacy subscriptions.
 * It handles different payment methods (Card, Apple Pay, Google Pay) and
 * interacts with the Stripe API for payment processing.
 */
const useLegacySubscriptionPayment = (
  plan?: LegacySubscription.Plan,
  subscriptionDisplayName?: string,
  paymentModalRef?: PaymentModalContextState["modalRef"] | null
) => {
  const app = useApp();

  const segment = useSegment();
  const {
    stripePaymentRequest,
    canMakeApplePayPayment,
    createStripePaymentMethod,
    handleStripeCardAction,
    isDirectPayment,
    isIndirectPayment,
    isPaymentIntentResult,
    isSetupIntentResult,
  } = useStripePayment();

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<PaymentError | null>(null);

  const availablePaymentMethods = [PaymentMethod.Card, PaymentMethod.GooglePay];
  if (canMakeApplePayPayment()) {
    availablePaymentMethods.push(PaymentMethod.ApplePay);
  }

  const onPaymentError = (error: PaymentError) => {
    setPaymentError(error);
  };

  const displayNotification = () => {
    if (!toast.isActive("toast-payment-success")) {
      toast.success(
        <Column>
          <Text fontWeightVariant="bold">
            Votre entreprise vous remercie&nbsp;😉
          </Text>
          <Text>
            Vous êtes désormais abonné à{" "}
            <strong>{subscriptionDisplayName}</strong>. Si vous avez une
            question sur l&apos;outil n&apos;hésitez pas à nous contacter.
          </Text>
        </Column>,
        {
          autoClose: false,
          icon: false,
          toastId: "toast-payment-success",
        }
      );
    }
  };

  const _updateSubscriptions = async () => {
    const subscriptions = await LegacySubscriptionService.getSubscriptions();
    app.setLegacySubscriptions(subscriptions);
  };

  const onPaymentSuccess = async () => {
    segment.track("paywall: completed", {
      "subscription product": plan?.type_slug,
      plan: plan?.slug,
    });

    await _updateSubscriptions();

    app.closeModal();
    displayNotification();
  };

  const confirmStripePayment = async (
    stripeData:
      | {
          stripe_payment_method_id: SetupIntent["payment_method"];
          stripe_setup_intent_id: SetupIntent["id"];
        }
      | {
          stripe_payment_method_id: PaymentIntent["payment_method"];
          stripe_payment_intent_id: PaymentIntent["id"];
        }
  ) => {
    try {
      if (!plan) throw new Error("Subscription plan is not provided");

      // Send confirm request to BE
      await LegacySubscriptionService.confirmStripePayment({
        subscription_type_slug: plan.type_slug,
        subscription_plan_slug: plan.slug,
        legal_entity_id: app.currentLegalEntity?.id,
        ...stripeData,
      });

      onPaymentSuccess();
    } catch (error) {
      onPaymentError({
        source: "ERROR_SOURCES.BACKEND_STRIPE",
        message: (error as PaymentError).message,
        code: (error as PaymentError).code,
      });
    }
  };

  /**
   * Initiates the Stripe payment process.
   * @param stripePaymentMethodId - The ID of the Stripe payment method
   * @param event - Optional PaymentRequestPaymentMethodEvent for Apple Pay
   */
  const initiateStripePayment = async (
    stripePaymentMethodId: StripePaymentMethod["id"],
    event?: PaymentRequestPaymentMethodEvent
  ) => {
    // Clear previously payment error if any
    setPaymentError(null);

    try {
      if (!plan) throw new Error("Subscription plan is not provided");

      // send initiation payment request to server
      const initiateStripePaymentResult =
        await LegacySubscriptionService.initiateStripePayment({
          legal_entity_id: app.currentLegalEntity?.id,
          subscription_type_slug: plan.type_slug,
          subscription_plan_slug: plan.slug,
          stripe_payment_method_id: stripePaymentMethodId,
        });

      const handlePaymentAction = async (
        clientSecret: string,
        intentType: PaymentIntent["object"] | SetupIntent["object"]
      ) => {
        // Hide the payment modal while Stripe is handling the card action
        paymentModalRef?.current?.hide();
        const handleCardActionResult = await handleStripeCardAction(
          clientSecret,
          intentType
        );
        // Reappear the payment modal to show the result and continue payment process
        paymentModalRef?.current?.unhide();

        if (handleCardActionResult) {
          const { error } = handleCardActionResult;
          if (error) {
            onPaymentError({
              source: "ERROR_SOURCES.FRONTEND_STRIPE",
              message: error.message || "",
              code: String(error.code),
            });
          } else {
            if (isPaymentIntentResult(handleCardActionResult)) {
              const { paymentIntent } = handleCardActionResult;
              await confirmStripePayment({
                stripe_payment_method_id: paymentIntent.payment_method,
                stripe_payment_intent_id: paymentIntent.id,
              });
            }
            if (isSetupIntentResult(handleCardActionResult)) {
              const { setupIntent } = handleCardActionResult;
              await confirmStripePayment({
                stripe_payment_method_id: setupIntent.payment_method,
                stripe_setup_intent_id: setupIntent.id,
              });
            }
          }
        }
      };

      if (isDirectPayment(initiateStripePaymentResult)) {
        const {
          stripe_subscription_status,
          payment_intent_status,
          stripe_client_secret,
        } = initiateStripePaymentResult;

        switch (payment_intent_status) {
          case "requires_action":
            if (stripe_client_secret) {
              await handlePaymentAction(stripe_client_secret, "payment_intent");
            } else {
              throw new Error("Stripe client secret is not provided");
            }
            break;
          case "succeeded":
            if (
              LegacySubscriptionService.isActiveStatus(stripe_subscription_status)
            ) {
              await onPaymentSuccess();
            } else {
              throw new Error("Invalid subscription status");
            }
            break;
          default:
            throw new Error("Invalid payment intent status");
        }
      } else if (isIndirectPayment(initiateStripePaymentResult)) {
        const {
          setup_intent_status,
          stripe_client_secret,
          stripe_subscription_status,
        } = initiateStripePaymentResult;

        switch (setup_intent_status) {
          case "requires_action":
            if (stripe_client_secret) {
              await handlePaymentAction(stripe_client_secret, "setup_intent");
            } else {
              throw new Error("Stripe client secret is not provided");
            }
            break;
          case "succeeded":
            if (
              stripe_subscription_status &&
              LegacySubscriptionService.isActiveStatus(stripe_subscription_status)
            ) {
              await onPaymentSuccess();
            } else {
              throw new Error("Invalid subscription status");
            }
            break;
          default:
            throw new Error("Invalid setup intent status");
        }
      } else {
        throw new Error("Invalid payment intent type");
      }

      if (event) event.complete("success");
    } catch (exception) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      if (event) event.complete("fail");
      onPaymentError({
        source: "ERROR_SOURCES.BACKEND_STRIPE",
        message: (exception as PaymentError).message,
        code: (exception as PaymentError).code,
      });
    }

    setIsProcessingPayment(false);
  };
  const payWithCard = async (
    stripePaymentMethodId?: StripePaymentMethod["id"]
  ) => {
    setIsProcessingPayment(true);

    if (stripePaymentMethodId) {
      // Using an existing payment method
      initiateStripePayment(stripePaymentMethodId);
    } else {
      const { error, paymentMethod: stripePaymentMethod } =
        await createStripePaymentMethod();

      if (error) {
        onPaymentError({
          source: "ERROR_SOURCES.FRONTEND_STRIPE",
          message: error.message || "",
          code: String(error.code),
        });
      }

      if (stripePaymentMethod) {
        initiateStripePayment(stripePaymentMethod.id);
      }
    }
  };

  const payWithApplePay = async (
    stripePaymentMethod: StripePaymentMethod,
    event: PaymentRequestPaymentMethodEvent
  ) => {
    setIsProcessingPayment(true);
    initiateStripePayment(stripePaymentMethod.id, event);
  };

  const payWithGooglePay = async (paymentToken: string) => {
    setIsProcessingPayment(true);

    const { error, paymentMethod: stripePaymentMethod } =
      await createStripePaymentMethod(
        { token: paymentToken },
        { wallet_name: "google_pay" }
      );

    if (error) {
      onPaymentError({
        source: "ERROR_SOURCES.FRONTEND_STRIPE",
        message: error.message || "",
        code: String(error.code),
      });
    }

    if (stripePaymentMethod) {
      initiateStripePayment(stripePaymentMethod.id);
    }
  };

  return {
    stripePaymentRequest,
    availablePaymentMethods,
    isProcessingPayment,
    paymentError,
    payWithCard,
    payWithApplePay,
    payWithGooglePay,
  };
};

export default useLegacySubscriptionPayment;
