type RedirectionOptions = {
  backToCurrentPage?: boolean;
};

export const redirectToLoginPage = (options?: RedirectionOptions) => {
  const webLoginPage = process.env.REACT_APP_WEB_APP_DOMAIN + "/compte/login/";
  const queryString = options?.backToCurrentPage
    ? "?next=" + window.location.href
    : "";
  // FIXME: redirect to the Userspace owned login page when it's fully integrated
  // - index.html: Remove the script to set the tokens in dev mode in 
  // - package.json: Remove the `prestart` script
  window.location.replace(webLoginPage + queryString);
};

export const redirectToCMS = () =>
  window.location.replace(
    process.env.REACT_APP_LEGALSTART_CMS_URL || "https://www.legalstart.fr"
  );
