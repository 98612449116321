import { useContext } from "react";

import { useSegment } from "hooks";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import ZenService from "../../../../services/apis";
import { QuestionnaireContext } from "../context";

const useQuestionnaire = () => {
  const project = useZenProject();
  const task = useTask();

  const {
    state: { currentPage, resumePageSlug, onComplete },
    dispatch,
  } = useContext(QuestionnaireContext);
  const segment = useSegment();
  const { isOnboardingQuestionnaire } = useZenRoutes();

  const submitAnswers = async () => {
    await ZenService.project(project.info.id).onboarding.updateAnswers(
      project.onboardingQuestionnaire?.answers || {}
    );

    // Perform onComplete action if it's defined on last page
    if (!currentPage?.nextPageSlug && onComplete) {
      onComplete();
    }
  };

  const updateCurrentPage = (page: QuestionnaireTimelinePage) => {
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: page,
    });

    if (isOnboardingQuestionnaire) {
      segment.track("zen onboarding question: displayed", {
        sub_aj: project.info.aj_subscription_status,
        sub_zen: project.info.zen_subscription_status,
        sub_cs: project.info.cs_subscription_status,
        has_siren: project.hasCompanySiren,
        page_slug: page.slug,
      });

      ZenService.project(project.info.id).onboarding.patch({
        current_page: page.slug,
      });
    } else {
      segment.track("zen task step: displayed", {
        has_siren: project.hasCompanySiren,
        task_name: task.type.title,
        task_id: task.id,
        task_category: task.type.category,
        task_class: task.taskClass,
        task_slug: task.type.slug,
        page_slug: page.slug,
      });
    }
  };

  const setLoaderVisible = (visible: boolean) => {
    dispatch({
      type: "SET_LOADER_VISIBLE",
      payload: visible,
    });
  };

  return {
    currentPage,
    resumePageSlug,
    onComplete,
    submitAnswers,
    updateCurrentPage,
    setLoaderVisible,
  };
};

export default useQuestionnaire;
