import { Column, Spinner } from "@yolaw/ui-kit-components";
import { FC } from "react";
import { styled } from "styled-components";

const PageContainer = styled(Column)`
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingPage: FC = () => {
  return (
    <PageContainer>
      <Spinner />
    </PageContainer>
  );
};

export default LoadingPage;
