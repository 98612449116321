import React from "react";

enum DocumentModal {
  DocumentRename,
  DocumentDelete,
  DocumentValidationUnqualifiedModal,
}

type DecisionViewContextState = {
  currentDocumentId: DecisionDocument["id"] | null;
  openingModal: {
    id: DocumentModal;
    context: {
      document?: DecisionDocument;
    };
  } | null;
};

const initialState: DecisionViewContextState = {
  currentDocumentId: null,
  openingModal: null,
};

enum ActionType {
  SetCurrentDocumentId,
  SetOpeningModal,
}

type DecisionViewContextAction =
  | {
      type: ActionType.SetCurrentDocumentId;
      payload: DecisionViewContextState["currentDocumentId"];
    }
  | {
      type: ActionType.SetOpeningModal;
      payload: DecisionViewContextState["openingModal"];
    };

const reducer = (
  state: DecisionViewContextState,
  action: DecisionViewContextAction
): DecisionViewContextState => {
  switch (action.type) {
    case ActionType.SetCurrentDocumentId:
      return {
        ...state,
        currentDocumentId: action.payload,
      };
    case ActionType.SetOpeningModal:
      return {
        ...state,
        openingModal: action.payload,
      };
    default:
      throw new Error(
        "[DecisionViewContext] unknown action type: " + JSON.stringify(action)
      );
  }
};

type DecisionViewContextType = {
  state: DecisionViewContextState;
  dispatch: React.Dispatch<DecisionViewContextAction>;
};

const Context = React.createContext<DecisionViewContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "DecisionViewContext";

const DecisionViewContext = {
  Context,
  ActionType,
  DocumentModal,
  initialState,
  reducer,
};

export default DecisionViewContext;
