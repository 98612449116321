import React from "react";
import { useTheme } from "styled-components";

function useMediaQuery<T>(query: string, whenTrue: T, whenFalse: T): T {
  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = React.useState(!!mediaQuery.matches);

  React.useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [mediaQuery]);

  if (typeof window === "undefined" || typeof window.matchMedia === "undefined")
    return whenFalse;

  return match ? whenTrue : whenFalse;
}

export const useIsMobile = (customBreakpoint?: number) => {
  const theme = useTheme();
  return useMediaQuery<boolean>(
    `(min-width: ${customBreakpoint || theme.breakpoints.l}px)`,
    false,
    true
  );
};
