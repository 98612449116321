import { AppContext } from "contexts";
import { OpeningModal } from "contexts/app";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLegalEntityIdInURL,
  replaceLegalEntityInCurrentPath,
} from "services/router";
import LegacySubscription from "types/legacy-subscriptions";
import { LegalEntity } from "types/legal-entities";
import LSUser from "types/user";

export const useApp = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext.Context);

  const openModal = (openingModal: OpeningModal) => {
    dispatch({
      type: AppContext.ActionType.OpenModal,
      payload: openingModal,
    });
  };

  const closeModal = () => {
    dispatch({ type: AppContext.ActionType.CloseModal });
  };

  const setCurrentLegalEntity = (legalEntity: LegalEntity) => {
    dispatch({
      type: AppContext.ActionType.SetCurrentLegalEntity,
      payload: legalEntity,
    });

    // Also update the LE ID in URL if need be
    if (String(legalEntity.id) !== getLegalEntityIdInURL()) {
      const newPath = replaceLegalEntityInCurrentPath(legalEntity.id);
      newPath && navigate(newPath);
    }
  };

  /**
   * @deprecated 
   * We're using LegacySubscription here because this represents the old subscription model.
   * Please be aware that this is part of a legacy system and may be phased out in future updates.
   */
  const setLegacySubscriptions = (subscriptions: LegacySubscription.SubscriptionItem[]) => {
    dispatch({
      type: AppContext.ActionType.SetLegacySubscriptions,
      payload: subscriptions,
    });
  };

  const setUserInfo = (userInfo: LSUser.Info) => {
    dispatch({
      type: AppContext.ActionType.SetUserInfo,
      payload: userInfo,
    });
  };

  return {
    ...state,

    // Actions
    openModal,
    closeModal,
    setCurrentLegalEntity,
    setLegacySubscriptions,
    setUserInfo,
  };
};
