import { FC, memo, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";

import { LayoutContext } from "contexts";
import { useIsMobile } from "hooks";
import AppMenu from "./AppMenu";

const SIDEBAR_WIDTH = 300;

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 100% 1fr;

  grid-template-areas: "sidebar main-content";

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
    min-height: 100%;
  }
`;

const MainContent = styled.main`
  container-type: inline-size;

  grid-column: sidebar-start / main-content-end;
  grid-row: main-content-start / main-content-end;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    &.menu-open {
      grid-column: main-content-start / main-content-end;
    }
  }
`;

export const AppLayout: FC = memo(() => {
  const isMobile = useIsMobile();
  const {
    state: { isMenuOpen },
    dispatch: layoutDispatch,
  } = useContext(LayoutContext.Context);

  useEffect(() => {
    if (isMobile) {
      layoutDispatch({ type: "CLOSE_SIDEBAR" });
    } else {
      layoutDispatch({ type: "OPEN_SIDEBAR" });
    }

    return () => {
      !isMobile && layoutDispatch({ type: "OPEN_SIDEBAR" });
    };
  }, [isMobile, layoutDispatch]);

  return (
    <StyledLayout className="app-layout">
      <AppMenu />
      <MainContent
        data-testid="app-main-content"
        className={`${isMenuOpen ? "menu-open" : ""}`}
      >
        {<Outlet /> || <h1>Nothing here</h1>}
      </MainContent>
    </StyledLayout>
  );
});
