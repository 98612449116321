import { Button } from "@yolaw/ui-kit-components";
import { formatDate } from "date-fns/format";
import React, { useEffect, useState } from "react";

import { CancelButton, ModalForm } from "components/FormStyles";
import Modal from "components/modal/Modal";
import { DecisionsService } from "services";
import useDecisionsContext from "../hooks/useDecisionsContext";
import DecisionModalFieldset from "./DecisionModalFieldset";

type DecisionEditModalProps = {
  isOpen: boolean;
};

const DecisionEditModal: React.FC<DecisionEditModalProps> = ({ isOpen }) => {
  const { openingModal, closeModal, updateDecision } = useDecisionsContext();
  const decision = openingModal?.context?.decision;

  const [decisionDate, setDecisionDate] = useState(new Date());
  const [decisionTitle, setDecisionTitle] = useState("");

  useEffect(() => {
    if (decision) {
      setDecisionTitle(decision.title);
      setDecisionDate(new Date(decision.date));
    }
  }, [decision]);

  const onValidateButtonClick = () => {
    if (decision) {
      const updatedProperties: Partial<Decision> = {
        date: formatDate(decisionDate, "yyyy-MM-dd"),
        title: decisionTitle,
      };

      // Update the local context
      updateDecision({
        ...decision,
        ...updatedProperties,
      });

      // API request
      DecisionsService.patchDecision(decision.id, updatedProperties);
    }
    closeModal();
  };

  const FooterContent = (
    <>
      <CancelButton
        formMethod="dialog"
        onClick={closeModal}
        leftIcon="ChevronDownFlat"
      >
        Annuler
      </CancelButton>
      <Button type="submit" onClick={onValidateButtonClick}>
        Valider
      </Button>
    </>
  );

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      title="Modifier la décision"
      FooterContent={FooterContent}
    >
      <ModalForm id="DecisionEditForm">
        <DecisionModalFieldset
          decisionTitle={decisionTitle}
          setDecisionTitle={setDecisionTitle}
          decisionDate={decisionDate}
          setDecisionDate={setDecisionDate}
        />
      </ModalForm>
    </Modal>
  );
};

export default React.memo(DecisionEditModal);
