import { useEffect } from "react";

export const useExternalScripts = (url: string, async = true) => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.src = url;
    script.async = async;

    head?.appendChild(script);

    return () => {
      head?.removeChild(script);
    };
  }, [url, async]);
};

export default useExternalScripts;
