import { Accent } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import LegacySubscriptionPaymentModal from "../../../components/Modals/LegacySubscriptionPayment";
import { PaymentModalStep } from "../../../components/Modals/LegacySubscriptionPayment/context";
import { PlanSlug } from "../../../components/Modals/LegacySubscriptionPayment/pages/PlanSelection";
import img2 from "./assets/Mask group-1.png";
import img3 from "./assets/Mask group-2.png";
import img1 from "./assets/Mask group.png";

const INTRO_BENEFITS = [
  {
    imgSrc: img1,
    title: "Faites-les bons choix",
    description: "Aides fiancières disponibles, rémunérations ou dividendes...",
  },
  {
    imgSrc: img2,
    title: "Posez toutes vos questions",
    description:
      "Interrogations en termes de fiscalités, obligations de l'entrepreneur...",
  },
  {
    imgSrc: img3,
    title: "Utilisez nos modèles",
    description:
      "PV de non rémunération, CDI/CDD, CGV, PV d'AG, devis, factures...",
  },
];

const SUBSCRIPTION_DISPLAY_NAME = "Assistance Legalstart";

export const AJSubscriptionPaymentModal = () => {
  return (
    <LegacySubscriptionPaymentModal
      modalName={ModalName.AJSubscriptionPayment}
      subscriptionTypeSlug={LegacySubscriptionSlug.AssistanceJuridique}
      modalConfigs={{
        subscriptionDisplayName: SUBSCRIPTION_DISPLAY_NAME,
        styles: {
          color: "primary.main",
          tagColor: "green",
        },
      }}
      stepsConfigs={{
        [PaymentModalStep.Introduction]: {
          title: (
            <span>
              Obtenez une réponse à toutes vos questions avec l'
              <Accent color="primary.main">{SUBSCRIPTION_DISPLAY_NAME}</Accent>
            </span>
          ),
          benefitItems: INTRO_BENEFITS,
        },
        [PaymentModalStep.PlanSelection]: {
          displayPlans: [
            {
              [PlanSlug.Yearly]: {
                description:
                  "30 jours d’essai inclus, profitez d’un accompagnement annuel.",
                tagText: "40% d'économies",
              },
            },
            {
              [PlanSlug.Monthly]: {
                description:
                  "30 jours d’essai inclus, parfait pour un besoin juridique ponctuel.",
              },
            },
          ],
        },
      }}
    />
  );
};
