import {
  Button,
  Column,
  Container,
  Row,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import _get from "lodash/get";
import StampSampleSvg from "pages/zen/assets/svgs/stamp-sample.svg";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import { useContext } from "react";
import styled, { css } from "styled-components";
import { DocumentGenerationContext } from "../../context";

const EnvelopeContainer = styled(Column)`
  text-align: left;
  width: 520px;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.lighter};
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.m}px;
    padding: ${theme.spacing.xs}px;
    row-gap: ${theme.spacing.s}px;
    @media (max-width: ${theme.breakpoints.m}px) {
      width: 100%;
    }
  `};
`;

const Address = styled.div`
  padding-left: 30%;
`;

const Succeed = () => {
  const { goToDashboard } = useZenRoutes();

  const {
    state: { formData },
  } = useContext(DocumentGenerationContext);

  const handleCtaClick = () => {
    goToDashboard({ navigateOptions: { replace: true } });
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, votre courrier est prêt !
      </Title>
      <Container size="s">
        <Text type="BODY" color="neutral.dark">
          ⚠️ Envoyez vite votre courrier pour que votre changement de régime
          soit pris en compte rapidement :
        </Text>
      </Container>

      <EnvelopeContainer>
        <Row>
          <Text type="SMALL" fontWeightVariant="bold">
            LETTRE RECOMMANDÉE AVEC ACCUSÉ DE RÉCEPTION
          </Text>
          <img src={StampSampleSvg} alt="stamp" />
        </Row>

        <Address>
          <Text type="SMALL">{_get(formData, ["sie_city"])}</Text>
          <Text type="SMALL">{_get(formData, ["sie_address"])}</Text>
        </Address>
      </EnvelopeContainer>

      <Container size="xs">
        <Button text="Fermer" onClick={handleCtaClick} />
      </Container>
    </>
  );
};

export default Succeed;
