import React from "react";

type AddDocumentContextState = {
  acceptedFile: File | null;
};

const initialState: AddDocumentContextState = {
  acceptedFile: null,
};

enum ActionType {
  SetAcceptedFile,
}

type AddDocumentDispatchAction = {
  type: ActionType.SetAcceptedFile;
  payload: AddDocumentContextState["acceptedFile"];
};

const reducer = (
  state: AddDocumentContextState,
  action: AddDocumentDispatchAction
) => {
  switch (action.type) {
    case ActionType.SetAcceptedFile:
      return {
        ...state,
        acceptedFile: action.payload,
      };
    default:
      throw new Error(
        "[AddDocumentContext] unexpected action type: " + JSON.stringify(action)
      );
  }
};

type DecisionsContextType = {
  state: AddDocumentContextState;
  dispatch: React.Dispatch<AddDocumentDispatchAction>;
};

const Context = React.createContext<DecisionsContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "AddDocumentContext";

const AddDocumentContext = {
  Context,
  ActionType,
  initialState,
  reducer,
};

export default AddDocumentContext;
