import { createContext } from "react";

const initialState: LayoutContextState = {
  isMenuOpen: false,
};

const reducer = (
  state: LayoutContextState,
  action: LayoutDispatchAction
): LayoutContextState => {
  switch (action.type) {
    case "CLOSE_SIDEBAR":
      return { ...state, isMenuOpen: false };
    case "OPEN_SIDEBAR":
      return { ...state, isMenuOpen: true };
    default:
      throw new Error("unexpected action type");
  }
};

type LayoutContextType = {
  state: LayoutContextState;
  dispatch: React.Dispatch<LayoutDispatchAction>;
};

const Context = createContext<LayoutContextType>({
  state: initialState,
  dispatch: () => null,
});
Context.displayName = "Layout";

export { Context, initialState, reducer };
