import axios, { AxiosRequestConfig } from "axios";
import { ApiService } from "services";
import { CookiesUtils } from "utils";

const zenApiInstance = axios.create({
  baseURL: process.env.REACT_APP_ZEN_API_URL,
});

const setAuthorizationHeader = (
  requestConfig: AxiosRequestConfig,
  accessToken: string
) => {
  requestConfig.headers &&
    (requestConfig.headers.Authorization = `Bearer ${accessToken}`);
};

zenApiInstance.interceptors.request.use(
  (requestConfig) => {
    const userAccessToken = CookiesUtils.getAccessToken();
    if (userAccessToken) {
      setAuthorizationHeader(requestConfig, userAccessToken);
    }

    return requestConfig;
  },
  (error) => Promise.reject(error)
);

/** Store the retried URL list */
let retriedURLs: string[] = [];

zenApiInstance.interceptors.response.use(
  (response) => {
    // Assure the retried URL is removed from the list
    retriedURLs.filter((retriedURL) => retriedURL !== response.config.url);
    return response;
  },
  async (error) => {
    // Handle unauthorized request (status === 401)
    if (error.response?.status === 401) {
      const errorCode = error.response.data.code;
      const refreshToken = CookiesUtils.getRefreshToken();
      const requestURL = error.config.url;

      const shouldRefreshToken =
        // it's not a refresh request
        requestURL !== "/auth/token/refresh/" &&
        // the error code is `token_not_valid`
        errorCode === "token_not_valid" &&
        // the refresh token is available
        refreshToken &&
        // the request has never been retried
        !retriedURLs.some((retriedURL) => retriedURL === requestURL);

      if (shouldRefreshToken) {
        // Add the URL to the retried URL list
        retriedURLs.push(requestURL);

        // Try to request a new access token
        const response = await ApiService.users.refreshToken(refreshToken);

        if (response?.data.access) {
          // Save the access token into cookies
          CookiesUtils.setAccessToken(response.data.access);

          // Set the new access token to the original request headers
          setAuthorizationHeader(error.config, response.data.access);

          // Retry original request
          return zenApiInstance.request(error.config);
        }
      }
      // return AuthService.reconnect();
    }

    return Promise.reject(error);
  }
);

export default zenApiInstance;
