import * as AdminEndpoints from "./endpoints/admin";
import * as AssistanceEndpoints from "./endpoints/assistance";
import * as AuthenticationEndpoints from "./endpoints/authentication";
import * as ConfigsEndpoints from "./endpoints/configs";
import * as ERecordsEndpoints from "./endpoints/e-records";
import * as FormalityEndpoints from "./endpoints/formalities";
import * as InvoicesEndpoints from "./endpoints/invoices";
import * as LegacySubscriptionsEndpoints from "./endpoints/legacy-subscriptions";
import * as LegalEntitiesEndpoints from "./endpoints/legal-entities";
import * as ServicesEndpoints from "./endpoints/services";
import * as SubscriptionsEndpoints from "./endpoints/subscriptions";
import * as UserEndpoints from "./endpoints/users";

const ApiService = {
  admin: { ...AdminEndpoints },
  assistance: { ...AssistanceEndpoints },
  authentication: { ...AuthenticationEndpoints },
  configs: { ...ConfigsEndpoints },
  eRecords: { ...ERecordsEndpoints },
  formalities: { ...FormalityEndpoints },
  invoices: { ...InvoicesEndpoints },
  legacySubscriptions: { ...LegacySubscriptionsEndpoints },
  legalEntities: { ...LegalEntitiesEndpoints },
  services: { ...ServicesEndpoints },
  subscriptions: { ...SubscriptionsEndpoints },
  users: { ...UserEndpoints },
};

export default ApiService;
