import {
  AssistanceContactFormData,
  AssistanceQuestionTheme,
} from "types/assistance";
import coreApiInstance from "../coreApi";

export const getQuestionThemes = async () =>
  coreApiInstance.get<AssistanceQuestionTheme[]>(
    "/assistance/question-themes/"
  );

export const createTicket = async (data: AssistanceContactFormData) =>
  coreApiInstance.post(`/assistance/tickets/`, data);
