import { Column } from '@yolaw/ui-kit-components';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import TaskNavigator from '../components/TaskNavigator';

const MainContainer = styled(Column)`
  &&{
    gap: 0;
    height: 100%;
    overflow: scroll;
  }
`;

const TaskWithNavigator = () => {
  return (
    <MainContainer>
      <TaskNavigator />
      <Outlet />
    </MainContainer>
  );
};

export default TaskWithNavigator;
