import { colors, Text, Title } from '@yolaw/ui-kit-components';
import { Checkmark } from '@yolaw/ui-kit-icons';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  ${({ theme }) => css`
    margin: ${theme.spacing.l}px 0;

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column;
      align-items: center;
      margin: ${theme.spacing.m}px 0;
    }
  `}
`;

const StepItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 368px;
  position: relative;
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.s}px ${theme.spacing.m}px ${theme.spacing.s}px;

    &:not(:last-child) {
      &:after {
        width: 100%;
        left: 50%;
        top: 40px;
        content: '';
        border-bottom: 4px dotted ${theme.colors.neutral.lighter};
        opacity: 0.5;
        position: absolute;
        z-index: -2;

        @media (max-width: ${theme.breakpoints.m}px) {
          width: initial;
          height: 135%;
          border-bottom: none;
          border-left: 4px dotted ${theme.colors.neutral.lighter};
          left: 18px;
          top: 40px;
        }
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      margin: 0;
      margin-bottom: ${theme.spacing.m}px;
      flex-direction: row;
    }
  `}
`;

const StepIndex = styled.div<{ $stepType: StepType }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 48px;
  font-weight: bold;
  height: 80px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 80px;

  ${({ color, theme, $stepType }) => css`
    background: ${color};
    color: ${theme.colors.secondary.main};
    margin: 0 0 ${theme.spacing.xs}px;
    box-shadow: ${$stepType === 'CURRENT' ? theme.shadows.purple.medium : theme.shadows.blue.medium};

    @media (max-width: ${theme.breakpoints.m}px) {
      font-size: 24px;
      height: 40px;
      min-width: 40px;
      width: 40px;
      margin: 0;
    }
  `}
`;

type StepProps = {
  labelHeight?: number;
};

const StepLabel = styled(Title).attrs({ type: 'H4' })<StepProps>`
  ${({ theme, labelHeight }) => css`
    color: ${theme.colors.secondary.main};

    @media (min-width: ${theme.breakpoints.m}px) {
      margin-bottom: ${theme.spacing.xs}px;
      height: ${labelHeight ? `${labelHeight}px` : 'unset'};
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      text-align: left;
      margin: 0;
      margin-left: ${theme.spacing.m}px;
    }
  `}
`;

const DoneStepIcon = styled(Checkmark)`
  height: 32px;
  width: 32px;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.m}px) {
      margin-left: ${theme.spacing.s}px;
      height: 20px;
      width: 20px;
    }
  `}
`;

const StepDescription = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.dark};
    margin-top: 0;
    @media (max-width: ${theme.breakpoints.m}px) {
      display: none;
    }
  `}
`;

const getTimelineValue = (steps?: StepItem[]) => {
  if (steps) {
    return {
      sections: steps,
      currentSection: _find(steps, (step) => !!step.isCurrentStep)
    };
  }
  return {
    sections: [],
    currentSection: null
  };
};

type StepType = 'DONE' | 'CURRENT' | 'NOT_DONE_YET';

const getStepType = (stepItem: StepItem, currentStep?: StepItem | null): StepType => {
  if (stepItem.index && currentStep?.index) {
    if (stepItem.index < currentStep.index) {
      return 'DONE';
    }
    if (stepItem.index === currentStep.index) {
      return 'CURRENT';
    }
  }
  return 'NOT_DONE_YET';
};

const getStepIndexColor = (stepItem: StepItem, stepType: StepType): string => {
  // prefer the color set explicitly
  if (stepItem.stepColor) {
    return _get(colors, stepItem.stepColor, colors.neutral.dark);
  }

  switch (stepType) {
    case 'DONE':
      return colors.neutral.light;
    case 'CURRENT':
      return colors.accentuation.main;
    case 'NOT_DONE_YET':
    default:
      return colors.common.white;
  }
};

const computeSteps = (steps: StepItem[], currentStep?: StepItem | null, labelHeight?: number) => {
  const currentStepIndex = _findIndex(steps, (stepItem) => stepItem.name === currentStep?.name);

  return steps.map((stepItem, index) => {
    const stepType = getStepType(stepItem, currentStep);
    const stepIndexColor = getStepIndexColor(stepItem, stepType);

    return (
      <StepItemContainer key={stepItem.name}>
        <StepIndex color={stepIndexColor} $stepType={stepType}>
          {index + 1}
        </StepIndex>
        <StepLabel labelHeight={labelHeight}>
          <span className="step-label">{stepItem.name}</span>
        </StepLabel>
        {/* Step description */}
        {index < currentStepIndex ? (
          // done
          <DoneStepIcon color={colors.primary.main} />
        ) : (
          // current or not done yet
          <StepDescription type="small">{stepItem.description}</StepDescription>
        )}
      </StepItemContainer>
    );
  });
};

type StepItem = {
  name: string;
  index?: number;
  isCurrentStep?: boolean;
  description?: string;
  stepColor?: string;
};
type StepMapProps = {
  steps: StepItem[];
};

const StepsMap = ({ steps }: StepMapProps) => {
  const theme = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [labelHeight, setLabelHeight] = useState<number>();
  const { sections, currentSection } = getTimelineValue(steps);

  const computeLabelHeight = (ref: HTMLDivElement | null) => {
    if (!ref) return;
    const stepsLabel: Array<HTMLSpanElement> = Array.from(ref.querySelectorAll('.step-label'));
    if (!stepsLabel.length) return;
    const heightList = stepsLabel.map((element) => element.offsetHeight);

    if (heightList.length) {
      const maxHeight = Math.max(...heightList);
      if (maxHeight !== labelHeight) setLabelHeight(maxHeight);
    }
  };

  const updateLabelHeight = useCallback(() => {
    if (window.innerWidth <= theme.breakpoints.m && labelHeight) {
      setLabelHeight(undefined);
      return;
    }
    computeLabelHeight(wrapperRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelHeight]);

  useEffect(() => {
    if (wrapperRef.current) {
      computeLabelHeight(wrapperRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  useEffect(() => {
    if (labelHeight) {
      window.addEventListener('resize', updateLabelHeight);
    } else {
      window.removeEventListener('resize', updateLabelHeight);
    }

    return () => window.removeEventListener('resize', updateLabelHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelHeight]);

  return (
    <StepsWrapper ref={wrapperRef}>
      {computeSteps(sections, currentSection, labelHeight)}
    </StepsWrapper>
  );
};

export default StepsMap;
