import { useEffect } from "react";

import { useApp, useIsMobile } from "hooks";
import { ELEMENT_IDS } from "../constants";

const loadScript = () => {
  const tag = document.createElement("script");
  tag.defer = true;
  tag.id = "ze-snippet";
  tag.src =
    "https://static.zdassets.com/ekr/snippet.js?key=0e1f6b23-94aa-4bc2-a5d5-24a4215a2fcf";
  const body = document.getElementsByTagName("body")[0];
  body.appendChild(tag);
};

const ChatButton = () => {
  const app = useApp();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!window.$zopim) {
      loadScript();
    }
  }, []);

  const setBottomOffset = (
    bottomOffset: number
  ) => {
    if (isMobile) {
      window.$zopim.livechat.button.setOffsetVerticalMobile(bottomOffset);
    } else {
      window.$zopim.livechat.button.setOffsetVertical(bottomOffset);
    }
  };

  useEffect(() => {
    if (app.user && window.$zopim) {
      // Prefill the user information
      const { email, first_name, last_name } = app.user;
      window.$zopim.livechat.set({
        email,
        name: `${first_name} ${last_name}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.user, window.$zopim]);

  useEffect(() => {
    if (window.$zopim) {
      const stickyBottomBarElm = document.getElementById(
        ELEMENT_IDS.STICKY_BOTTOM_BAR
      );

      if (stickyBottomBarElm) {
        setBottomOffset(stickyBottomBarElm.clientHeight);

        // Add a listener on the chat window hide
        window.$zopim.livechat.window.onHide(() =>
          setBottomOffset(stickyBottomBarElm.clientHeight)
        );
      } else {
        setBottomOffset(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.$zopim]);

  if (!window.$zopim) return null;

  return <></>;
};

export default ChatButton;
