import { Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

import { useIsMobile } from "hooks";
import UploadDocumentOrDownloadTemplate from "./UploadDocumentOrDownloadTemplate";
import emptyDecisionThumbnail from "./empty-decision-thumbnail.svg";

const Container = styled(Column)`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    justify-content: center;
    text-align: center;
    max-width: 640px;
    text-align: left;

    .image-n-title, .title-n-description {
      display: flex;
      align-items: center;
    }

    .title-n-description {
      flex-direction: column;
      row-gap: ${theme.spacing.xs}px;
    }

    @container (max-width: ${theme.breakpoints.l}px) {
      padding: 0 ${theme.spacing.s}px;
      && {
        row-gap: ${theme.spacing.s}px;
      }
    }

    @container (min-width: ${theme.breakpoints.l}px) {
      height: 100%;
      && {
        row-gap: ${theme.spacing.m}px;
      }
    }
  `}
`;

const ButtonsContainer = styled(Row)`
  container-type: normal;
`;

const NoDocumentView = () => {
  const isMobile = useIsMobile();

  return (
    <Container>
      <div className="title-n-description">
        <div className="image-n-title">
          <img
            src={emptyDecisionThumbnail}
            width={isMobile ? 75 : 161}
            alt="empty-decision-thumbnail"
          />
          <Title type="H3">
            Ajouter un document qui matérialise votre décision
          </Title>
        </div>

        <Text>
          Il faut matérialiser la décision par un <strong>procès-verbal</strong>{" "}
          et y détailler les décisions qui ont été prises. Vous pouvez également
          ajouter des annexes et fiches de présence.
        </Text>
      </div>

      <ButtonsContainer>
        <UploadDocumentOrDownloadTemplate />
      </ButtonsContainer>
    </Container>
  );
};

export default NoDocumentView;
