import { Button, Text, TextField } from "@yolaw/ui-kit-components";
import {
  Fieldset,
  Form,
  FormFooter,
  FormSection,
  FormSectionBody,
} from "components/FormStyles";
import { useSegment } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { AuthService } from "services";
import { PASSWORD_MIN_LENGTH } from "utils/constants";
import { FORM_ERROR_MESSAGES } from "utils/form";

type FormInputs = {
  password: string;
  passwordConfirmation: string;
};

export const UserPasswordUpdateForm = () => {
  const segment = useSegment();
  const { control, formState, handleSubmit, setError, reset } =
    useForm<FormInputs>({
      mode: "onTouched",
      defaultValues: {
        password: "",
        passwordConfirmation: "",
      },
    });

  const { errors, isDirty, isSubmitSuccessful, isSubmitting, isValid } =
    formState;

  const onSubmit = async (data: FormInputs) => {
    segment.track("user information: updated");

    const { error, success } = await AuthService.changePassword(data.password);
    if (error) {
      Object.values(error).forEach((value) => {
        setError(
          "password",
          {
            type: "custom",
            message: Array.isArray(value) ? value.join("\n") : value,
          },
          { shouldFocus: true }
        );
      });
    }

    if (success) {
      reset();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormSection>
        <FormSectionBody>
          <Fieldset>
            <Controller
              control={control}
              name="password"
              rules={{
                required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                minLength: {
                  value: PASSWORD_MIN_LENGTH,
                  message: `Votre mot de passe doit contenir au moins ${PASSWORD_MIN_LENGTH} caractères.`,
                },
              }}
              render={({ field: { ref, ...restOfField } }) => (
                <TextField
                  type="password"
                  label="Nouveau mot de passe"
                  showLabel
                  placeholder="Entrez votre nouveau mot de passe"
                  hasError={!!errors.password}
                  errorMessage={errors.password?.message}
                  {...restOfField}
                />
              )}
            />

            <Controller
              control={control}
              name="passwordConfirmation"
              rules={{
                required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                validate: {
                  bothFieldsRequired: (value: string, formValues: FormInputs) =>
                    !value || !formValues.password
                      ? "Les deux champs sont obligatoires."
                      : true,
                  matchValues: (value: string, formValues: FormInputs) =>
                    value !== formValues.password
                      ? "Les mots de passe ne sont pas identiques."
                      : true,
                },
              }}
              render={({ field: { ref, ...restOfField } }) => (
                <TextField
                  type="password"
                  label="Confirmation du mot de passe"
                  showLabel
                  placeholder="Entrez votre nouveau mot de passe"
                  hasError={!!errors.passwordConfirmation}
                  errorMessage={errors.passwordConfirmation?.message}
                  {...restOfField}
                />
              )}
            />
          </Fieldset>
          {isSubmitSuccessful && !isDirty && (
            <Text color="primary.main" fontWeightVariant="bold">
              Vos informations ont été enregistrées !
            </Text>
          )}
          <FormFooter>
            <Button
              type="submit"
              variant="primary"
              text="Mettre à jour"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            />
          </FormFooter>
        </FormSectionBody>
      </FormSection>
    </Form>
  );
};

export default UserPasswordUpdateForm;
