import { Elements } from "@stripe/react-stripe-js";
import { Column, Text } from "@yolaw/ui-kit-components";
import { useEffect, useReducer, useRef } from "react";
import styled, { css } from "styled-components";

import ButtonContactPhone from "components/ButtonContactPhone";
import Modal, { CustomHTMLDialogElement } from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, usePrevious, useSegment } from "hooks";
import StripeProvider from "providers/stripe";
import { LegacySubscriptionService, UserService } from "services";
import PaymentModalContext, {
  PaymentModalContextAction,
  PaymentModalContextState,
  PaymentModalStep,
} from "./context";
import { IntroductionPage, PaymentPage, PlanSelectionPage } from "./pages";
import { IntroductionPageProps } from "./pages/Introduction";
import { PlanSelectionProps } from "./pages/PlanSelection";

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
  .modal-container-inner {
    @media (min-width: ${theme.breakpoints.l}px) {
      max-width: ${theme.breakpoints.l}px;
    }
  `}
`;

const BodyContainer = styled(Column)`
  align-items: center;
  text-align: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;
  `}

  sup {
    font-size: 0.5em;
  }
`;

const getStepNameForTrackingEvent = (step: PaymentModalStep) => {
  switch (step) {
    case PaymentModalStep.Introduction:
      return "benefits";
    case PaymentModalStep.PlanSelection:
      return "plans";
    case PaymentModalStep.Payment:
      return "form";
  }
};

type SubscriptionPaymentModalProps = {
  modalName: ModalName;
  subscriptionTypeSlug: string;
  modalConfigs: PaymentModalContextState["modalConfigs"];
  stepsConfigs: {
    [PaymentModalStep.Introduction]: IntroductionPageProps;
    [PaymentModalStep.PlanSelection]: PlanSelectionProps;
  };
};

/** This component handles the payment process for legacy subscriptions. */
const LegacySubscriptionPaymentModal = ({
  modalName,
  subscriptionTypeSlug,
  modalConfigs,
  stepsConfigs,
}: SubscriptionPaymentModalProps) => {
  const app = useApp();
  const segment = useSegment();
  const prevOpeningModal = usePrevious(app.openingModal);
  const modalRef = useRef<CustomHTMLDialogElement | null>(null);

  const [state, dispatch] = useReducer(
    PaymentModalContext.reducer,
    PaymentModalContext.initialState
  );
  const prevStep =
    usePrevious(state.currentModalStep) || state.currentModalStep;

  useEffect(() => {
    dispatch({
      type: PaymentModalContextAction.SetModalRef,
      payload: modalRef,
    });
  }, [modalRef]);

  const getSubscriptionDetails = async () => {
    const subscription =
      await LegacySubscriptionService.getSubscriptionDetails(
        subscriptionTypeSlug
      );
    dispatch({
      type: PaymentModalContextAction.SetSubscriptionDetails,
      payload: subscription,
    });
  };

  const getSavedPaymentMethods = async () => {
    const savedPaymentMethods = await UserService.getPaymentMethods();
    dispatch({
      type: PaymentModalContextAction.SetSavedPaymentMethods,
      payload: savedPaymentMethods,
    });
  };

  useEffect(() => {
    // Whenever the modal is opened
    if (app.openingModal?.name === modalName) {
      if (!state.subscription) {
        // Get subscription info if it's not there
        getSubscriptionDetails();
      }
      if (!state.savedPaymentMethods) {
        // Get saved payment method if it's not there
        getSavedPaymentMethods();
      }
    }

    // When `modalName` modal is closed, reset its step to `PaymentModalStep.Introduction`
    if (prevOpeningModal?.name === modalName && !app.openingModal) {
      dispatch({
        type: PaymentModalContextAction.SetPaymentModalStep,
        payload: PaymentModalStep.Introduction,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.openingModal]);

  useEffect(() => {
    if (app.openingModal?.name === modalName) {
      // Only check if the modal is currently opened
      const comeFrom: string =
        state.currentModalStep === prevStep
          ? app.openingModal.openedBy.from // modal just opened
          : getStepNameForTrackingEvent(prevStep); // jumping between steps

      segment.track("paywall: displayed", {
        context: app.openingModal.openedBy.context,
        from: comeFrom,
        "subscription product": subscriptionTypeSlug,
        step: getStepNameForTrackingEvent(state.currentModalStep),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentModalStep, app.openingModal]);

  useEffect(() => {
    dispatch({
      type: PaymentModalContextAction.SetModalConfigs,
      payload: modalConfigs,
    });
  }, [modalConfigs]);

  const onClickBackHandler = () => {
    switch (state.currentModalStep) {
      case PaymentModalStep.PlanSelection:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.Introduction,
          });
      case PaymentModalStep.Payment:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.PlanSelection,
          });
      case PaymentModalStep.Introduction:
      default:
        return undefined;
    }
  };

  const renderPageContent = () => {
    switch (state.currentModalStep) {
      case PaymentModalStep.Introduction:
        return (
          <IntroductionPage {...stepsConfigs[PaymentModalStep.Introduction]} />
        );
      case PaymentModalStep.PlanSelection:
        return (
          <PlanSelectionPage
            {...stepsConfigs[PaymentModalStep.PlanSelection]}
          />
        );
      case PaymentModalStep.Payment:
      default:
        return <PaymentPage />;
    }
  };

  return (
    <Elements stripe={StripeProvider.stripePromise}>
      <PaymentModalContext.Context.Provider value={{ state, dispatch }}>
        <StyledModal
          isOpen={app.openingModal?.name === modalName}
          onClose={app.closeModal}
          onClickBack={onClickBackHandler()}
          ref={modalRef}
        >
          <BodyContainer>
            {renderPageContent()}
            <div>
              <ButtonContactPhone />
              <Text type="small" color="neutral.dark">
                Nos conseillers sont disponibles du lundi au vendredi de 10h à
                18h
              </Text>
            </div>
          </BodyContainer>
        </StyledModal>
      </PaymentModalContext.Context.Provider>
    </Elements>
  );
};

export default LegacySubscriptionPaymentModal;
