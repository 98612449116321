import { memo, useReducer } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { styled } from "styled-components";

import { LayoutContext } from "../contexts";
import AdminToolbox from "./AdminToolbox";
import { AppHeader } from "./header";

const MainLayout = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "app-body";

  --modal-backdrop-color: ${({ theme }) => theme.colors.secondary.main}B2;

  &#app-container[covered] {
    --overlay-content: "";
    &:before {
      display: flex;
      content: var(--overlay-content);
      position: fixed;
      inset: 0;
      z-index: 9999;
      background-color: var(--modal-backdrop-color);
      transition: background-color 400ms ease 0s;
      will-change: background-color;
      justify-content: center;
      align-items: center;
      font-size: 80px;
    }
  }
`;

const AppBody = styled.div`
  min-height: 100%;
`;

export const GlobalLayout = memo(() => {
  const [layoutState, layoutDispatch] = useReducer(
    LayoutContext.reducer,
    LayoutContext.initialState
  );

  return (
    <LayoutContext.Context.Provider
      value={{ state: layoutState, dispatch: layoutDispatch }}
    >
      <AdminToolbox />
      <MainLayout id="app-container">
        <AppHeader />
        <AppBody>
          <Outlet />
        </AppBody>
      </MainLayout>
      <ScrollRestoration />
    </LayoutContext.Context.Provider>
  );
});
