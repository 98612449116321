import {
  Button,
  Checkbox,
  Column,
  Row,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { useApp } from "hooks";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { AuthService } from "services";
import styled, { css } from "styled-components";
import { PASSWORD_MIN_LENGTH } from "utils/constants";
import { EMAIL_REGEX } from "utils/email";
import { FORM_ERROR_MESSAGES } from "utils/form";

const Form = styled(Column).attrs({
  as: "form",
})`
  align-self: center;
  width: 520px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xxxs}px;
  `};
`;

const SubmitButton = styled(Button)``;

const SubTitle = styled(Title)`
  align-self: flex-start;
`;

const FieldSetContainer = styled(Column)`
  width: 100%;
`;

const EmailOptInField = styled(Row)`
  align-items: flex-start;
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xxxs}px;
  `};
`;

const TitleContainer = styled.div`
  width: 100%;
`;

type FormInputs = Omit<
  TUserInformationInputs & {
    password: string;
    confirmPassword: string;
    emailOptIn: boolean;
  },
  "phoneNumber"
>;

export const SignUpPage = () => {
  const { user, setUserInfo } = useApp();

  const searchParams = new URLSearchParams(window.location.search);
  const redirectionUrlAfterSuccess = searchParams.get("next") || "/";

  const submitPassword = async (password: string) => {
    const { success, error } = await AuthService.changePassword(password);

    if (error) {
      const e = error as any;

      setError(
        "root",
        {
          type: "custom",
          message:
            e?.password?.password[0] ??
            "Une erreur est survenue lors de la création de votre compte.",
        },
        { shouldFocus: true }
      );
    }

    if (success) {
      window.location.assign(redirectionUrlAfterSuccess);
    }
  };

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      email: user?.email || "",
      password: "",
      confirmPassword: "",
      emailOptIn: user?.ok_for_emails || true,
    },
  });

  const { control, handleSubmit, formState, setError, trigger } = formMethods;

  const { isSubmitting } = formState;

  const password = useWatch({
    control,
    name: "password",
  });

  const validationRules = {
    firstName: {
      required: !!user?.first_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
    },
    lastName: {
      required: !!user?.last_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
    },
    email: {
      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address format",
      },
    },
    password: {
      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
      minLength: {
        value: PASSWORD_MIN_LENGTH,
        message: `Votre mot de passe doit contenir au moins ${PASSWORD_MIN_LENGTH} caractères.`,
      },
    },
    confirmPassword: {
      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
      validate: (value: string) =>
        value !== password ? "Les mots de passe ne sont pas identiques." : true,
    },
  };

  const { errors } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    const newUserData = await AuthService.patchUserProfile({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      ok_for_emails: data.emailOptIn,
    });
    if (newUserData) {
      setUserInfo(newUserData);
    }

    await submitPassword(data.password);
  };

  useEffect(() => {
    if (user?.has_set_password) window.location.assign("/");
  }, [user?.has_set_password]);

  useEffect(() => {
    if (formState.errors.confirmPassword) trigger("confirmPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, trigger]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer>
          <Title text="Création de votre compte" type="H3" />
        </TitleContainer>
        <SubTitle text="Vos informations personnelles" type="H4" />
        <UserInformationFieldset
          validationRules={validationRules}
          showPhone={false}
        />
        <SubTitle text="Mot de passe" type="H4" textAlign="left" />
        <FieldSetContainer>
          <Row>
            <Controller
              control={control}
              name="password"
              rules={{ ...validationRules?.password }}
              render={({ field: { ref, ...restOfField } }) => (
                <TextField
                  type="password"
                  id="password"
                  placeholder="Mot de passe"
                  showLabel
                  hasError={!!errors.password}
                  errorMessage={errors.password?.message}
                  {...restOfField}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              rules={{ ...validationRules?.confirmPassword }}
              render={({ field: { ref, ...restOfField } }) => (
                <TextField
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirmez votre mot de passe"
                  showLabel
                  hasError={!!errors.confirmPassword}
                  errorMessage={errors.confirmPassword?.message}
                  {...restOfField}
                />
              )}
            />
          </Row>
          <Row>
            <Text
              type="small"
              text={`Votre mot de passe doit contenir au mininmum ${PASSWORD_MIN_LENGTH} caractères`}
            />
          </Row>
          <EmailOptInField>
            <Controller
              control={control}
              name="emailOptIn"
              render={({ field: { ref, value, ...restOfField } }) => (
                <Checkbox
                  {...restOfField}
                  id="emailOptIn"
                  checked={value}
                  value={JSON.stringify(value)}
                />
              )}
            />
            <label htmlFor="emailOptIn">
              <Text type="small">
                J'accepte que{" "}
                <strong>
                  Legalstart.fr m'envoie des informations sur des promotions ou
                  des services fournis par Legalstart.fr
                </strong>{" "}
                (promis, que des informations utiles, pas de spam !)
              </Text>
            </label>
          </EmailOptInField>
        </FieldSetContainer>
        {errors.root && (
          <Text
            type="small"
            text={errors.root.message}
            color="error"
            fontWeightVariant="bold"
          />
        )}
        <SubmitButtonContainer>
          <SubmitButton
            type="submit"
            variant="primary"
            text="Valider"
            disabled={!formState.isValid || isSubmitting}
            isLoading={isSubmitting}
          />
        </SubmitButtonContainer>
      </Form>
    </FormProvider>
  );
};
