import styled, { css } from "styled-components";

export const CardsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
  list-style: none;
  ${({ theme }) => css`
    grid-gap: ${theme.spacing.xs}px;

    @media (min-width: ${theme.breakpoints.l}px) {
      grid-gap: ${theme.spacing.s}px;
    }
  `}
`;
