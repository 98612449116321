import { BackButton } from "pages/components";
import { Outlet } from "react-router-dom";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";

const PageContentContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.m}px ${theme.spacing.s}px;
    @media (min-width: ${theme.breakpoints.l}px) {
      padding: 3rem;
    }
  `}
`;

const SubPageLayout = () => {
  return (
    <>
      <BackButton to={BuiltRoutePath.LegalAssistancePage} />
      <PageContentContainer>
        <Outlet />
      </PageContentContainer>
    </>
  );
};

export default SubPageLayout;
