import React from "react";

export enum FormalityStatus {
  Cancelled = "cancelled",
  Done = "done",
  Draft = "draft",
  InProgress = "in_progress",
  /** @deprecated grouped to `InProgress` status */
  InProgressByLS = "in_progress_by_ls",
  /** @deprecated grouped to `InProgress` status */
  InProgressByUser = "in_progress_by_user",
}

const GROUP_BY_STATUS_TITLE: Record<FormalityStatus, string> = {
  [FormalityStatus.Cancelled]: "Vos démarches abandonnées",
  [FormalityStatus.Done]: "Vos démarches terminées",
  [FormalityStatus.Draft]: "Vos démarches débutées récemment",
  [FormalityStatus.InProgress]: "Démarches en cours",
  [FormalityStatus.InProgressByLS]: "Nous prenons le relais",
  [FormalityStatus.InProgressByUser]: "À vous de jouer",
};

type FormalitiesContextState = {
  isLoaded: boolean;
  formalities: Formality[];
  groups: {
    byStatus: Record<
      FormalityStatus,
      {
        formalities: Formality[];
        title: string;
      }
    >;
  };
};

const _filterFormalitiesByStatus = (
  formalities: Formality[],
  status: FormalityStatus
) => {
  return formalities.filter((formality) => formality.status === status);
};

const _groupFormalitiesByStatus = (formalities: Formality[]) => {
  return Object.values(FormalityStatus).reduce((obj, status) => {
    obj[status] = {
      formalities: _filterFormalitiesByStatus(formalities, status),
      title: GROUP_BY_STATUS_TITLE[status],
    };
    return obj;
  }, {} as FormalitiesContextState["groups"]["byStatus"]);
};

const _groupFormalities = (
  state: FormalitiesContextState,
  formalities: Formality[]
): FormalitiesContextState["groups"] => {
  return {
    ...state.groups,
    byStatus: _groupFormalitiesByStatus(formalities),
  };
};

const initialState: FormalitiesContextState = {
  isLoaded: false,
  formalities: [],
  groups: {
    byStatus: _groupFormalitiesByStatus([]),
  },
};

enum ActionType {
  SetFormalities,
  UpdateFormality,
}

type FormalitiesContextAction =
  | {
      type: ActionType.SetFormalities;
      payload: Formality[];
    }
  | {
      type: ActionType.UpdateFormality;
      payload: Formality;
    };

const reducer = (
  state: FormalitiesContextState,
  action: FormalitiesContextAction
): FormalitiesContextState => {
  switch (action.type) {
    case ActionType.SetFormalities:
      return {
        ...state,
        isLoaded: true,
        formalities: action.payload,
        groups: _groupFormalities(state, action.payload),
      };
    case ActionType.UpdateFormality:
      const updatedFormalities = state.formalities.map((formality) => {
        if (
          formality.source_type === action.payload.source_type &&
          formality.source_id === action.payload.source_id
        ) {
          return action.payload;
        } else {
          return formality;
        }
      });
      return {
        ...state,
        formalities: updatedFormalities,
        groups: _groupFormalities(state, updatedFormalities),
      };
    default:
      throw new Error("[FormalitiesContext] unknown action type");
  }
};

type FormalitiesContextType = {
  state: FormalitiesContextState;
  dispatch: React.Dispatch<FormalitiesContextAction>;
};

const Context = React.createContext<FormalitiesContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "FormalitiesContext";

const FormalitiesContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default FormalitiesContext;
