import { Column } from "@yolaw/ui-kit-components";
import { LayoutContext } from "contexts";
import { useIsMobile } from "hooks";
import { useContext } from "react";
import styled from "styled-components";
import { Sidebar } from "./Sidebar";
import { LegalEntitySwitch } from "./legalEntitySwitch";
import { Navigation } from "./navigation";

const MenuContainer = styled(Column)`
  && {
    row-gap: ${(props) => props.theme.spacing.s}px;
  }

  padding: ${(props) => props.theme.spacing.s}px;
`;

const AppMenu = () => {
  const isMobile = useIsMobile();
  const { state: layoutState } = useContext(LayoutContext.Context);

  const { isMenuOpen } = layoutState;

  return (
    <Sidebar open={isMenuOpen}>
      <MenuContainer>
        {/* FIXME: isMobile isn't changed on window resize */}
        {isMobile && <LegalEntitySwitch />}
        <Navigation />
      </MenuContainer>
    </Sidebar>
  );
};

export default AppMenu;
