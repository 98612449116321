import { Column, Row, Tag, Title } from "@yolaw/ui-kit-components";
import { formatDate } from "date-fns";
import styled, { css } from "styled-components";

import { BackButton } from "pages/components";
import DecisionOptionsMenu from "../decisions-section/DecisionCard/OptionsMenu";
import useDecision from "../hooks/useDecision";
import DocumentListItem from "./DocumentListItem";

const Container = styled(Column)`
  ${({ theme }) => css`
    flex: 1;
    height: 100%;
    && {
      row-gap: ${theme.spacing.s}px;
    }

    @media (max-width: ${theme.breakpoints.l}px) {
      .inner-content {
        padding: 0 ${theme.spacing.s}px;
      }
    }

    @media (min-width: ${theme.breakpoints.l}px) {
      padding: ${theme.spacing.xxs}px ${theme.spacing.s}px;
    }
  `}
`;

const Header = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `}
`;

const StyledTag = styled(Tag).attrs({
  size: "small",
})`
  align-self: flex-start;
`;

const DocumentList = styled(Column).attrs({
  as: "ul",
})`
  list-style: none;
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.l}px) {
      && {
        row-gap: ${theme.spacing.s}px;
      }
    }
  `};
`;

type DecisionDetailsProps = {
  hideDocuments?: boolean;
};
const DecisionDetails = ({ hideDocuments }: DecisionDetailsProps) => {
  const decision = useDecision();

  if (!decision) return null;

  return (
    <Container>
      <BackButton label="Retour à toutes mes décisions" relative="path" />
      <Header className="inner-content">
        <Row>
          <Title type="H3">{decision.title}</Title>
          <DecisionOptionsMenu decision={decision} />
        </Row>
        <Row style={{ justifyContent: "flex-start" }}>
          {decision.finalized && <StyledTag color="green">Finalisé</StyledTag>}
          <StyledTag color="blue">{`Date de la décision : ${formatDate(
            new Date(decision.date),
            "dd/MM/yyyy"
          )}`}</StyledTag>
        </Row>
      </Header>

      <DocumentList className="inner-content">
        {!hideDocuments &&
          decision.visibleDocuments?.map((document) => (
            <li key={document.id}>
              <DocumentListItem document={document} />
            </li>
          ))}
      </DocumentList>
    </Container>
  );
};

export default DecisionDetails;
