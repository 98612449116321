import { useIsMobile } from "hooks/useIsMobile";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

const Image = styled.img<{
  hideOnMobile: boolean;
}>`
  max-width: 100%;
  ${({ theme, hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media (max-width: ${theme.breakpoints.m}px) {
        display: none;
      }
    `}
`;

type ImageViewerProps = {
  sourceUrl:
    | string
    | {
        mobile: string;
        desktop: string;
      };
  alt?: string;
  height?: string | number;
  width?: string | number;
  hideOnMobile?: boolean;
};

const ImageViewer = ({
  sourceUrl,
  alt = "",
  height,
  width,
  hideOnMobile = false,
}: ImageViewerProps) => {
  const isMobile = useIsMobile();
  const [image, setImage] = useState("");

  const imgSrcStr =
    typeof sourceUrl === "string"
      ? sourceUrl
      : isMobile
      ? sourceUrl.mobile
      : sourceUrl.desktop;

  const getImage = useCallback(async () => {
    if (imgSrcStr.startsWith("http")) {
      // If it's an absolute link, use it directly
      setImage(imgSrcStr);
    } else {
      // It's a relative URL, try to import it from `../assets`
      const response = await import(`../assets${imgSrcStr}`);
      setImage(response?.default || "");
    }
  }, [imgSrcStr]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <Image
      alt={alt}
      src={image}
      height={height}
      width={width}
      hideOnMobile={hideOnMobile}
    />
  );
};

export default React.memo(ImageViewer);
