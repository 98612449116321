import { Route, Routes } from "react-router-dom";

import { MandatoryRecordsPage } from "./MandatoryRecordsPage";
import MyRegistryView from "./MyRegistryView";
import { DecisionView } from "./decision-view";

const DecisionsApp = () => {
  return (
    <Routes>
      <Route index element={<MandatoryRecordsPage />} />
      <Route path=":decisionId/*" element={<DecisionView />} />
      <Route path="mon-registre" element={<MyRegistryView />}/>
    </Routes>
  );
};

export default DecisionsApp;
