import { Button, Dropdown, TextArea, Title } from "@yolaw/ui-kit-components";
import {
  Fieldset,
  Form,
  FormFooter,
  FormSection,
  FormSectionBody,
} from "components/FormStyles";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { AppContext } from "contexts";
import { useSegment } from "hooks";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AssistanceService } from "services";
import {
  AppointmentLocationState,
  AssistanceAJContactFormData,
  AssistanceQuestionTheme,
  AssistanceQuestionType,
} from "types/assistance";
import { FORM_ERROR_MESSAGES } from "utils/form";

const trackingEventProperties = {
  "contact form": "assistance juridique",
};

const SET_VALUE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

enum InputName {
  questionThemeId = "questionThemeId",
  questionSubThemeId = "questionSubThemeId",
  questionText = "questionText",
}

type FormInputs = TUserInformationInputs & {
  [InputName.questionThemeId]: number;
  [InputName.questionSubThemeId]: number;
  [InputName.questionText]: string;
};

const QuestionForm = () => {
  const segment = useSegment();
  const navigate = useNavigate();
  const { state: appState } = useContext(AppContext.Context);
  const { user } = appState;

  const [questionThemes, setQuestionThemes] = useState<
    AssistanceQuestionTheme[]
  >([]);

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      phoneNumber: user?.phone || "",
      email: user?.email || "",
      [InputName.questionThemeId]: 0,
      [InputName.questionSubThemeId]: 0,
      [InputName.questionText]: "",
    },
  });

  const { control, formState, handleSubmit, setValue, watch } = formMethods;
  const { errors, isValid, isSubmitting } = formState;

  const watchQuestionThemeId = watch(InputName.questionThemeId);

  const _getQuestionThemes = async () => {
    const qThemes = await AssistanceService.getQuestionThemes(
      AssistanceQuestionType.AssistanceJuridique
    );
    setQuestionThemes(qThemes);
  };

  useEffect(() => {
    if (!questionThemes.length) {
      _getQuestionThemes();
    }
  }, [questionThemes]);

  useEffect(() => {
    segment.track(
      "assistance contact form: displayed",
      trackingEventProperties
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategoryDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(
      InputName.questionThemeId,
      Number(event.target.value),
      SET_VALUE_OPTIONS
    );
  };

  const onSubCategoryDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(
      InputName.questionSubThemeId,
      Number(event.target.value),
      SET_VALUE_OPTIONS
    );
  };

  const onQuestionTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(InputName.questionText, event.target.value, SET_VALUE_OPTIONS);
  };

  const onSubmit = async (data: FormInputs) => {
    segment.track(
      "assistance contact form: completed",
      trackingEventProperties
    );

    const {
      questionThemeId,
      questionSubThemeId,
      questionText,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = data;

    const submitData: AssistanceAJContactFormData = {
      type: AssistanceQuestionType.AssistanceJuridique,
      question_theme_id: questionSubThemeId || questionThemeId,
      question_text: questionText,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
    };

    await AssistanceService.createTicket(submitData);

    const questionTheme = questionThemes.find(
      (theme) => theme.id === questionThemeId
    );
    const questionSubTheme = questionTheme?.subthemes.find(
      (subTheme) => subTheme.id === questionSubThemeId
    );
    const locationState: AppointmentLocationState = {
      questionTheme: questionTheme?.name,
      questionSubTheme: questionSubTheme?.name,
      questionText: questionText,
    };

    // Navigate to the appointment page with question and question theme
    // to fill the Calendly widget form
    navigate("./appointment", {
      state: locationState,
    });
  };

  const getSubThemeOptions = (themeId: number) => {
    return questionThemes
      .find((theme) => theme.id === themeId)
      ?.subthemes.map((subTheme) => ({
        label: subTheme.name,
        value: subTheme.id,
      }));
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Title text="Quelle est votre question ?" type="h3" />
          <FormSectionBody>
            <Fieldset>
              <Controller
                control={control}
                name={InputName.questionThemeId}
                rules={{
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    placeholder="Choisissez une catégorie"
                    label="Choisissez une catégorie"
                    options={questionThemes.map((theme) => ({
                      label: theme.name,
                      value: theme.id,
                    }))}
                    errorMessage={errors[field.name]?.message}
                    onChange={onCategoryDropdownChange}
                  />
                )}
              />
              {watchQuestionThemeId ? (
                <Controller
                  control={control}
                  name={InputName.questionSubThemeId}
                  rules={{
                    required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                  }}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      placeholder="Précisez..."
                      options={getSubThemeOptions(watchQuestionThemeId) || []}
                      errorMessage={errors[field.name]?.message}
                      onChange={onSubCategoryDropdownChange}
                    />
                  )}
                />
              ) : null}
              <Controller
                control={control}
                name={InputName.questionText}
                rules={{
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                }}
                render={() => (
                  <TextArea
                    label="Vos questions"
                    placeholder="Pour un entretien efficace et une réponse à toutes vos questions, merci de nous donner un maximum de détails ici"
                    onChange={onQuestionTextAreaChange}
                    errorMessage={errors.questionText?.message}
                  />
                )}
              />
            </Fieldset>
          </FormSectionBody>
        </FormSection>
        <FormSection>
          <Title text="Comment pouvons-nous vous joindre ?" type="h3" />
          <FormSectionBody>
            <UserInformationFieldset
              validationRules={{
                firstName: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                lastName: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                phoneNumber: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                email: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
              }}
            />
            <FormFooter>
              <Button
                type="submit"
                variant="primary"
                text="Choisir un créneau"
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </FormFooter>
          </FormSectionBody>
        </FormSection>
      </Form>
    </FormProvider>
  );
};

export default QuestionForm;
