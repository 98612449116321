import { AppContext } from "contexts";
import { useContext } from "react";
import { AuthService, LegacySubscriptionService } from "services";
import { redirectToLoginPage } from "services/auth/redirection";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import useSegment from "./useSegment";

export const useUser = () => {
  const segment = useSegment();
  const { state: appState } = useContext(AppContext.Context);
  const { user, legacySubscriptions } = appState;

  /** user has subscribed a legacy subscription and the subscription is still active */
  const hasSubscribedLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ): boolean => {
    return !!LegacySubscriptionService.findLegacySubscription(
      legacySubscriptions,
      subSlugOrPrefix
    );
  };

  const logout = () => {
    segment.track("user: logged out", {
      context: "userspace",
      button: "userspace",
    });
    AuthService.clearAuthInfo();

    redirectToLoginPage();
  };

  return {
    ...user,
    hasSubscribedLegacySubscription,
    hasSubscribedAJ:
      hasSubscribedLegacySubscription(LegacySubscriptionSlug.AssistanceJuridique) ||
      hasSubscribedLegacySubscription(LegacySubscriptionSlug.WebConformite) ||
      hasSubscribedLegacySubscription(LegacySubscriptionSlug.WebAssistanceJuridique) ||
      hasSubscribedLegacySubscription(LegacySubscriptionSlug.WebAe),
    logout,
  };
};

export default useUser;
