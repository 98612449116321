import _compact from "lodash/compact";
import { useContext } from "react";
import { LegacySubscriptionService } from "services";
import { LegacySubscriptionStatus } from "types/legacy-subscriptions";
import ZenContext, { ZenDashboard } from "../context";
import ZenService from "../services/apis";
import useZenRoutes from "./useZenRoutes";

const useZenProject = () => {
  const {
    state: {
      currentDashboard,
      onboardingQuestionnaire,
      shouldDisplayZenIntro,
      info,
      isGeneratingTasks,
      tasks,
      legalObligationTasks: legalObligationTasksSections,
      administrativeTasks: administrativeTasksSections,
    },
    dispatch,
  } = useContext(ZenContext.Context);
  const { goToDashboard } = useZenRoutes();

  const getTaskById = (taskId: number) =>
    tasks.find((task) => task.id === taskId);
  const getTasksByIds = (taskIds: number[]) =>
    _compact(taskIds.map((taskId) => getTaskById(taskId)));

  const findTaskBySlug = (taskSlug: TaskItem["type"]["slug"]) =>
    tasks.find((task) => task.type.slug === taskSlug);

  const legalObligationTasks = {
    preSirenTasks: getTasksByIds(legalObligationTasksSections.preSirenTaskIds),
    postSirenTasks: getTasksByIds(
      legalObligationTasksSections.postSirenTaskIds
    ),
    overdueTasks: getTasksByIds(legalObligationTasksSections.overdueTaskIds),
    todoTasks: getTasksByIds(legalObligationTasksSections.todoTaskIds),
    doneTasks: getTasksByIds(legalObligationTasksSections.doneTaskIds),
  };

  // NOTES: This ordering is the default order for the administrative dashboard
  const administrativeTasks = {
    remunerationTasks: getTasksByIds(
      administrativeTasksSections.remunerationTaskIds
    ),
    protectionTasks: getTasksByIds(
      administrativeTasksSections.protectionTaskIds
    ),
    administrativeTasks: getTasksByIds(
      administrativeTasksSections.administrativeTaskIds
    ),
  };

  const isZenSubscriptionNeeded = !LegacySubscriptionService.isActiveStatus(
    info.zen_subscription_status as LegacySubscriptionStatus
  );

  const setCurrentDashboard = (dashboard: ZenDashboard) => {
    dispatch({
      type: "SET_CURRENT_DASHBOARD",
      payload: dashboard,
    });
  };

  const setTasks = (newTasks: TaskItem[]) => {
    dispatch({
      type: "SET_TASKS",
      payload: newTasks,
    });
  };

  const updateTask = (task: TaskItem) => {
    dispatch({
      type: "UPDATE_TASK",
      payload: task,
    });
  };

  const markOnboardingQuestionnaireAsDone = async () => {
    dispatch({
      type: "SET_IS_GENERATING_TASKS",
      payload: true,
    });

    // Wait for mark onboarding questionnaire as done request to be completed
    await ZenService.project(info.id).onboarding.markAsDone();

    dispatch({
      type: "SET_IS_GENERATING_TASKS",
      payload: false,
    });

    goToDashboard({
      searchParams: "?utm_source=questionnaire",
      navigateOptions: { replace: true },
    });
  };

  const patchAnswers = (key: string, value: any) => {
    dispatch({
      type: "PATCH_ANSWERS",
      payload: {
        key,
        value,
      },
    });
  };

  const _getOnboardingQuestionnaire = async (projectId: string) => {
    const _onboardingQuestionnaire = await ZenService.project(
      Number(projectId)
    ).onboarding.get();

    dispatch({
      type: "SET_ONBOARDING_QUESTIONNAIRE",
      payload: _onboardingQuestionnaire,
    });
  };

  const getProjectInfo = async (projectId: string) => {
    const projectInfo = await ZenService.project(Number(projectId)).getInfo();
    dispatch({
      type: "SET_PROJECT_INFO",
      payload: projectInfo,
    });

    await _getOnboardingQuestionnaire(projectId);
  };

  const getTasks = async (refresh?: boolean) => {
    const taskList = await ZenService.project(info.id).getTasks(refresh);
    // Store latest tasks in context
    setTasks(taskList);
    return taskList;
  };

  /** a.k.a Ask BE to regenerate the tasks */
  const refreshProjectAndTasks = async () => {
    await getProjectInfo(String(info.id));
    await getTasks(true);
  };

  const hideZenIntro = () => {
    dispatch({ type: "HIDE_ZEN_INTRO" });
  };

  const logZenRatings = async (data: ZenRatingData) => {
    const ratingEntity = await ZenService.project(info.id).logZenRatings(data);
    // Update the project rating
    dispatch({
      type: "LOG_ZEN_RATINGS",
      payload: ratingEntity,
    });
  };

  return {
    currentDashboard,
    onboardingQuestionnaire,
    shouldDisplayZenIntro,
    info,
    isGeneratingTasks,
    isZenSubscriptionNeeded,
    legalObligationTasks,
    administrativeTasks,
    hasCompanySiren: !!info.company_siren,
    setCurrentDashboard,
    setTasks,
    updateTask,
    patchAnswers,
    getTaskById,
    getTasksByIds,
    findTaskBySlug,
    markOnboardingQuestionnaireAsDone,
    getProjectInfo,
    getTasks,
    refreshProjectAndTasks,
    hideZenIntro,
    logZenRatings,
  };
};

export default useZenProject;
