import { Column } from "@yolaw/ui-kit-components";
import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";
import { LegalStartLogo } from "@yolaw/ui-kit-icons";

const PageContainer = styled.div`
  container-type: inline-size;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
    padding: ${theme.spacing.xs}px;

    a {
      color: ${theme.colors.primary.main};
      font-weight: bold;
      cursor: pointer;
    }
  `};
`;

const FormContainer = styled(Column)`
  align-items: center;
  max-width: 560px;
  ${({ theme }) => css`
    background-color: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.medium};
    && {
      row-gap: ${theme.spacing.m}px;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.l}px ${theme.spacing.s}px;
      width: 100%;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.l}px;
    }
  `};
`;

const AuthenticationLayout = () => {
  return (
    <PageContainer>
      <FormContainer>
        <LegalStartLogo width={270} height={56}/>
        <Outlet />
      </FormContainer>
    </PageContainer>
  );
};

export default AuthenticationLayout;
