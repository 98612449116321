import { Column, Text, Title } from "@yolaw/ui-kit-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { useFluidLayout, useIsMobile } from "hooks";
import { Sidebar } from "layout/Sidebar";
import { BackButton } from "pages/components";
import downloadFile from "pages/zen/utils/downloadFile";
import DocumentView from "./decision-view/DocumentView";

const DESKTOP_SIDEBAR_WIDTH = 500;

const Container = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.l}px) {
      display: grid;
      min-height: 100%;
      grid-template-areas: "sidebar main-content";
      grid-template-columns: ${DESKTOP_SIDEBAR_WIDTH}px 1fr;

      > .sidebar {
        z-index: unset;
      }
    }
  `}
`;

const InnerContent = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `}
`;

const SidebarInnerContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }

    @media (max-width: ${theme.breakpoints.l}px) {
      .inner-content {
        padding: 0 ${theme.spacing.s}px;
      }
    }

    @media (min-width: ${theme.breakpoints.l}px) {
      padding: ${theme.spacing.xxs}px ${theme.spacing.s}px;
    }
  `}
`;

export const MyRegistryView = () => {
  useFluidLayout();
  const {
    state: { fileURL },
  } = useLocation();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (fileURL) {
      toast.success("Votre registre a été généré avec succès !");

      if (isMobile) {
        // Download the file if it's on mobile device
        downloadFile(fileURL, "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Sidebar open>
        <SidebarInnerContainer>
          <BackButton label="Retour à toutes mes décisions" relative="path" />

          <InnerContent className="inner-content">
            <Title type="H3">Registre digital et sécurisé</Title>
            <Text>
              Vous venez de créer un registre avec horodatage conforme au Décret
              n°2019-1118 du 31 octobre 2019 relatif à la dématérialisation des
              registres. Veillez à vérifier que l’intégralité des documents soit
              conforme à vos attentes. Legalstart ne pourra pas être tenu
              responsable d’un éventuel oubli ou d’une erreur de votre part.
            </Text>
          </InnerContent>
        </SidebarInnerContainer>
      </Sidebar>
      {!isMobile && <DocumentView url={fileURL} />}
    </Container>
  );
};

export default MyRegistryView;
