import { StripeConstructorOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const stripeConstructorOptions: StripeConstructorOptions = {
  locale: "fr-FR",
};

const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
  stripeConstructorOptions
);

const StripeProvider = {
  stripePromise,
};

export default StripeProvider;
