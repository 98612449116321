import type { Blocker } from "@remix-run/router";
import { Button, Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { useIsMobile } from "hooks";
import styled, { css } from "styled-components";

const MainContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `};
`;

const ContentContainer = styled(Column)`
  text-align: center;
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `};
`;

type Props = {
  blocker: Blocker;
};

const DocumentSelectionCancellationModal = ({ blocker }: Props) => {
  const isMobile = useIsMobile();

  const cancelAndGo = () => {
    blocker.proceed?.();
  };

  const stayAndContinue = () => {
    blocker.reset?.();
  };

  return (
    <Modal onClose={stayAndContinue} isOpen={blocker.state === "blocked"}>
      <MainContainer>
        <ContentContainer>
          <Title
            type="h3"
            color="secondary.main"
            text="Annuler l’ajout du document"
          />
          <Text color="secondary.main">
            Si vous quittez le parcours maintenant, le document ne sera pas
            ajouté à votre registre.
          </Text>
          <Text color="secondary.main">
            Souhaitez-vous annuler l’ajout du document?
          </Text>
        </ContentContainer>
        <Row>
          <Button variant="secondary" onClick={stayAndContinue}>
            {isMobile ? "Non" : "Non, continuer l’ajout"}
          </Button>
          <Button onClick={cancelAndGo}>
            {isMobile ? "Oui" : "Oui, annuler l'ajout"}
          </Button>
        </Row>
      </MainContainer>
    </Modal>
  );
};

export default DocumentSelectionCancellationModal;
