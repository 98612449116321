import axios from "axios";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { DecisionsService } from "services";
import { DecisionViewPageParams } from "services/router";
import useDecisionsContext from "./useDecisionsContext";

const useDecision = (id?: Decision["id"]) => {
  const urlParams = useParams<DecisionViewPageParams>();
  const searchId = id || Number(urlParams.decisionId || 0);

  const { decisions, updateDecision } = useDecisionsContext();

  const decision = useMemo(
    () => decisions.find((d) => d.id === searchId),
    [decisions, searchId]
  );

  if (!decision) {
    return null;
  }

  /** Only display the documents that are contain a file uploaded */
  const visibleDocuments = decision.documents.filter(
    (document) => !!document.file_url
  );

  const patch = (updatedProperties: Partial<Decision>) => {
    updateDecision({
      ...decision,
      ...updatedProperties,
    });
  };

  const finalize = () => {
    // Send request to BE
    DecisionsService.finalizeDecision(decision.id);

    // Update local
    patch({
      finalized: true,
    });
  };

  const addDocument = async (acceptedFile: File) => {
    // Create document
    const newDocument = await DecisionsService.createDocument(decision.id, {
      name: acceptedFile.name.slice(0, acceptedFile.name.lastIndexOf(".")),
      decision: decision.id,
    });

    // Upload the file to S3
    const uploadResult = await axios({
      url: newDocument.upload_url,
      method: "PUT",
      data: acceptedFile,
      headers: {
        "Content-Type": acceptedFile.type,
      },
    });

    const uploadSucceeded = uploadResult.status === 200;

    // Inform BE that the file upload is succeeded/failed based on the upload result status
    const finalDocument = await DecisionsService.confirmDocumentFileUploaded(
      decision.id,
      newDocument.id,
      {
        success: uploadSucceeded,
      }
    );

    // Error handling
    if (!uploadSucceeded || !finalDocument)
      throw new Error("Échec de l'envoi du document !");

    // Store latest document in the context
    patch({
      finalized: false,
      documents: [finalDocument, ...decision.documents],
    });

    return finalDocument;
  };

  const deleteDocument = (documentId: DecisionDocument["id"]) => {
    // update local context
    patch({
      finalized: false,
      documents: decision.documents.filter((d) => d.id !== documentId),
    });

    // API request
    DecisionsService.deleteDocument(decision.id, documentId);
  };

  return {
    ...decision,
    visibleDocuments,
    patch,
    finalize,
    addDocument,
    deleteDocument,
  };
};

export default useDecision;
