import { Button, Row, Text } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

import Modal from "components/modal/Modal";
import useDecision from "../hooks/useDecision";
import useDecisionsContext from "../hooks/useDecisionsContext";
import useERecordRoutes from "../hooks/useERecordRoutes";

const FooterContentInner = styled(Row)`
  ${({ theme }) => css`
    && {
      flex: 1;
    }
    @container (max-width: ${theme.breakpoints.l}px) {
      && {
        flex-direction: column-reverse;
        row-gap: 8px;
      }
    }

    @container (min-width: ${theme.breakpoints.l}px) {
    }
  `}
`;

type CertificateAndExportRegistriesModalProps = {
  open: boolean;
};

const CertificateAndExportRegistriesModal: React.FC<
  CertificateAndExportRegistriesModalProps
> = ({ open }) => {
  const decision = useDecision();
  const { getMyRegistryFileURL, isGettingRecordURL, closeModal } =
    useDecisionsContext();
  const { goToDecisionsPage, goToMyRegistryPage } = useERecordRoutes();

  const finalizeAndGoToDecisionsPage = () => {
    decision?.finalize();

    closeModal();
    goToDecisionsPage();
  };

  const finalizeAndGoToRegistryPage = async () => {
    decision?.finalize();

    const fileURL = await getMyRegistryFileURL();
    closeModal();
    if (fileURL) {
      goToMyRegistryPage(fileURL);
    }
  };

  const FooterContent = (
    <FooterContentInner>
      <Button onClick={finalizeAndGoToDecisionsPage} variant="secondary">
        Finaliser sans exporter
      </Button>
      <Button
        onClick={finalizeAndGoToRegistryPage}
        isLoading={isGettingRecordURL}
        disabled={isGettingRecordURL}
      >
        Finaliser et exporter maintenant
      </Button>
    </FooterContentInner>
  );

  return (
    <Modal
      onClose={closeModal}
      isOpen={open}
      title="Avez-vous ajouté tous les documents ?"
      FooterContent={FooterContent}
    >
      <div style={{ textAlign: "center" }}>
        <Text color="secondary.main">
          Vous pourrez toujours ajouter ou supprimer des documents plus tard.
        </Text>
      </div>
    </Modal>
  );
};

export default CertificateAndExportRegistriesModal;
