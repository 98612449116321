import OnboardingApiEndpoint from '../endpoints/onboarding';
import { commonApiErrorHandler } from './errors';

const get = async (projectId: number) => {
  try {
    const questionnaire = await OnboardingApiEndpoint.get(projectId);
    return questionnaire.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const patch = (projectId: number) => async (data: Partial<OnboardingQuestionnaire>) => {
  try {
    const questionnaire = await OnboardingApiEndpoint.patch(projectId, data);
    return questionnaire.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const markAsDone = async (projectId: number) => {
  try {
    const questionnaire = await OnboardingApiEndpoint.patch(projectId, { status: 'done' });
    return questionnaire.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const updateAnswers = (projectId: number) => async (answers: QuestionnaireAnswers) => {
  try {
    const questionnaire = await OnboardingApiEndpoint.patch(projectId, { answers });
    return questionnaire.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const OnboardingApiHandler = {
  get,
  patch,
  markAsDone,
  updateAnswers
};

export default OnboardingApiHandler;
