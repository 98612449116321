import { captureException } from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';
import _get from 'lodash/get';

const handleUnexpectedError = (error: any) => {
  captureException(error, {
    contexts: {
      errorConfig: error.config,
      errorResponse: error.response
    }
  });
  return Promise.reject(error);
};

const getNonFieldErrors = (responseData: AxiosResponse['data']): string[] => {
  return _get(responseData, 'non_field_errors', []);
};

const getCommonError = (responseData: AxiosResponse['data']): string | undefined => {
  return _get(responseData, 'error', undefined);
};

const handleAxiosError = (error: AxiosError) => {
  if (error.response?.data) {
    // Try to get `non_field_errors` if any
    const nonFieldErrorMessages = getNonFieldErrors(error.response.data);
    if (nonFieldErrorMessages.length) {
      return Promise.reject(new Error(nonFieldErrorMessages.join('; ')));
    }

    // Try to get `error` if any
    const commonError = getCommonError(error.response.data);
    if (commonError) {
      return Promise.reject(new Error(commonError));
    }

    if (Array.isArray(error.response.data) && !!error.response.data[0]) {
      return Promise.reject(new Error(error.response.data[0]));
    }
  }

  return handleUnexpectedError(error);
};

export const commonApiErrorHandler = (error: any) => {
  if (error.isAxiosError) {
    return handleAxiosError(error);
  }

  return handleUnexpectedError(error);
};
