import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

import Modal from "components/modal/Modal";
import useERecordRoutes from "../../hooks/useERecordRoutes";
import useDecisionViewContext from "../hooks/useDecisionViewContext";

const MainContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `};
`;

const ContentContainer = styled(Column)`
  text-align: center;
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `};
`;

type Props = {
  open: boolean;
};

const DocumentValidationUnqualifiedModal = ({ open }: Props) => {
  const { goToDecisionsPage } = useERecordRoutes();
  const { closeModal } = useDecisionViewContext();

  const confirmAndGo = () => {
    closeModal();
    goToDecisionsPage();
  };

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      FooterContent={
        <>
          <div></div>
          <Button onClick={confirmAndGo}>J’ai compris</Button>
        </>
      }
    >
      <MainContainer>
        <ContentContainer>
          <Title
            type="h3"
            color="secondary.main"
            text="Votre décision a bien été créée."
          />
          <Text color="secondary.main">
            Elle contient le document qui est conforme. Un document ne peut pas
            être ajouté sans la signature électronique.
          </Text>
        </ContentContainer>
      </MainContainer>
    </Modal>
  );
};

export default DocumentValidationUnqualifiedModal;
