import { Button, Dropdown, TextArea } from "@yolaw/ui-kit-components";
import {
  Fieldset,
  Form,
  FormFooter,
  FormSection,
  FormSectionBody,
} from "components/FormStyles";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { AppContext, FormalitiesContext } from "contexts";
import { useSegment } from "hooks";
import { PageContentHeader } from "pages/components";
import { useContext, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AssistanceService } from "services";
import { BuiltRoutePath } from "services/router";
import {
  AssistanceFormalistContactFormData,
  AssistanceQuestionType,
} from "types/assistance";
import { FORM_ERROR_MESSAGES } from "utils/form";

const trackingEventProperties = {
  "contact form": "formality assistance",
};

const SET_VALUE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

enum InputName {
  formality = "formality",
  questionText = "questionText",
}

type FormInputs = TUserInformationInputs & {
  [InputName.formality]: string;
  [InputName.questionText]: string;
};

const ContactFormalistPage = () => {
  const segment = useSegment();
  const navigate = useNavigate();
  const { state: appState } = useContext(AppContext.Context);
  const { state: formalitiesState } = useContext(FormalitiesContext.Context);

  const { user } = appState;

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      phoneNumber: user?.phone || "",
      email: user?.email || "",
      [InputName.formality]: "",
      [InputName.questionText]: "",
    },
  });

  const { control, handleSubmit, formState, setValue, setError, clearErrors } =
    formMethods;
  const { errors, isSubmitting, isValid } = formState;

  const { formalities } = formalitiesState;

  const formalityOptions = formalities.map((formality) => ({
    label: `${formality.title} N°${formality.source_id}`,
    value: `${formality.source_id}-${formality.source_type}`,
  }));

  useEffect(() => {
    segment.track(
      "assistance contact form: displayed",
      trackingEventProperties
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: FormInputs) => {
    segment.track(
      "assistance contact form: completed",
      trackingEventProperties
    );

    try {
      const {
        firstName,
        lastName,
        phoneNumber,
        email,
        formality,
        questionText,
      } = data;

      const [sourceId, sourceType] = formality.split("-");

      const submitData: AssistanceFormalistContactFormData = {
        type: AssistanceQuestionType.Formalist,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        question_text: questionText,
        source_id: Number(sourceId),
        source_type: sourceType as Formality["source_type"],
      };

      await AssistanceService.createTicket(submitData);

      toast.success(
        "Votre message a été envoyé à votre formaliste avec succès !",
        {
          icon: false,
          hideProgressBar: true,
        }
      );

      navigate(BuiltRoutePath.LegalAssistancePage, { replace: true });
    } catch (error: any) {
      setError("root", { message: error.message || "SUBMIT_FAILED" });
      toast.error(
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard !",
        {
          icon: false,
          onClose: () => clearErrors("root"),
        }
      );
    }
  };

  const onFormalityDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(InputName.formality, event.target.value, SET_VALUE_OPTIONS);
  };

  const onQuestionTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(InputName.questionText, event.target.value, SET_VALUE_OPTIONS);
  };

  return (
    <>
      <PageContentHeader
        title="Contacter mon formaliste"
        subTitle="Vous pouvez contacter votre juriste pour vos démarches en cours"
      />

      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <FormSectionBody>
              <Fieldset>
                <Controller
                  control={control}
                  name={InputName.formality}
                  rules={{
                    required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                  }}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      placeholder="Ma démarche"
                      label="Sélectionnez votre démarche"
                      options={formalityOptions}
                      errorMessage={errors.formality?.message}
                      onChange={onFormalityDropdownChange}
                    />
                  )}
                />

                <UserInformationFieldset
                  validationRules={{
                    firstName: {
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    },
                    lastName: {
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    },
                    phoneNumber: {
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    },
                    email: {
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    },
                  }}
                />

                <Controller
                  control={control}
                  name={InputName.questionText}
                  rules={{
                    required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                  }}
                  render={({ field }) => (
                    <TextArea
                      label="Vos questions"
                      placeholder="Pour un entretien efficace et une réponse à toutes vos questions, merci de nous donner un maximum de détails ici"
                      onChange={onQuestionTextAreaChange}
                      errorMessage={errors.questionText?.message}
                    />
                  )}
                />
              </Fieldset>
              <FormFooter>
                <Button
                  type="submit"
                  variant="primary"
                  text="Envoyer"
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                />
              </FormFooter>
            </FormSectionBody>
          </FormSection>
        </Form>
      </FormProvider>
    </>
  );
};

export default ContactFormalistPage;
