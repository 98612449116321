import { Accent, Title } from "@yolaw/ui-kit-components";
import { FC } from "react";

import Lawyer from "../../assets/banner/lawyer.png";
import Banner from "../../components/Banner";

const BannerTitle = () => (
  <Title type="h3">
    Rendez-vous avec votre <Accent color="primary.main">juriste</Accent>
  </Title>
);

const LawyerAppointmentBanner: FC = () => {
  return (
    <Banner
      title={<BannerTitle />}
      description="C'est un rendez-vous téléphonique de 20 minutes, vous allez être rappelé par un expert à l'heure du rendez-vous."
      image={<img src={Lawyer} alt="" />}
    />
  );
};

export default LawyerAppointmentBanner;
