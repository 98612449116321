export const isHubspotPageTask = (task: TaskItem): task is HubspotPageTask =>
  task.type.kind === 'hubspot_page';

export const isDocumentTask = (task: TaskItem): task is DocumentTask.TaskObject =>
  task.type.kind === 'document';

export const isQuestionnaireTask = (task: TaskItem): task is QuestionnaireTask =>
  task.type.kind === 'questionnaire';

export const isWebinarTask = (task: TaskItem): task is WebinarTask => task.type.kind === 'webinar';
