import unemployment from './unemployment';
import convention from './convention';
import changeVatSchemeEmail from './vat-mail';

const DOCUMENT_GENERATION_TASK_CONFIGS: DocumentTask.TasksLocalConfigMap = {
  unemployment,
  convention,
  'vat-mail': changeVatSchemeEmail
};

export const getTaskConfigsBySlug = (taskSlug: TaskItem['type']['slug']) => {
  return DOCUMENT_GENERATION_TASK_CONFIGS[taskSlug] || undefined;
};

export default DOCUMENT_GENERATION_TASK_CONFIGS;
