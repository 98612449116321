import { LegalEntity } from "types/legal-entities";
import coreApiInstance from "../coreApi";

export const getDecisions = () =>
  coreApiInstance.get<Decision[]>("/erecords/decisions/");

export const createDecision = (creationData: DecisionCreationData) =>
  coreApiInstance.post<Decision>(`/erecords/decisions/`, creationData);

export const deleteDecision = (decisionId: Decision["id"]) =>
  coreApiInstance.delete<Decision>(`/erecords/decisions/${decisionId}/`);

export const patchDecision = (
  decisionId: Decision["id"],
  updatedProperties: Partial<Decision>
) =>
  coreApiInstance.patch<Decision>(
    `/erecords/decisions/${decisionId}/`,
    updatedProperties
  );

export const finalizeDecision = (
  decisionId: Decision["id"]
) =>
  coreApiInstance.post(
    `/erecords/decisions/${decisionId}/finalize/`
  );

export const createDocument = (
  decisionId: Decision["id"],
  creationData: DecisionDocumentCreationData
) =>
  coreApiInstance.post<DecisionDocumentCreationResponse>(
    `/erecords/decisions/${decisionId}/documents/`,
    creationData
  );

export const confirmDocumentFileUploaded = (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"],
  data: DecisionDocumentUploadConfirmData
) =>
  coreApiInstance.post<DecisionDocument>(
    `/erecords/decisions/${decisionId}/documents/${documentId}/upload-status/`,
    data
  );

export const getDocument = (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"]
) =>
  coreApiInstance.get<DecisionDocument>(
    `/erecords/decisions/${decisionId}/documents/${documentId}/`
  );

export const deleteDocument = (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"]
) =>
  coreApiInstance.delete<Decision>(
    `/erecords/decisions/${decisionId}/documents/${documentId}/`
  );

export const patchDocument = (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"],
  updatedProperties: Partial<DecisionDocument>
) =>
  coreApiInstance.patch<Decision>(
    `/erecords/decisions/${decisionId}/documents/${documentId}/`,
    updatedProperties
  );

export const getRecordURL = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<{ decision_erecords_url: string }>(
    "/erecords/get-record-url/",
    {
      params: {
        legal_entity_id: legalEntityId,
      },
    }
  );
