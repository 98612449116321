import {
  Accent,
  Column,
  Row,
  ServiceItemLayout,
  ServiceItems,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { css, styled } from "styled-components";

import ButtonContactPhone from "components/ButtonContactPhone";
import { useIsMobile, useLegalEntity } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { CSSubscriptionPaymentModal } from "./CSSubscriptionPaymentModal";
import CSPreviewImg from "./assets/comptastart-preview.png";

const PageContainerInner = styled(Column)`
  text-align: center;

  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.l}px;
    }

    .service-items-container {
      .service-items-wrapper {
        padding: 0px;
        gap: ${theme.spacing.xs}px;

        .service-item {
          padding: ${theme.spacing.xs}px;

          @media (min-width: ${theme.breakpoints.l}px) {
            padding-block: ${theme.spacing.xxxxs}px;
          }

          .icon-wrapper {
            min-width: 60px;
            min-height: 60px;

            svg {
              width: 60px;
              height: 60px;
            }
          }

          .text-container {
            > p {
              color: ${theme.colors.secondary.main};
            }
          }
        }

        .layout-horizontal {
          align-items: center;
        }
      }
    }
  `};
`;

const PreviewImg = styled.img`
  width: 37%;
`;

const PreviewAndFeatures = styled(Row)`
  align-items: center;
  align-self: center;
  && {
    gap: 48px;
  }
`;

const ComptastartSellingPage = () => {
  const isMobile = useIsMobile();
  const currentLegalEntity = useLegalEntity();

  const activeComptastartSub = currentLegalEntity.findCSActiveSubscription();

  useEffect(() => {
    if (!!activeComptastartSub && !activeComptastartSub.redirection_url) {
      toast.error(
        "Quelque chose s'est mal passé ! Veuillez réessayer plus tard ou contacter notre équipe d'assistance !",
        { autoClose: false }
      );
    }
  }, [activeComptastartSub]);

  return (
    <PageContainer>
      <PageContainerInner>
        <Title type="H1">
          Toute votre comptabilité avec{" "}
          <Accent color="primary.main">Comptastart</Accent>
        </Title>
        <PreviewAndFeatures>
          {isMobile ? null : (
            <PreviewImg src={CSPreviewImg} alt="comptastart-preview" />
          )}
          <ServiceItems
            containerLayout={ServiceItemLayout.Vertical}
            itemLayout={
              isMobile
                ? ServiceItemLayout.Vertical
                : ServiceItemLayout.Horizontal
            }
            showTitleOnMobile
            items={[
              {
                icon: "UniversalCharts",
                title: "Toute votre comptabilité",
                description:
                  "Facturation, TVA, notes de frais, indemnités kilométriques, bilan et liasse fiscale",
              },
              {
                icon: "UniversalFast2",
                title: "Un logiciel complet et efficace",
                description:
                  "Catégorisation automatique des dépenses, suivi de trésorerie en temps réel, télédéclaration de la TVA",
              },
              {
                icon: "UniversalCustomerSupport2",
                title: "Accompagnement au quotidien",
                description:
                  "Une équipe dédiée pour répondre à vos questions sur l'utilisation de notre logiciel",
              },
            ]}
          />
        </PreviewAndFeatures>
        <Column>
          <Text color="information.main" fontWeightVariant="bold">
            Testez gratuitement Comptastart pendant 30 jours
          </Text>
          <ButtonContactPhone />
        </Column>
      </PageContainerInner>

      <CSSubscriptionPaymentModal />
    </PageContainer>
  );
};

export default ComptastartSellingPage;
