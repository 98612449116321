import ApiService from "services/api";
import {
  AssistanceContactFormData,
  AssistanceQuestionType,
} from "types/assistance";

export const getQuestionThemes = async (questionType: AssistanceQuestionType) => {
  try {
    const response = await ApiService.assistance.getQuestionThemes();
    if (response) {
      return response.data.filter((theme) => theme.type === questionType);
    }
    return [];
  } catch (error) {
    throw error;
  }
};

export const createTicket = async (data: AssistanceContactFormData) => {
  try {
    const response = await ApiService.assistance.createTicket(data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
