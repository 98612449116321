import { BurgerPointFlat } from "@yolaw/ui-kit-icons";
import React, { useState } from "react";
import styled, { css, useTheme } from "styled-components";

import Popover from "components/Popover/Popover";
import PopoverContent from "components/Popover/PopoverContent";
import PopoverTrigger from "components/Popover/PopoverTrigger";
import { ModalName } from "contexts/app";
import { useApp, useLegalEntity } from "hooks";
import DecisionsContext from "pages/zen/pages/mandatory-records/context";
import useDecisionsContext from "pages/zen/pages/mandatory-records/hooks/useDecisionsContext";

const Container = styled.div`
  background-color: white;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.lightest};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
  `}
`;

const OptionsButton = styled.button`
  flex-shrink: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  padding: ${(props) => props.theme.spacing.xxxs}px;

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.neutral.lightest};
  }
`;

const MenuItemButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.dark};
    width: 100%;
    padding: ${theme.spacing.xxxs}px;
    background-color: transparent;
    border: none;
    border-radius: ${theme.borderRadius.xs}px;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: ${theme.colors.neutral.lightest};
    }
  `}
`;

type OptionsMenuContentProps = {
  closeMenu: VoidFunction;
  decision: Decision;
};

const OptionsMenuContent = ({
  closeMenu,
  decision,
}: OptionsMenuContentProps) => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const { openModal } = useDecisionsContext();

  const handleRenaming = () => {
    openModal({
      id: DecisionsContext.DecisionModal.DecisionEdit,
      context: {
        decision,
      },
    });
  };

  const handleDeletion = () => {
    openModal({
      id: DecisionsContext.DecisionModal.DecisionDelete,
      context: {
        decision,
      },
    });
  };

  const checkAndHandleAction = (onCheckPassed: VoidFunction) => () => {
    closeMenu();

    if (!currentLegalEntity.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "e-records decisions",
          from: "decision option menu item",
        },
      });
      return;
    }

    // Perform the action
    onCheckPassed();
  };

  return (
    <Container>
      <MenuList>
        <MenuItem>
          <MenuItemButton onClick={checkAndHandleAction(handleRenaming)}>
            Modifier la décision
          </MenuItemButton>
        </MenuItem>
        <MenuItem>
          <MenuItemButton onClick={checkAndHandleAction(handleDeletion)}>
            Supprimer la décision
          </MenuItemButton>
        </MenuItem>
      </MenuList>
    </Container>
  );
};

type DecisionOptionsMenuProps = {
  decision: Decision;
};

const DecisionOptionsMenu = ({ decision }: DecisionOptionsMenuProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);
  const openMenu = () => setOpen(true);

  return (
    <>
      <Popover placement="left" open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <OptionsButton onClick={openMenu}>
            <BurgerPointFlat size="16" color={theme.colors.neutral.dark} />
          </OptionsButton>
        </PopoverTrigger>
        <PopoverContent>
          <OptionsMenuContent closeMenu={closeMenu} decision={decision} />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DecisionOptionsMenu;
