import { ArrayUtils } from "utils/array";
import useDecision from "../../hooks/useDecision";

const useDocument = (id: DecisionDocument["id"]) => {
  const decision = useDecision();

  if (!decision) {
    console.error("[useDocument] should be used inside a Decision context");
    return null;
  }

  const document = decision.documents.find((doc) => doc.id === id);

  if (!document) {
    return null;
  }

  const patch = (updatedProperties: Partial<DecisionDocument>) => {
    decision.patch({
      documents: ArrayUtils.updateElementById(
        decision.documents,
        document.id,
        updatedProperties
      ),
    });
  };

  return {
    ...document,
    patch,
  };
};

export default useDocument;
