import ProjectApiEndpoint from '../endpoints/projects';
import { commonApiErrorHandler } from './errors';

const getInfo = async (projectId: Project['id']) => {
  try {
    const response = await ProjectApiEndpoint.getInfo(projectId);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const logZenRatings = async (projectId: Project['id'], data: ZenRatingData) => {
  try {
    const response = await ProjectApiEndpoint.logZenRatings(projectId, data);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const ProjectApiHandler = {
  getInfo,
  logZenRatings
};

export default ProjectApiHandler;
