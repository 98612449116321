import { Column, Spinner, Title } from "@yolaw/ui-kit-components";
import _get from "lodash/get";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useFluidLayout, useSegment } from "hooks";
import QuestionnaireViewer from "pages/zen/components/TaskViewer/Questionnaire";
import useZenProject from "pages/zen/hooks/useZenProject";

const LoaderText = styled(Title).attrs({ type: "H3" })``;

const LoaderContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: ${({ theme }) => theme.spacing.m}px;

  > ${LoaderText} {
    max-width: 640px;
    text-align: center;
  }
`;

const WaitingForTaskGeneration = () => {
  return (
    <LoaderContainer>
      <Spinner color="accentuation.main" />
      <LoaderText>
        Super, nous mettons à jour le contenu de Zen pour qu&apos;il soit adapté
        à votre société !
      </LoaderText>
    </LoaderContainer>
  );
};

const Onboarding = () => {
  useFluidLayout();
  const project = useZenProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const segment = useSegment();
  const location = useLocation();

  useEffect(() => {
    segment.track("zen onboarding: started", {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      has_siren: project.hasCompanySiren,
      from: searchParams.get("utm_source") || "direct_access",
    });

    // Clear search params after use to clean the visible URL
    setSearchParams([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldStartFromBeginning = _get(location.state, "startFromBeginning");

  if (!project.onboardingQuestionnaire) return null;

  return project.isGeneratingTasks ? (
    <WaitingForTaskGeneration />
  ) : (
    <QuestionnaireViewer
      questions={project.onboardingQuestionnaire.questions}
      resumePageSlug={
        shouldStartFromBeginning
          ? null
          : project.onboardingQuestionnaire.current_page
      }
      onComplete={project.markOnboardingQuestionnaireAsDone}
      navigatorVisible={true}
    />
  );
};

export default Onboarding;
