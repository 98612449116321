import { Button, Checkbox, Text } from "@yolaw/ui-kit-components";
import {
  Form,
  FormFooter,
  FormSection,
  FormSectionBody,
  OptinField,
} from "components/FormStyles";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { AppContext } from "contexts";
import { useSegment } from "hooks";
import { useContext } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { AuthService } from "services";
import { FORM_ERROR_MESSAGES } from "utils/form";

type FormInputs = TUserInformationInputs & {
  emailOptIn: boolean;
};

const UserProfileUpdateForm = () => {
  const segment = useSegment();
  const { state: appState, dispatch: appDispatch } = useContext(
    AppContext.Context
  );
  const { user } = appState;

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      phoneNumber: user?.phone || "",
      email: user?.email || "",
      emailOptIn: !!user?.ok_for_emails,
    },
  });

  const { control, handleSubmit, formState } = formMethods;

  const { isDirty, isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    segment.track("user information: updated");

    const newUserData = await AuthService.patchUserProfile({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      ok_for_emails: data.emailOptIn,
    });

    appDispatch({
      type: AppContext.ActionType.SetUserInfo,
      payload: newUserData,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <FormSectionBody>
            <UserInformationFieldset
              validationRules={{
                firstName: {
                  required:
                    !!user?.first_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                lastName: {
                  required:
                    !!user?.last_name && FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                phoneNumber: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
                email: {
                  required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                },
              }}
            />
            <OptinField>
              <Controller
                control={control}
                name="emailOptIn"
                render={({ field: { ref, value, ...restOfField } }) => (
                  <Checkbox
                    {...restOfField}
                    id="emailOptIn"
                    checked={value}
                    value={JSON.stringify(value)}
                  />
                )}
              />
              <label htmlFor="emailOptIn">
                <Text type="small">
                  J'accepte que{" "}
                  <strong>
                    Legalstart.fr m'envoie des informations sur des promotions
                    ou des services fournis par Legalstart.fr
                  </strong>{" "}
                  (promis, que des informations utiles, pas de spam !)
                </Text>
              </label>
            </OptinField>
            <FormFooter>
              <Button
                type="submit"
                variant="primary"
                text="Mettre à jour"
                disabled={!isDirty || isSubmitting}
                isLoading={isSubmitting}
              />
            </FormFooter>
          </FormSectionBody>
        </FormSection>
      </Form>
    </FormProvider>
  );
};

export default UserProfileUpdateForm;
