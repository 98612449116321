import { Row, Tag, Title } from "@yolaw/ui-kit-components";
import { RegisterV3 } from "@yolaw/ui-kit-icons";
import styled, { css } from "styled-components";

import useDecisionViewContext from "../hooks/useDecisionViewContext";
import DocumentOptionsMenu from "./DocumentOptionsMenu";

const DocumentTitle = styled(Title).attrs({
  type: "H4",
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemContainer = styled(Row)`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    padding: ${theme.spacing.xs}px;

    .main-content {
      display: inherit;
      gap: inherit;
      overflow: hidden;

      .icon-n-title {
        display: inherit;
        gap: inherit;
        overflow: hidden;

        > svg {
          flex-shrink: 0;
        }
      }

      .tag {
        flex: none;
      }
    }

    @container (max-width: ${theme.breakpoints.l}px) {
      .main-content {
        flex-wrap: wrap;
      }
    }

    @container (min-width: ${theme.breakpoints.l}px) {
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.neutral.lightest};
      }

      &.selected {
        background-color: ${theme.colors.tertiary.main};
      }
    }
  `}
`;

type DocumentListItemProps = {
  document: DecisionDocument;
};

const DocumentListItem = ({ document }: DocumentListItemProps) => {
  const { currentDocumentId, setCurrentDocumentId } = useDecisionViewContext();

  const toggleDocumentSelection = () => {
    setCurrentDocumentId(
      currentDocumentId === document.id ? null : document.id
    );
  };

  return (
    <ItemContainer
      className={currentDocumentId === document.id ? "selected" : ""}
      onClick={toggleDocumentSelection}
    >
      <div className="main-content">
        <div className="icon-n-title">
          <RegisterV3 size="32" />
          <DocumentTitle>{document.name}</DocumentTitle>
        </div>
        <Tag color="green" icon="CheckmarkFlat" size="large" className="tag">
          Téléchargé
        </Tag>
      </div>
      <DocumentOptionsMenu document={document} />
    </ItemContainer>
  );
};

export default DocumentListItem;
