import LSUser from "types/user";

/** Check if the given password DOESN'T contain some user info */
export const notContainUserInfo = (password: string, user: LSUser.Info) => {
  // Convert all inputs to lowercase for case-insensitive comparison
  const lowercasePassword = password.toLowerCase();
  const lowercaseFirstName = user.first_name.toLowerCase();
  const lowercaseLastName = user.last_name.toLowerCase();
  const lowercaseEmail = user.email.toLowerCase();

  // Check if password contains first name, last name, or email
  if (
    (!!lowercaseFirstName && lowercasePassword.includes(lowercaseFirstName)) ||
    (!!lowercaseLastName && lowercasePassword.includes(lowercaseLastName)) ||
    lowercasePassword.includes(lowercaseEmail.split("@")[0])
  ) {
    return false;
  }

  return true;
};

/** Check if the given string contains at least one special character or a number  */
export const containSpecialCharacterOrNumber = (str: string) => {
  /** Consider any non-alphanumeric characters as special characters.
   * Ex: The emoji is also a case.
   */
  const specialCharOrNumberRegex = /[^a-zA-Z]/;
  return specialCharOrNumberRegex.test(str);
};
