import { FormalitiesContext } from "contexts";
import { FormalityStatus } from "contexts/formalities";
import { useContext } from "react";
import { FormalityService } from "services";

export const useFormality = (formality: Formality) => {
  const { dispatch: formalitiesDispatch } = useContext(
    FormalitiesContext.Context
  );

  const archiveFormalityFunnel = async () => {
    // Local update first
    formalitiesDispatch({
      type: FormalitiesContext.ActionType.UpdateFormality,
      payload: {
        ...formality,
        // Mock the formality with cancellation data
        status: FormalityStatus.Cancelled,
        canceled_at: new Date(),
      },
    });

    // Call API to update the formality status in DB
    const updatedFormality = await FormalityService.archiveFormalityFunnel(
      formality
    );
    // Update the formality if it should be
    if (updatedFormality) {
      formalitiesDispatch({
        type: FormalitiesContext.ActionType.UpdateFormality,
        payload: updatedFormality,
      });
    }
  };

  const unarchiveFormalityFunnel = async () => {
    // Local update first
    formalitiesDispatch({
      type: FormalitiesContext.ActionType.UpdateFormality,
      payload: {
        ...formality,
        // Set the formality status to "draft" and clear cancellation date
        status: FormalityStatus.Draft,
        canceled_at: null,
        updated_at: new Date(),
      },
    });

    // Call API to update the formality status in DB
    const updatedFormality = await FormalityService.unarchiveFormalityFunnel(
      formality
    );
    // Update the formality if it should be
    if (updatedFormality) {
      formalitiesDispatch({
        type: FormalitiesContext.ActionType.UpdateFormality,
        payload: updatedFormality,
      });
    }
  };

  return {
    ...formality,
    archiveFormalityFunnel,
    unarchiveFormalityFunnel,
  };
};
