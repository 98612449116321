import { useCallback } from "react";
import { generatePath, NavigateOptions, useNavigate } from "react-router-dom";

import { PathParams } from "services/router";
import { useApp } from "./useApp";

const useAppRoutes = () => {
  const app = useApp();
  const navigate = useNavigate();

  const generateAppPath = useCallback(
    (originalPath: string) => {
      const pathParams = {
        [PathParams.LegalEntityId]: String(app.currentLegalEntity?.id),
      };
      return generatePath(originalPath, pathParams);
    },
    [app.currentLegalEntity?.id]
  );

  const navigateTo = useCallback(
    (path: string, options?: NavigateOptions) => {
      navigate(generateAppPath(path), options);
    },
    [generateAppPath, navigate]
  );

  return {
    generateAppPath,
    navigateTo,
  };
};

export default useAppRoutes;
