import { LegalEntity, LegalStructure } from "types/legal-entities";

enum Template {
  PVAssocieUnique = "PV-associe-unique",
  PVPlusieursAssocies = "PV-plusieurs-associes",
  PVRemunerationSAS = "PV-remuneration-SAS",
  PVRemunerationSASU = "PV-remuneration-SASU",
  PVRemunerationEURL = "PV-remuneration-EURL",
  PVRemunerationSARL = "PV-remuneration-SARL",
  PVCompteCourantAssocieSASxSARL = "PV-compte-courant-associe-SAS-SARL",
  PVCompteCourantAssocieSASUxEURL = "PV-compte-courant-associe-SASU-EURL",
}

enum TemplateType {
  PVAssocieUnique = "PV-associe-unique",
  PVPlusieursAssocies = "PV-plusieurs-associes",
  PVRemuneration = "PV-remuneration",
  PVCompteCourantAssocie = "PV-compte-courant-associe",
}

export type DocumentTemplate = {
  type: TemplateType;
  label: string;
  link: string;
};

const DOCUMENT_TEMPLATES: Record<Template, DocumentTemplate> = {
  [Template.PVAssocieUnique]: {
    type: TemplateType.PVAssocieUnique,
    label: "PV d'Assemblée Générale",
    link: "https://www.legalstart.fr/hubfs/Mod%C3%A8les%20de%20documents%20-%20AJ/PV%20AG%20Associ%C3%A9%20Unique.docx",
  },
  [Template.PVPlusieursAssocies]: {
    type: TemplateType.PVPlusieursAssocies,
    label: "PV d'Assemblée Générale",
    link: "https://www.legalstart.fr/hubfs/Mod%C3%A8les%20de%20documents%20-%20AJ/PV%20AG%20Plusieurs%20associ%C3%A9s.docx",
  },
  [Template.PVRemunerationSAS]: {
    type: TemplateType.PVRemuneration,
    label: "PV de rémunération et non rémunération",
    link: "https://www.legalstart.fr/hubfs/AJ%20-%20Biblioth%C3%A8que%20de%20documents/PV%20de%20r%C3%A9mun%C3%A9ration%20ou%20non-r%C3%A9mun%C3%A9ration%20SAS.docx",
  },
  [Template.PVRemunerationSASU]: {
    type: TemplateType.PVRemuneration,
    label: "PV de rémunération et non rémunération",
    link: "https://www.legalstart.fr/hubfs/AJ%20-%20Biblioth%C3%A8que%20de%20documents/PV%20de%20r%C3%A9mun%C3%A9ration%20et%20non-r%C3%A9mun%C3%A9ration%20SASU.docx",
  },
  [Template.PVRemunerationEURL]: {
    type: TemplateType.PVRemuneration,
    label: "PV de rémunération et non rémunération",
    link: "https://www.legalstart.fr/hubfs/AJ%20-%20Biblioth%C3%A8que%20de%20documents/PV%20de%20r%C3%A9mun%C3%A9ration%20et%20non-r%C3%A9mun%C3%A9ration%20EURL.docx",
  },
  [Template.PVRemunerationSARL]: {
    type: TemplateType.PVRemuneration,
    label: "PV de rémunération et non rémunération",
    link: "https://www.legalstart.fr/hubfs/AJ%20-%20Biblioth%C3%A8que%20de%20documents/PV%20de%20r%C3%A9mun%C3%A9ration%20et%20non-r%C3%A9mun%C3%A9ration%20SARL.docx",
  },
  [Template.PVCompteCourantAssocieSASxSARL]: {
    type: TemplateType.PVCompteCourantAssocie,
    label: "PV de compte courant d'associé",
    link: "https://www.legalstart.fr/hubfs/Mod%C3%A8les%20de%20documents%20-%20AJ/PV%20-%20Compte%20Courant%20Associ%C3%A9%20-%20SAS%20SARL.docx",
  },
  [Template.PVCompteCourantAssocieSASUxEURL]: {
    type: TemplateType.PVCompteCourantAssocie,
    label: "PV de compte courant d'associé",
    link: "https://www.legalstart.fr/hubfs/Mod%C3%A8les%20de%20documents%20-%20AJ/PV%20-%20Compte%20Courant%20Associ%C3%A9%20-%20SASU%20EURL.docx",
  },
};

const DOCUMENT_TEMPLATES_BY_LEGAL_STRUCTURE: Record<
  Partial<LegalEntity["legal_structure"]>,
  Template[]
> = {
  [LegalStructure.SAS]: [
    Template.PVPlusieursAssocies,
    Template.PVCompteCourantAssocieSASxSARL,
    Template.PVRemunerationSAS,
  ],
  [LegalStructure.SASU]: [
    Template.PVAssocieUnique,
    Template.PVCompteCourantAssocieSASUxEURL,
    Template.PVRemunerationSASU,
  ],
  [LegalStructure.SARL]: [
    Template.PVPlusieursAssocies,
    Template.PVCompteCourantAssocieSASxSARL,
    Template.PVRemunerationSARL,
  ],
  [LegalStructure.EURL]: [
    Template.PVAssocieUnique,
    Template.PVCompteCourantAssocieSASUxEURL,
    Template.PVRemunerationEURL,
  ],
  [LegalStructure.Association]: [],
  [LegalStructure.SCI]: [],
  [LegalStructure.IndividualEnterprise]: [],
  [LegalStructure.AutoEnterprise]: [],
};

export const getDocumentTemplatesByLegalStructure = (
  legalStructure: LegalStructure
): DocumentTemplate[] =>
  DOCUMENT_TEMPLATES_BY_LEGAL_STRUCTURE[legalStructure].map(
    (type) => DOCUMENT_TEMPLATES[type]
  );
