import downloadFile from "./downloadFile";

export const LOCAL_STORAGE_KEYS = {
  HAS_DOWNLOADED_KBIS: "lz_has_downloaded_kbis",
  HAS_SEEN_ZEN_INTRO: "lz_has_seen_zen_intro",
  LAST_PROJECT_ID: "lz_last_project_id",
};

const _isListContainsItem = (
  listName: string,
  item: string | number
): boolean => {
  const value = window.localStorage.getItem(listName);

  // The list has not been initiated at all
  if (!value) return false;

  const list = JSON.parse(value);
  if (Array.isArray(list) && list.includes(item)) {
    // Found ID in the list
    return true;
  }

  // Unexpected data type or project not found
  return false;
};

const _addItemToList = (item: string | number, listName: string) => {
  // Get current list and parse it before use
  const value = window.localStorage.getItem(listName) || "[]";
  const list = JSON.parse(value);

  // Add the item to the list if it was not there
  if (Array.isArray(list) && !list.includes(item)) {
    list.push(item);

    // Save to local storage
    window.localStorage.setItem(listName, JSON.stringify(list));
  }
};

export const hasSeenZenIntro = (projectId: Project["id"]) => {
  // Backwards compatibility
  if (
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_SEEN_ZEN_INTRO) ===
    "seen"
  ) {
    // remove old entry
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.HAS_SEEN_ZEN_INTRO);

    // Initialize new entry format
    _addItemToList(projectId, LOCAL_STORAGE_KEYS.HAS_SEEN_ZEN_INTRO);
    return true;
  } else {
    return _isListContainsItem(
      LOCAL_STORAGE_KEYS.HAS_SEEN_ZEN_INTRO,
      projectId
    );
  }
};

export const setHasSeenZenIntro = (projectId: Project["id"]) =>
  _addItemToList(projectId, LOCAL_STORAGE_KEYS.HAS_SEEN_ZEN_INTRO);

/** Save the project ID in the list of "Has downloaded Kbis" projects */
export const setHasDownloadedKbis = (projectId: Project["id"]) =>
  _addItemToList(projectId, LOCAL_STORAGE_KEYS.HAS_DOWNLOADED_KBIS);

/** Whether the given project has downloaded the Kbis at least once ot not */
export const hasDownloadedKbis = (projectId: Project["id"]) =>
  _isListContainsItem(LOCAL_STORAGE_KEYS.HAS_DOWNLOADED_KBIS, projectId);

export const downloadKbisFile = (project: Project) => {
  const { id, company_name, kbis_url } = project;
  if (!kbis_url) return;

  // Save downloaded state in local storage
  setHasDownloadedKbis(id);

  const fileName = `kbis-${(company_name || "__NONAME__")
    .replace(" ", "-")
    .toLowerCase()}.pdf`;
  // Download the file
  return downloadFile(kbis_url, fileName);
};
