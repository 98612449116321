import coreApiInstance from "../coreApi";

export const initialEmail = (data: InitialEmailData) =>
  coreApiInstance.post("/users/me/initial-email/", data);

export const updateInitialEmail = (data: UpdateInitialEmailData) =>
  coreApiInstance.post<UpdateInitialEmailResponseError>(
    "/users/me/update-initial-email/",
    data,
    {
      validateStatus: (status) => status < 500,
    }
  );
