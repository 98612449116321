import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { Column, Row, TextField, colors } from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import React, { useEffect, useReducer } from "react";
import { PaymentMethod, PaymentOption } from "services/payment";
import styled, { css } from "styled-components";
import { usePaymentModalContext } from "../../../hooks";

const CardForm = styled(Column)`
  ${({ theme }) => css`
    box-sizing: border-box;
    background-color: ${theme.colors.common.white};
    gap: ${theme.spacing.xxxs}px;

    height: 0;
    display: none;

    .StripeElement {
      border-radius: ${theme.borderRadius.s}px;
      border: 1px solid ${theme.colors.neutral.lighter};
      padding: ${theme.spacing.xs}px;
      transition: border-color 400ms;
      box-sizing: border-box;

      &:hover {
        border: 1px solid ${theme.colors.secondary.main};
      }

      @media all and (max-width: ${theme.breakpoints.m}px) {
        width: 100%;
      }
    }

    .StripeElement--focus {
      border: 2px solid ${theme.colors.secondary.main};
      &:hover {
        border: 2px solid ${theme.colors.secondary.main};
      }
    }

    .StripeElement--invalid {
      border: 2px solid ${theme.colors.error.dark};
      &:hover {
        border: 2px solid ${theme.colors.error.dark};
      }
    }

    @media screen and (max-width: ${theme.breakpoints.m}px) {
      margin-left: 0;
      margin-right: 0;
    }
  `}
`;

const InputGroup = styled(Column)`
  text-align: left;
  position: relative;

  p {
    display: none;
  }

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxxs}px;

    @media all and (max-width: ${theme.breakpoints.m}px) {
      width: 100%;
    }
    @media all and (min-width: ${theme.breakpoints.m}px) {
      flex-grow: 1;
    }
  `}
`;

const PaymentSecondaryField = styled(InputGroup)<{ $flex?: number }>`
  flex: ${({ $flex }) => $flex || 1};
`;

const InputErrorString = styled.span`
  color: ${({ theme }) => theme.colors.error.dark};
  flex: 1 1 auto;
  font-size: 0.8rem;
`;

const StyledRow = styled(Row)`
  column-gap: ${({ theme }) => theme.spacing.xxxs}px;
`;

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "proxima-nova, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      "::placeholder": { color: colors.neutral.light },
    },
    invalid: {
      iconColor: colors.error.dark,
    },
  },
};

type CardInputState = {
  isValid: boolean;
  isDirty: boolean;
  errorMessage: string | null;
};

enum CardInputField {
  CardNumber = "cardNumber",
  CardExpiry = "cardExpiry",
  CardCvc = "cardCvc",
  CardHolder = "cardHolder",
}

type CardFormState = {
  [field in CardInputField]: CardInputState;
};

const initialCardInputState: CardInputState = {
  isValid: false,
  isDirty: false,
  errorMessage: null,
};

const cardFormInitialState: CardFormState = {
  [CardInputField.CardNumber]: initialCardInputState,
  [CardInputField.CardExpiry]: initialCardInputState,
  [CardInputField.CardCvc]: initialCardInputState,
  [CardInputField.CardHolder]: initialCardInputState,
};

type CardHolderNameChangeEvent = {
  elementType: CardInputField.CardHolder;
  error: StripeCardNumberElementChangeEvent["error"];
  complete: StripeCardNumberElementChangeEvent["complete"];
};

type StripeElementChangeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | CardHolderNameChangeEvent;

type CardInputAction =
  | {
      type: "SET_INPUT_VALIDITY";
      payload: {
        elementType: StripeElementChangeEvent["elementType"];
        isValid: boolean;
      };
    }
  | {
      type: "ADD_ERROR";
      payload: {
        elementType: StripeElementChangeEvent["elementType"];
        errorMessage: string;
      };
    }
  | {
      type: "REMOVE_ERROR";
      payload: {
        elementType: StripeElementChangeEvent["elementType"];
      };
    };

const cardInputReducer = (
  state: CardFormState,
  action: CardInputAction
): CardFormState => {
  const elementType = action.payload.elementType;

  switch (action.type) {
    case "SET_INPUT_VALIDITY":
      return {
        ...state,
        [elementType]: {
          ...state[elementType],
          isDirty: true,
          isValid: action.payload.isValid,
        },
      };
    case "ADD_ERROR":
      return {
        ...state,
        [elementType]: {
          ...state[elementType],
          errorMessage: action.payload.errorMessage,
        },
      };
    case "REMOVE_ERROR":
      return {
        ...state,
        [elementType]: {
          ...state[elementType],
          errorMessage: null,
        },
      };
    default:
      throw new Error(
        `[CardInputReducer] invalid action: ${JSON.stringify(action)}`
      );
  }
};

const StripeCardForm = () => {
  const isMobile = useIsMobile();
  const [inputState, inputValidationDispatch] = useReducer(
    cardInputReducer,
    cardFormInitialState
  );

  const paymentModal = usePaymentModalContext();
  const { cardInputsValidity, paymentMethod, paymentOption } =
    paymentModal.state;

  useEffect(() => {
    const hasAllInputsValid = Object.values(inputState).every(
      (field) => field.isValid
    );

    if (hasAllInputsValid && cardInputsValidity === false) {
      paymentModal.action.setCardInputsValidity(true);
    } else if (!hasAllInputsValid && cardInputsValidity === true) {
      paymentModal.action.setCardInputsValidity(false);
    }
  }, [cardInputsValidity, inputState, paymentModal.action]);

  /* ***************** Validations ********************* */

  // Handle real-time validation errors from the card Element.
  const handleCardInputChange = (event: StripeElementChangeEvent) => {
    const { elementType, error } = event;

    inputValidationDispatch({
      type: "SET_INPUT_VALIDITY",
      payload: {
        elementType,
        isValid: event.complete,
      },
    });

    if (error) {
      inputValidationDispatch({
        type: "ADD_ERROR",
        payload: {
          elementType,
          errorMessage: error.message,
        },
      });
    } else {
      inputValidationDispatch({
        type: "REMOVE_ERROR",
        payload: { elementType },
      });
    }
  };

  const handleCardHolderInputChange = () => {
    // clear error state
    handleCardInputChange({
      elementType: CardInputField.CardHolder,
      error: undefined,
      complete: false,
    });
  };

  const validateCardHolderInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    let error: StripeElementChangeEvent["error"];

    if (value?.length < 3) {
      error = {
        type: "validation_error",
        code: "card_holder_name_too_short",
        message: "Veuillez indiquer le nom du titulaire de la carte bancaire.",
      };
    }
    handleCardInputChange({
      elementType: CardInputField.CardHolder,
      error: error,
      complete: !error,
    });
  };

  const dynamicFormStyles: React.CSSProperties =
    paymentOption === PaymentOption.NewPaymentMethod &&
    paymentMethod === PaymentMethod.Card
      ? { height: "100%", display: "flex" }
      : { display: "none" };

  return (
    <CardForm style={dynamicFormStyles}>
      <InputGroup>
        <TextField
          id={CardInputField.CardHolder}
          placeholder="Titulaire de la carte"
          onChange={handleCardHolderInputChange}
          onBlur={validateCardHolderInput}
          hasError={!!inputState[CardInputField.CardHolder].errorMessage}
          errorMessage={""} // use custom error message below
        />
        {inputState[CardInputField.CardHolder].errorMessage && (
          <InputErrorString>
            {inputState[CardInputField.CardHolder].errorMessage}
          </InputErrorString>
        )}
      </InputGroup>
      <Row>
        <InputGroup>
          <CardNumberElement
            id={CardInputField.CardNumber}
            options={{
              ...CARD_ELEMENT_OPTIONS,
              placeholder: "Numéro de la carte",
              showIcon: true,
            }}
            onChange={handleCardInputChange}
          />
          {inputState[CardInputField.CardNumber].errorMessage && (
            <InputErrorString>
              {inputState[CardInputField.CardNumber].errorMessage}
            </InputErrorString>
          )}
        </InputGroup>
      </Row>

      <StyledRow>
        <PaymentSecondaryField $flex={1}>
          <CardExpiryElement
            id={CardInputField.CardExpiry}
            options={{
              ...CARD_ELEMENT_OPTIONS,
              placeholder: isMobile ? "MM / AA" : "Date d'expiration",
            }}
            onChange={handleCardInputChange}
          />
          {inputState[CardInputField.CardExpiry].errorMessage && (
            <InputErrorString>
              {inputState[CardInputField.CardExpiry].errorMessage}
            </InputErrorString>
          )}
        </PaymentSecondaryField>
        <PaymentSecondaryField $flex={1}>
          <CardCvcElement
            id={CardInputField.CardCvc}
            options={{
              ...CARD_ELEMENT_OPTIONS,
              placeholder: isMobile
                ? "CVV/CVC"
                : "Code de sécurité (3 chiffres)",
            }}
            onChange={handleCardInputChange}
          />

          {inputState[CardInputField.CardCvc].errorMessage && (
            <InputErrorString>
              {inputState[CardInputField.CardCvc].errorMessage}
            </InputErrorString>
          )}
        </PaymentSecondaryField>
      </StyledRow>
    </CardForm>
  );
};

export default React.memo(StripeCardForm);
