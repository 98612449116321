import { Title } from "@yolaw/ui-kit-components";
import { ChevronLeft, CloseFlat } from "@yolaw/ui-kit-icons";
import React, {
  DialogHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css, keyframes } from "styled-components";

const dialogFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const dialogFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Container = styled.dialog`
  height: 100%;
  width: 100%;
  max-width: none;
  max-height: none;
  padding: 0;
  border: 0;
  color: inherit;
  background-color: ${(props) => props.theme.colors.common.white};

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 0;
    background: transparent;
  }

  &::backdrop {
    background-color: ${(props) => props.theme.colors.common.white};

    @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
      background-color: var(--modal-backdrop-color);
    }
  }

  &[open] {
    display: grid;
    animation: ${dialogFadeIn} 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;

    &::backdrop {
      animation: ${dialogFadeIn} 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }

    &.dialog--closing,
    &.dialog--closing::backdrop {
      animation: ${dialogFadeOut} 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
`;

const ContainerInner = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  background: white;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    place-self: center;
    width: 100%;
    max-width: 690px;
    max-height: 90vh;
    border-radius: ${(props) => props.theme.borderRadius.m}px;
    overflow: auto;
  }
`;

const DialogTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.xs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: ${(props) => props.theme.spacing.s}px;
  }
`;

const DialogTitle = styled(Title)`
  ${({ theme }) => css`
    && {
      margin-bottom: ${theme.spacing.s}px;
      text-align: center;
    }
  `}
`;

const DialogBody = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s}px ${theme.spacing.s}px;
    overflow-y: auto;

    @media (min-width: ${theme.breakpoints.l}px) {
      padding-block: 0;

      &:last-child {
        padding-bottom: ${theme.spacing.s}px;
      }
    }
  `}
`;

const DialogFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    column-gap: ${theme.spacing.xs}px;
    padding: ${theme.spacing.xxxs}px ${theme.spacing.xs}px;
    box-shadow: ${theme.shadows.blue.medium};

    @media (min-width: ${theme.breakpoints.l}px) {
      padding: ${theme.spacing.s}px;
      box-shadow: none;
    }
  `}
`;

const CloseButton = styled.button`
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;
`;

const BackIcon = styled(ChevronLeft)`
  visibility: hidden;
  ${({ onClick }) =>
    onClick &&
    css`
      visibility: visible;
      cursor: pointer;
    `}
`;

type ModalProps = {
  /** Sets the visibility of the modal */
  isOpen: boolean;
  /** The modal content */
  children: React.ReactNode;
  /** The modal title */
  title?: string;
  /** Function to trigger when closing the modal */
  onClose?: VoidFunction;
  /** Callback function for back icon click */
  onClickBack?: VoidFunction;
  /** Prevent the closing of the modal when press escape or click outside */
  isLocked?: boolean;
  /** Component dedicated for sticky content on mobile (used for actions CTAs) */
  FooterContent?: JSX.Element;
  /** Allow css override thru styled components */
  className?: string;
} & DialogHTMLAttributes<HTMLDialogElement>;

export type CustomHTMLDialogElement = HTMLDialogElement & {
  hide: () => void;
  unhide: () => void;
};

const Modal = React.forwardRef<CustomHTMLDialogElement, ModalProps>(
  (
    {
      isOpen,
      children,
      title,
      onClose,
      onClickBack,
      isLocked,
      FooterContent,
      className,
    },
    forwardedRef
  ) => {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    // work out which classes should be applied to the dialog element
    const dialogClasses = useMemo(() => {
      const classesArray = [className];

      if (!isModalOpen) {
        classesArray.push("dialog--closing");
      }

      return classesArray.join(" ");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    const onCloseModal = useCallback(() => {
      if (isModalOpen) {
        onClose && onClose();
        setIsModalOpen(false);
      }
    }, [isModalOpen, onClose]);

    useImperativeHandle(
      forwardedRef,
      () => {
        const appContainer =
          document.querySelector<HTMLDivElement>("#app-container");

        return {
          ...(dialogRef.current as HTMLDialogElement),
          hide: () => {
            appContainer?.style.setProperty("--overlay-content", "'🔒'");
            appContainer?.setAttribute("covered", "");
            setIsModalOpen(false);
          },
          unhide: () => {
            setIsModalOpen(true);
            appContainer?.removeAttribute("covered");
            appContainer?.style.setProperty("--overlay-content", "''");
          },
        };
      },
      []
    );

    // Eventlistener: trigger onclose when cancel detected
    const onCancel = useCallback(
      (event: any) => {
        event.preventDefault();
        if (!isLocked) onCloseModal();
      },
      [isLocked, onCloseModal]
    );

    // Eventlistener: trigger onCloseModal when click outside
    const onClick = useCallback(
      ({ target }: any) => {
        const { current: el } = dialogRef;
        if (target === el && !isLocked) onCloseModal();
      },
      [isLocked, onCloseModal]
    );

    // Eventlistener: trigger close click on anim end
    const onAnimEnd = useCallback(() => {
      const { current: el } = dialogRef;
      if (!!el && !isModalOpen) {
        el.close();
        document.body.style.overflow = "unset";
      }
    }, [isModalOpen]);

    // when isModalOpen changes run open/close command
    useEffect(() => {
      const { current: el } = dialogRef;
      if (!!el && isModalOpen) {
        el.showModal();
        document.body.style.overflow = "hidden";
      }
    }, [isModalOpen]);

    useEffect(() => {
      setIsModalOpen(isOpen);
    }, [isOpen]);

    return (
      <Container
        ref={dialogRef}
        onClose={onCloseModal}
        onCancel={onCancel}
        onClick={onClick}
        onAnimationEnd={onAnimEnd}
        className={dialogClasses}
      >
        <ContainerInner className="modal-container-inner">
          <DialogTopBar>
            <BackIcon onClick={onClickBack} />
            <CloseButton type="button" onClick={onCloseModal}>
              <CloseFlat size="18" />
            </CloseButton>
          </DialogTopBar>
          <DialogBody>
            {title && <DialogTitle type="h3">{title}</DialogTitle>}
            {children}
          </DialogBody>
          {FooterContent && <DialogFooter>{FooterContent}</DialogFooter>}
        </ContainerInner>
      </Container>
    );
  }
);

export default Modal;
