import { Button, Label, Row, Tag } from "@yolaw/ui-kit-components";
import { FormalityStatus } from "contexts/formalities";
import { formatDate } from "date-fns/format";
import { useSegment } from "hooks";
import { FC } from "react";
import styled, { css } from "styled-components";
import { useFormality } from "../useFormality";
import FormalityOptionsMenu from "./FormalityOptionsMenu";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxs}px;
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};

    @media (min-width: ${theme.breakpoints.l}px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: ${(props) => props.theme.spacing.xs}px;
    }
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  flex: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Body = styled(Row)`
  align-items: center;
`;

const StyledTag = styled(Tag)`
  flex-shrink: 0;
`;

const FormalityCTA = ({ formality: rawFormality }: FormalityCardProps) => {
  const segment = useSegment();
  const formality = useFormality(rawFormality);
  const { detail_url, source_id, status } = formality;

  const unarchiveFormality = () => {
    formality.unarchiveFormalityFunnel();
  };

  if (status === FormalityStatus.Cancelled) {
    return (
      <Button
        type="button"
        variant="tertiary"
        sizing="small"
        text="Désarchiver"
        onClick={unarchiveFormality}
      />
    );
  }

  const openFormality = () => {
    segment.track("formality: clicked", {
      "formality id": source_id, // ⚠️ For tracking purpose only. Technically, a formality doesn't have an actual ID
      "legalentity id": formality.legal_entity_id,
      source_type: formality.source_type,
      source_id: source_id,
    });

    window.open(detail_url);
  };
  return (
    <Button
      type="button"
      variant="primary"
      sizing="small"
      text="Consulter"
      onClick={openFormality}
    />
  );
};

type FormalityCardProps = {
  formality: Formality;
};

const FormalityCard: FC<FormalityCardProps> = ({ formality }) => {
  const {
    source_id: sourceId,
    title,
    updated_at: updatedAt,
    canceled_at,
    status,
  } = formality;

  const updateAtTagText = `Mise à jour le : ${formatDate(
    new Date(updatedAt),
    "dd/MM/yyyy"
  )}`;

  const archivedAtTagText = `Archivé le : ${
    canceled_at ? formatDate(new Date(canceled_at), "dd/MM/yyyy") : "inconnue"
  }`;

  const sourceIdTagText = `n° de dossier : ${sourceId}`;

  return (
    <Container>
      <Header>
        <Label text={title} sizeVariant="LARGE" fontWeightVariant="normal" />
        <StyledTag color="orange">{sourceIdTagText}</StyledTag>
      </Header>
      <Body>
        <StyledTag color="blue">
          {status === FormalityStatus.Cancelled
            ? archivedAtTagText
            : updateAtTagText}
        </StyledTag>
        <FormalityCTA formality={formality} />
        <FormalityOptionsMenu formality={formality} />
      </Body>
    </Container>
  );
};

export default FormalityCard;
