import {
  UniversalBusinessPlan1,
  UniversalComptastart,
  UniversalEcommerce,
  UniversalInsurance3,
  UniversalMailManagement,
  UniversalMutuelle,
} from "@yolaw/ui-kit-icons";
import { useEffect } from "react";
import styled from "styled-components";

import { CardsList } from "components/CardsListStyles";
import { useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import FeatureCard from "../../components/FeatureCard";
import PageContentHeader from "../components/PageContentHeader";

const StyledFeatureCard = styled(FeatureCard)`
  height: 100%;
`;

type CardConfigs = {
  Icon: JSX.Element;
  title: string;
  description: string;
  primaryButtonRedirectURL: string;
};

const CARD_LIST: CardConfigs[] = [
  {
    Icon: <UniversalMailManagement size="32" />,
    title: "Domiciliation de votre siège social",
    description: "Une adresse prestigieuse pour ma domiciliation",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/partenaires/domiciliation/",
  },
  {
    Icon: <UniversalEcommerce size="32" />,
    title: "Compte bancaire professionnel",
    description: "Simple, rapide, en ligne !",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/partenaires/banque/",
  },
  {
    Icon: <UniversalBusinessPlan1 size="32" />,
    title: "Business Plan",
    description: "Planifiez efficacement vos revenus",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/partenaires/logiciel-business-plan/",
  },
  {
    Icon: <UniversalComptastart size="32" />,
    title: "Expert-comptable",
    description: "Soyez accompagné par un cabinet de référence",
    primaryButtonRedirectURL:
      "https://www.legalstart.fr/ls-compta-expertise-comptable/",
  },
  {
    Icon: <UniversalInsurance3 size="32" />,
    title: "Responsabilité Civile Pro",
    description: "Protégez votre société contre les imprévus",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/partenaires/compagnie-assurance/",
  },
  {
    Icon: <UniversalMutuelle size="32" />,
    title: "Mutuelle",
    description: "Obtenez une couverture santé au meilleur prix",
    primaryButtonRedirectURL:
      "https://webapp.legalstart.fr/partenaires/mutuelle/",
  },
];

const CARD_BUTTON_LABEL = "J'en profite";

const ServiceCard = ({ configs }: { configs: CardConfigs }) => {
  const segment = useSegment();

  const { Icon, title, description, primaryButtonRedirectURL } = configs;

  const onCardButtonClicked = (redirectionURL: string) => () => {
    segment.track("partnership lead recommendation: clicked", {
      product: title,
    });

    window.open(redirectionURL);
  };
  return (
    <li>
      <StyledFeatureCard
        icon={Icon}
        title={title}
        description={description}
        primaryButtonOptions={{
          text: CARD_BUTTON_LABEL,
          onClick: onCardButtonClicked(primaryButtonRedirectURL),
        }}
      />
    </li>
  );
};

export const OtherServicesPage = () => {
  const segment = useSegment();

  useEffect(() => {
    segment.track("partnership lead recommendations: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <PageContentHeader title="Les autres services disponibles :" />
      <CardsList>
        {CARD_LIST.map((cardConfigs) => (
          <ServiceCard key={cardConfigs.title} configs={cardConfigs} />
        ))}
      </CardsList>
    </PageContainer>
  );
};
