import { FC, memo } from "react";

import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import { useIsMobile } from "../../hooks";

const AppHeader: FC = memo(() => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <HeaderMobile />;
  }

  return <HeaderDesktop />;
});

export default AppHeader;
