import { Column, Hint, HintType, Text } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

const HintInner = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxxs}px;
    }

    .message-box-content {
      color: ${theme.colors.neutral.darker};
      font-weight: 400;

      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xxxxs}px;
      ul {
        margin-left: ${theme.spacing.s}px;
      }

      @container (min-width: ${theme.breakpoints.l}px) {
        margin-left: -${theme.spacing.s}px;
      }
    }
  `}
`;

const EmptyListMessageBox = () => {
  return (
    <div className="message-box-container">
      <Hint type={HintType.Information}>
        <HintInner>
          <Text fontWeightVariant="bold">
            Vous n’avez pas encore créé votre première décision.
          </Text>
          <div className="message-box-content">
            <p>Le registre des décisions est une obligation légale.</p>
            <p>Les cas de création de décisions sont :</p>

            <ul>
              <li>Ajouter un 1er dirigeant</li>
              <li>
                Conserver toutes vos décisions prises au cours d’une assemblée
                générale
              </li>
              <li>Modifier vos statuts (siège social, dirigeant...)</li>
            </ul>
          </div>
        </HintInner>
      </Hint>
    </div>
  );
};

export default EmptyListMessageBox;
