import { useContext } from "react";
import DecisionViewContext from "../context";

const useDecisionViewContext = () => {
  const { state, dispatch } = useContext(DecisionViewContext.Context);

  const setCurrentDocumentId = (id: number | null) => {
    dispatch({
      type: DecisionViewContext.ActionType.SetCurrentDocumentId,
      payload: id,
    });
  };

  const openModal = (
    payload: (typeof DecisionViewContext.initialState)["openingModal"]
  ) => {
    dispatch({
      type: DecisionViewContext.ActionType.SetOpeningModal,
      payload: payload,
    });
  };

  const closeModal = () =>
    dispatch({
      type: DecisionViewContext.ActionType.SetOpeningModal,
      payload: null,
    });

  return {
    ...state,
    setCurrentDocumentId,
    closeModal,
    openModal,
  };
};

export default useDecisionViewContext;
