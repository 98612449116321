import ApiService from "services/api";
import { CookiesUtils } from "utils";

export const getAdminProfile = async () => {
  try {
    // Only check if the admin tokens are found in cookies
    if (CookiesUtils.getAdminAccessToken()) {
      const response = await ApiService.admin.getAdminProfile();
      if (response?.data) {
        return response.data;
      }
      // Remove the admin tokens if they are invalid
      CookiesUtils.removeAdminAuthTokens();
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getViewAsToken = async (email: string) => {
  try {
    const response = await ApiService.admin.getViewAsToken(email);

    switch (response?.status) {
      case 200:
        return { success: response.data };
      case 403:
      case 404:
        return { error: response.data.message || response.data.detail };
      default:
        return { error: "Unexpected status: " + response?.status };
    }
  } catch (error) {
    throw error;
  }
};
