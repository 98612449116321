import {
  Button,
  Column,
  FileDropzone,
  FileDropzoneFilePreviewType,
  Title,
} from "@yolaw/ui-kit-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import { pdfjs } from "react-pdf";
import { matchPath, useBlocker } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { useIsMobile } from "hooks";
import { BackButton } from "pages/components";
import { ELEMENT_IDS } from "pages/zen/constants";
import { fakePromise } from "utils/promise";
import useDecision from "../../hooks/useDecision";
import useERecordRoutes, {
  E_RECORDS_PATH_TEMPLATE,
} from "../../hooks/useERecordRoutes";
import BottomStickyBar from "../components/BottomStickyBar";
import useDecisionViewContext from "../hooks/useDecisionViewContext";
import DocumentSelectionCancellationModal from "./DocumentSelectionCancellationModal";
import DocumentSelectionError, {
  FileErrorCode,
  MAX_FILE_SIZE,
  ValidationErrorCode,
} from "./DocumentSelectionError";
import AddDocumentPageContainer from "./components/AddDocumentPageContainer";
import FooterBackButton from "./components/FooterBackButton";
import AddDocumentContext from "./context";

const MAX_FILE = 1;

const ContentContainer = styled(Column)`
  > * {
    max-width: 600px;
    width: 100%;
  }

  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `}
`;

const _getPreviewData = (file: File): string | undefined => {
  return file.type !== "application/pdf"
    ? URL.createObjectURL(file)
    : undefined;
};

const DocumentSelection = () => {
  const decision = useDecision();
  const isMobile = useIsMobile();
  const { goToDecisionViewPage } = useERecordRoutes();
  const { setCurrentDocumentId } = useDecisionViewContext();
  const {
    state: { acceptedFile },
    dispatch,
  } = useContext(AddDocumentContext.Context);

  const [selectedFiles, setSelectedFiles] = useState<{
    acceptedFiles: File[];
    fileRejections: FileRejection[];
  }>({ acceptedFiles: [], fileRejections: [] });
  const [filePreviews, setFilePreviews] = useState<
    FileDropzoneFilePreviewType[]
  >([]);
  const [errorCode, setErrorCode] = useState<FileErrorCode | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { acceptedFiles, fileRejections } = selectedFiles;

  /** Block the navigation if:
   * - there is a file selected
   * - and the next location is not the decision view
   */
  const cancellationBlocker = useBlocker(
    ({ nextLocation }) =>
      !!filePreviews.length &&
      !matchPath(
        E_RECORDS_PATH_TEMPLATE.DecisionView.index,
        nextLocation.pathname
      )
  );

  useEffect(() => {
    setErrorCode(fileRejections[0]?.errors[0]?.code || null);
  }, [fileRejections]);

  useEffect(() => {
    if (acceptedFile) {
      // Restore the accepted file.
      setSelectedFiles({
        acceptedFiles: [acceptedFile],
        fileRejections: [],
      });
    }
    // Only do this once after the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rejectAcceptedFile = useCallback(
    (errorCode: ValidationErrorCode) => {
      if (!acceptedFile) return;

      // Move the file from `acceptedFiles` to `fileRejections` with corresponding error code
      setSelectedFiles({
        acceptedFiles: [],
        fileRejections: [
          {
            file: acceptedFile,
            errors: [
              {
                code: errorCode,
                message: `ERROR CODE: ${errorCode}`,
              },
            ],
          },
        ],
      });
    },
    [acceptedFile]
  );

  const validateAcceptedFile = useCallback(async () => {
    if (!acceptedFile) return;

    const arrBuffer = await acceptedFile.arrayBuffer();
    try {
      const documentLoadingTask = pdfjs.getDocument(arrBuffer);
      const documentProxy = await documentLoadingTask.promise;
      const metadata = await documentProxy.getMetadata();
      if (!!(metadata.info as any).EncryptFilterName) {
        // `EncryptFilterName` is not null => the file is encrypted
        rejectAcceptedFile(ValidationErrorCode.EncryptedFile);
      }
    } catch (error: any) {
      if (error.name === "PasswordException") {
        // caught by pdfjs.getDocument()
        rejectAcceptedFile(ValidationErrorCode.PasswordProtected);
      }
    }
  }, [acceptedFile, rejectAcceptedFile]);

  useEffect(() => {
    validateAcceptedFile();
  }, [validateAcceptedFile]);

  useEffect(() => {
    // Set accepted file to context
    dispatch({
      type: AddDocumentContext.ActionType.SetAcceptedFile,
      payload: acceptedFiles[0] || null,
    });
  }, [acceptedFiles, dispatch]);

  useEffect(() => {
    setFilePreviews([
      ...acceptedFiles.map((file) => ({
        id: file.name,
        name: file.name,
        preview: _getPreviewData(file),
        status: {
          name: "custom",
          options: {
            color: "green",
            text: "Téléchargé",
            icon: "CheckmarkFlat",
            size: "large",
          },
        },
      })),
      ...fileRejections.map(({ file }) => ({
        id: file.name,
        name: file.name,
        preview: _getPreviewData(file),
        status: {
          name: "custom",
          options: {
            color: "red",
            text: "Problème",
            icon: "CloseFlat",
            size: "large",
          },
        },
      })),
    ] as FileDropzoneFilePreviewType[]);
  }, [acceptedFiles, fileRejections]);

  const resetFileDropzone = () => {
    setSelectedFiles({ acceptedFiles: [], fileRejections: [] });
  };

  const handleOnDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    // Do nothing as `handleFilesChange` already covers the case
    return fakePromise();
  };

  const handleFilesChange = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    setSelectedFiles({ acceptedFiles, fileRejections });
  };

  const handleSubmission = async () => {
    setIsSubmitting(true);
    try {
      if (decision?.id && acceptedFile) {
        // Create document
        const document = await decision.addDocument(acceptedFile);
        // To select this document when back to the decision view page
        setCurrentDocumentId(document.id);
        goToDecisionViewPage(decision.id, { replace: true });
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <AddDocumentPageContainer className="document-selection-container">
        {isMobile && <BackButton />}
        <div className="page-body">
          <ContentContainer>
            <Title type="H2">Ajout du document</Title>

            <FileDropzone
              onDrop={handleOnDrop}
              onFilesChange={handleFilesChange}
              filePreviews={filePreviews}
              accept={{
                "application/pdf": [".pdf"],
              }}
              maxFiles={MAX_FILE}
              maxSize={MAX_FILE_SIZE * 1024 * 1024} // bytes
              hideInstructions={filePreviews.length >= MAX_FILE}
              instructionLabels={{
                dragInstruction: "Glissez déposez votre document en pdf ici",
                dragReject: (
                  <>
                    Vous ne pouvez pas importer ce type de fichier.
                    <br />
                    Nous acceptons seulement le pdf.
                  </>
                ),
                buttonLabel: "Cliquez pour importer un document",
              }}
            />

            <DocumentSelectionError errorCode={errorCode} />
          </ContentContainer>
        </div>

        <BottomStickyBar id={ELEMENT_IDS.STICKY_BOTTOM_BAR}>
          {!isMobile && <FooterBackButton />}

          {errorCode ? (
            <Button onClick={resetFileDropzone}>Recommencer</Button>
          ) : (
            <Button
              disabled={!acceptedFile || isSubmitting}
              isLoading={isSubmitting}
              onClick={handleSubmission}
            >
              Suivant
            </Button>
          )}
        </BottomStickyBar>
      </AddDocumentPageContainer>
      <DocumentSelectionCancellationModal blocker={cancellationBlocker} />
    </>
  );
};

export default DocumentSelection;
