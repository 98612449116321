import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ModalName } from "contexts/app";
import { useApp, useLegalEntity } from "hooks";
import { DecisionsService } from "services";
import { ERROR_MESSAGES } from "utils/constants";
import DecisionsContext from "../context";

enum SortOrder {
  ASC,
  DESC,
}
type OrderByFunction = (a: Decision, b: Decision) => number;
/** The compare function to determine the order of the Decisions by a key
 * If `orders` is unspecified, all values are sorted in ascending order. Otherwise, specify an order of "desc" for descending or "asc" for ascending sort order of corresponding values.
 * */
const sortDecisionBy = (
  key: keyof Decision,
  orders = SortOrder.ASC
): OrderByFunction => {
  return (a, b) =>
    a[key] > b[key]
      ? orders === SortOrder.ASC
        ? 1
        : -1
      : a[key] < b[key]
      ? orders === SortOrder.ASC
        ? -1
        : 1
      : 0;
};

const useDecisionsContext = () => {
  const app = useApp();
  const { state, dispatch } = useContext(DecisionsContext.Context);
  const [decisionsByLegalEntity, setDecisionsByLegalEntity] = useState<
    Decision[]
  >([]);

  const currentLegalEntity = useLegalEntity(app.currentLegalEntity);

  const [isGettingRecordURL, setIsGettingRecordURL] = useState(false);

  useEffect(() => {
    setDecisionsByLegalEntity(
      state.decisions
        .filter(
          (decision) => decision.legal_entity_id === currentLegalEntity?.id
        )
        .sort(sortDecisionBy("date", SortOrder.DESC))
    );
  }, [state.decisions, currentLegalEntity?.id]);

  const setDecisions = (decisions: Decision[]) => {
    dispatch({
      type: DecisionsContext.ActionType.SetDecisions,
      payload: decisions,
    });
  };

  const getDecisions = async () => {
    const decisions = await DecisionsService.getDecisions();
    setDecisions(decisions);
  };

  useEffect(() => {
    // Get decisions if it has never been loaded before
    if (!state.loaded && !state.loading) {
      dispatch({
        type: DecisionsContext.ActionType.SetIsLoading,
        payload: true,
      });
      getDecisions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, state.loaded]);

  const addDecision = (decision: Decision) => {
    dispatch({
      type: DecisionsContext.ActionType.AddDecision,
      payload: decision,
    });
  };

  const deleteDecision = (decisionId: Decision["id"]) => {
    dispatch({
      type: DecisionsContext.ActionType.DeleteDecision,
      payload: decisionId,
    });
  };

  const updateDecision = (updatedDecision: Decision) => {
    dispatch({
      type: DecisionsContext.ActionType.UpdateDecision,
      payload: updatedDecision,
    });
  };

  const closeModal = () => {
    dispatch({
      type: DecisionsContext.ActionType.SetOpeningModal,
      payload: null,
    });
  };

  const openModal = (
    payload: (typeof DecisionsContext.initialState)["openingModal"]
  ) => {
    dispatch({
      type: DecisionsContext.ActionType.SetOpeningModal,
      payload: payload,
    });
  };

  const getMyRegistryFileURL = async () => {
    if (!currentLegalEntity?.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "e-records decisions",
          from: "export decisions button",
        },
      });
      return;
    }

    try {
      setIsGettingRecordURL(true);
      const result = await DecisionsService.getRecordURL(
        Number(currentLegalEntity?.id)
      );
      if (result) {
        return result.decision_erecords_url;
      }
    } catch (error: any) {
      toast.error(error.message || ERROR_MESSAGES.DEFAULT);
    } finally {
      setIsGettingRecordURL(false);
    }
  };

  return {
    ...state,
    decisionsByLegalEntity,
    addDecision,
    getDecisions,
    setDecisions,
    deleteDecision,
    updateDecision,
    closeModal,
    openModal,
    getMyRegistryFileURL,
    isGettingRecordURL,
  };
};

export default useDecisionsContext;
