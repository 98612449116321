import { Button, Title } from "@yolaw/ui-kit-components";
import { useMemo } from "react";
import styled from "styled-components";

import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useLegalEntity, useSegment } from "hooks";
import DecisionsContext from "../context";
import useDecisionsContext from "../hooks/useDecisionsContext";
import useERecordRoutes from "../hooks/useERecordRoutes";

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex-direction: row;
    align-items: center;
  }
`;

const DecisionsHeader = () => {
  const segment = useSegment();
  const isMobile = useIsMobile();
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const {
    decisionsByLegalEntity,
    openModal,
    getMyRegistryFileURL,
    isGettingRecordURL,
  } = useDecisionsContext();
  const { goToMyRegistryPage } = useERecordRoutes();

  const hasDocument: boolean = useMemo(() => {
    return decisionsByLegalEntity.some(
      (decision) => !!decision.documents.length
    );
  }, [decisionsByLegalEntity]);

  const openDecisionCreationModal = () => {
    if (currentLegalEntity.siren) {
      if (!currentLegalEntity.zenSub) {
        // This action need an active Zen sub
        app.openModal({
          name: ModalName.ZenSubscriptionPayment,
          openedBy: {
            context: "e-records decisions",
            from: "create new decision button",
          },
        });
        return;
      }

      openModal({
        id: DecisionsContext.DecisionModal.DecisionCreate,
      });

      segment.track("e-records decision creation: started", {
        sub_zen: currentLegalEntity.zenSub?.status,
        has_siren: !!currentLegalEntity.siren,
        legalentity_id: currentLegalEntity.id,
      });
    } else {
      app.openModal({
        name: ModalName.ERecordsWaitingForSiren,
        openedBy: {
          context: "mandatory records dashboard",
          from: "create new decision button",
        },
      });
    }
  };

  const getMyRegistry = async () => {
    const fileURL = await getMyRegistryFileURL();
    if (fileURL) {
      goToMyRegistryPage(fileURL);
    }
  };
  return (
    <Header>
      <Title type="h3" text="Mes décisions" />
      <HeaderActions>
        <Button
          text="Certifier et exporter mon registre"
          disabled={!hasDocument || isGettingRecordURL}
          isLoading={isGettingRecordURL}
          onClick={getMyRegistry}
          variant="secondary"
        />
        <Button
          text={isMobile ? "Créer une décision" : "Créer une nouvelle décision"}
          onClick={openDecisionCreationModal}
        />
      </HeaderActions>
    </Header>
  );
};

export default DecisionsHeader;
