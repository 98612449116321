import { useReducer } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDecision from "../../hooks/useDecision";
import DocumentSelection from "./DocumentSelection";
import DocumentValidation from "./DocumentValidation";
import AddDocumentContext from "./context";
import { AddDocumentPaths } from "./routes";

const AddDocument = () => {
  const decision = useDecision();

  const [state, dispatch] = useReducer(
    AddDocumentContext.reducer,
    AddDocumentContext.initialState
  );

  // If the decision is not available, cancel process and get back
  if (!decision) return <Navigate to=".." />;

  return (
    <AddDocumentContext.Context.Provider value={{ state, dispatch }}>
      <Routes>
        <Route index element={<DocumentValidation />} />
        <Route
          path={AddDocumentPaths.selectFile}
          element={<DocumentSelection />}
        />
      </Routes>
    </AddDocumentContext.Context.Provider>
  );
};

export default AddDocument;
