import { useMergeRefs } from "@floating-ui/react";
import React from "react";
import { usePopoverContext } from "./context";

const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & React.PropsWithChildren
>(({ children, ...props }, propRef) => {
  const context = usePopoverContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  throw new Error(
    "The given children of PopoverTrigger is not a valid React element"
  );
});

export default PopoverTrigger;
