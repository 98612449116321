export const isEqualString = (value: any, compareValue: any) => {
  let strValue = value;
  let strCompareValue = compareValue;

  if (typeof value !== 'string') {
    strValue = JSON.stringify(value);
  }

  if (typeof compareValue !== 'string') {
    strCompareValue = JSON.stringify(compareValue);
  }

  return strValue === strCompareValue;
};
