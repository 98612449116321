
enum BillingDocumentType {
  TRANSACTIONAL_INVOICE = "transactional-invoice",
  SUBSCRIPTION_INVOICE = "subscription-invoice",
  CREDIT_NOTE = "credit-note",
  ACCOUNTING_CREDIT_NOTE = "accounting-credit-note",
  // DUMMY = "dummy",
}

export interface BillingInvoice {
  id: number;
  document_id: string;
  source: BillingDocumentType;
  document_name: string;
  download_url: string;
  reference_date: string;
}

export enum WebInvoiceSource {
  Formality = "formality",
  Subscription = "subscription",
}

export type WebInvoice = {
  id: number;
  invoice_id: number;
  source_id: number;
  document_name: string;
  paid_at: string;
  download_url: string;
  source: WebInvoiceSource;
};

export interface GetInvoicesResponseData {
  web_invoices: WebInvoice[];
  billing_documents: {
    documents?: {
      transactional_invoices?: BillingInvoice[];
      vouchers?: BillingInvoice[];
      accounting_credit_notes?: BillingInvoice[];
    };
    subscriptions?: BillingInvoice[];
  };
}
