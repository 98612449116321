import { Row, Text } from "@yolaw/ui-kit-components";
import { CheckCircleFlat, CloseFlat } from "@yolaw/ui-kit-icons";
import styled, { css, useTheme } from "styled-components";

const ErrorRowContainer = styled(Row)`
  ${({ theme }) => css`
    && {
      column-gap: ${theme.spacing.xxxs}px;
      justify-content: flex-start;
    }
  `};
`;

type CriteriaValidationRowProps = {
  label: string;
  onlyShowOnError?: boolean;
  hasError?: boolean;
  hasValidated?: boolean;
};

const CriteriaValidationRow = ({
  onlyShowOnError,
  label,
  hasError,
  hasValidated,
}: CriteriaValidationRowProps) => {
  const theme = useTheme();

  if (onlyShowOnError && !hasError) return null;

  const isValid = hasValidated && !hasError;

  return (
    <ErrorRowContainer>
      {hasError && <CloseFlat color={theme.colors.error.dark} size="12" />}
      {isValid && <CheckCircleFlat color={theme.colors.primary.main} />}
      <Text
        type="XSMALL"
        color={
          isValid ? "neutral.darker" : hasError ? "error.dark" : "neutral.dark"
        }
        text={label}
      />
    </ErrorRowContainer>
  );
};

export default CriteriaValidationRow;
