export enum SubscriptionFamilySlug {
  LSBusinessCapitalDeposit = "ls-business-capital-deposit",
}

interface SubscriptionFamily {
  id: number;
  name: string;
  slug: SubscriptionFamilySlug;
}

interface SubscriptionProduct {
  id: number;
  name: string;
  slug: string;
}

interface SubscriptionPlan {
  id: 1;
  period_unit: string;

  // Not used yet
  // period_interval: number;
  // trialing_unit: string | null;
  // trialing_amount: number | null;
  // is_active: boolean;
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Draft = "draft",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  PausedCollection = "paused_collection",
  Trialing = "trialing",
}

export interface Subscription {
  id: number;
  legal_entity_id: number;
  family: SubscriptionFamily;
  status: SubscriptionStatus;
  redirection_url: string | null;

  product: SubscriptionProduct;

  plan: SubscriptionPlan;

  // NOTE: these properties haven't been used yet
  // tier_id: number;
  // requested_cancellation_at: string | null;
  // cancel_at_period_end: boolean;
  // canceled_at: string | null;
  // cancellation_planned_date: string | null;
  // created_at: string;
  // pricing: string;
  // trial_start_date: string | null;
  // trial_end_date: string | null;
  // current_period_start_date: string | null;
  // current_period_end_date: string | null;
}
