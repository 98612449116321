export enum LegalStructureType {
  Association,
  Commercial,
  RealEstate,
  MicroOrAutoEnterprise,
}

export enum LegalStructure {
  // Association
  Association = "ASSOCIATION",

  // Commercial
  SAS = "SAS",
  SASU = "SASU",
  EURL = "EURL",
  SARL = "SARL",

  // RealEstate
  SCI = "SCI",

  // MicroOrAutoEnterprise
  IndividualEnterprise = "EI",
  AutoEnterprise = "AE",
}

export interface LegalEntity {
  funnel_id: number | null;
  id: number;
  is_standalone_for_subscription?: boolean | null;
  legal_structure: LegalStructure;
  name: string;
  workflow_id: number | null;
  service_id?: number | null;
  siren?: string | null;
  has_access_to_zen?: boolean | null;
  service_id_for_zen?: number | null;
}
