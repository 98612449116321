import { FC } from "react";

import Placeholder from "../../components/Placeholder";
import PlaceholderSvg from "../../assets/banner/placeholder.svg";

const FormalitiesPlaceholder: FC = () => {
  return (
    <Placeholder
      title="Vous n’avez pas de démarches en cours"
      description="Aucune démarche en cours actuellement, remédiez vite à cela !"
      image={<img src={PlaceholderSvg} alt="" />}
    />
  );
};

export default FormalitiesPlaceholder;
