import ComparisonTable from './ComparisonTable';
import ConditionalBlock from './ConditionalBlock';
import ImageViewer from './ImageViewer';
import MoreInfo from './MoreInfo';
import Questionnaire from './Questionnaire';
import QuestionnairePage from './QuestionnairePage';
import QuestionnairePageWithoutForm from './QuestionnairePageWithoutForm';
import ServiceItemsWithThumbnail from './ServiceItemsWithThumbnail';
import StepMap from './StepMap';

const questionnaireComponents = {
  ComparisonTable,
  ConditionalBlock,
  ImageViewer,
  MoreInfo,
  Questionnaire,
  QuestionnairePage,
  QuestionnairePageWithoutForm,
  ServiceItemsWithThumbnail,
  StepMap
};

export default questionnaireComponents;
