import { Column, Hint, HintType, Text } from "@yolaw/ui-kit-components";
import styled from "styled-components";
import { ErrorCode as DropzoneErrorCode } from "react-dropzone";

export const MAX_FILE_SIZE = 5; // MB

const HintInner = styled(Column)`
  && {
    row-gap: 0px;
  }

  ul {
    color: ${({ theme }) => theme.colors.neutral.darker};
    font-size: x-small;
  }
`;

export enum ValidationErrorCode {
  PasswordProtected = "password-protected",
  EncryptedFile = "encrypted-file",
}

export type FileErrorCode = DropzoneErrorCode | ValidationErrorCode | string;

type ErrorBoxProps = {
  errorCode: FileErrorCode | null;
};

const ErrorMessageContent = ({ errorCode }: ErrorBoxProps) => {
  switch (errorCode) {
    case DropzoneErrorCode.FileInvalidType:
      return (
        <Text fontWeightVariant="bold">
          Vous ne pouvez pas importer ce type de fichier. Nous acceptons
          seulement le PDF.
        </Text>
      );
    case DropzoneErrorCode.TooManyFiles:
      return (
        <Text fontWeightVariant="bold">
          Veuillez sélectionner 1 seul fichier.
        </Text>
      );
    case DropzoneErrorCode.FileTooLarge:
      return (
        <>
          <Text fontWeightVariant="bold">
            Ce fichier est trop volumineux (+ de {MAX_FILE_SIZE}Mo).
          </Text>
          <ul>
            <li>
              <Text type="SMALL">
                Compressez le fichier gratuitement avec le site{" "}
                <a
                  href="https://www.adobe.com/fr/acrobat/online/compress-pdf.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  PDF compression de document
                </a>
              </Text>
            </li>
          </ul>
        </>
      );
    case ValidationErrorCode.PasswordProtected:
    case ValidationErrorCode.EncryptedFile:
      return (
        <>
          <Text fontWeightVariant="bold">
            Le PDF téléchargé est en lecture seule
          </Text>
          <Text color="secondary.main">
            Modifiez les autorisations de votre document pour autoriser les
            modifications et essayer à nouveau.
          </Text>
          <Text color="secondary.main">
            Si besoin, contactez-nous via le bouton “Besoins d’aide ?” en haut à
            droite.
          </Text>
        </>
      );
    default:
      return (
        <Text fontWeightVariant="bold">
          Désolé, l'ajout du fichier a échoué. Vous pouvez recommencer.
        </Text>
      );
  }
};

const DocumentSelectionError = ({ errorCode }: ErrorBoxProps) => {
  if (!errorCode) return null;

  return (
    <Hint type={HintType.Error}>
      <HintInner data-testid="error-box">
        <ErrorMessageContent errorCode={errorCode} />
      </HintInner>
    </Hint>
  );
};

export default DocumentSelectionError;
