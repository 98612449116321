import {
  AccountancyFlat,
  CalendarFlat,
  CompteProFlat,
  FormalitiesFlat,
  HeadphoneFlat,
  OtherServicesFlat,
} from "@yolaw/ui-kit-icons";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { styled, useTheme } from "styled-components";

import useAppRoutes from "hooks/useAppRoutes";
import { BuiltRoutePath, RoutePath } from "services/router";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import { LegalStructure, LegalStructureType } from "types/legal-entities";
import { useLegalEntity, useSegment } from "../../hooks";
import MultiLevelNavigationItem from "./MultiLevelNavigationItem";
import NavigationItem from "./NavigationItem";

const Container = styled.nav``;

const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.s}px;
  list-style-type: none;
`;

const NavigationSubList = styled(NavigationList)`
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  padding-top: ${(props) => props.theme.spacing.s}px;
`;

const SubNavigationItem = styled(NavigationItem)`
  > * {
    padding-left: calc(
      var(--nav-item-padding) + var(--nav-item-icon-width) +
        var(--nav-item-column-gap)
    );
    font-size: 14px;
  }
`;

const SOJNavItem = () => {
  const currentLegalEntity = useLegalEntity();

  /** @see https://app.clickup.com/t/2571097/TECH-43552 */
  const sojSubscription = currentLegalEntity.findSOJActiveSubscription();

  if (!sojSubscription) return null;

  const redirectToSOJ = () =>
    window.location.assign(
      `${process.env.REACT_APP_WEB_APP_DOMAIN}${
        sojSubscription.redirection_url || "/compte/service-conformite/"
      }`
    );

  return (
    <NavigationItem
      label="Obligations juridiques"
      icon={<CalendarFlat size="24" />}
      onClick={redirectToSOJ}
    />
  );
};

const ComptastartNavItem = () => {
  const segment = useSegment();
  const { navigateTo } = useAppRoutes();
  const currentLegalEntity = useLegalEntity();

  const activeComptastartSub = currentLegalEntity.findCSActiveSubscription();

  const handleComptastartAccess = () => {
    segment.track("comptastart: displayed", {
      "sub comptastart": activeComptastartSub
        ? activeComptastartSub.status
        : "none",
      cycle: activeComptastartSub?.plan.period_unit,
      "price (ht)": activeComptastartSub?.plan.price_et,
      type: activeComptastartSub?.plan.type_slug,
    });

    if (activeComptastartSub?.redirection_url) {
      window.open(activeComptastartSub.redirection_url);
    } else {
      navigateTo(BuiltRoutePath.ComptastartPage);
    }
  };

  return (
    <NavigationItem
      label="Comptastart"
      icon={<AccountancyFlat size="24" />}
      onClick={handleComptastartAccess}
    />
  );
};

const LSCompteProOrComptastartNavItem = () => {
  const segment = useSegment();
  const theme = useTheme();
  const { generateAppPath, navigateTo } = useAppRoutes();
  const currentLegalEntity = useLegalEntity();

  // Find the active LS Compte Pro subscription
  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  // Find the active Comptastart subscription
  const activeComptastartSub = currentLegalEntity.findCSActiveSubscription();
  const isCSSubWithLEStandaloneForSubscription =
    !!activeComptastartSub &&
    !!currentLegalEntity.is_standalone_for_subscription;

  if (accessibleLSCompteProSub) {
    const checkSirenAndRedirect = (paths: {
      lsBusinessPathname: string;
      userspacePathname: string;
    }) => {
      const { lsBusinessPathname, userspacePathname } = paths;
      if (
        !currentLegalEntity.siren ||
        !accessibleLSCompteProSub.redirection_url
      ) {
        // There are pages with the same pathname as for Business app
        // configured in our Userspace internally.
        // All of them are showing the same content: LSCompteProWaitingForSirenPage
        navigateTo(userspacePathname);
      } else {
        // Redirect to the actual Business page
        const redirectionURL =
          // It's expected to be https://business.legalstart.fr/companies/{company_id}
          `${accessibleLSCompteProSub.redirection_url}/${lsBusinessPathname}`;
        window.open(redirectionURL);
      }
    };

    const trackingEventProperties = {
      "legalentity id": currentLegalEntity.id,
      has_siren: !!currentLegalEntity.siren,
      "ls-cpro sub plan": accessibleLSCompteProSub.plan.period_unit,
      "ls-cpro sub product": accessibleLSCompteProSub.product.name,
      "ls-cpro sub family": accessibleLSCompteProSub.family.name,
    };

    const handleMyProAccountClick = () => {
      segment.track("ls compte pro account: clicked", trackingEventProperties);
      checkSirenAndRedirect({
        lsBusinessPathname: RoutePath.LSCPProAccount,
        userspacePathname: BuiltRoutePath.LSCPProAccountPage,
      });
    };

    const handleMyLatestTransactionsClick = () => {
      segment.track(
        "ls compte pro operations: clicked",
        trackingEventProperties
      );
      checkSirenAndRedirect({
        lsBusinessPathname: RoutePath.LSCPMyLatestTransactions,
        userspacePathname: BuiltRoutePath.LSCPMyLatestTransactionsPage,
      });
    };

    const handleGenerateInvoiceClick = () => {
      segment.track(
        "ls compte pro customer invoices: clicked",
        trackingEventProperties
      );
      checkSirenAndRedirect({
        lsBusinessPathname: RoutePath.LSCPGenerateInvoice,
        userspacePathname: BuiltRoutePath.LSCPGenerateInvoicePage,
      });
    };

    const handleManageExpensesClick = () => {
      segment.track(
        "ls compte pro expense claim: clicked",
        trackingEventProperties
      );
      checkSirenAndRedirect({
        lsBusinessPathname: RoutePath.LSCPMyExpenses,
        userspacePathname: BuiltRoutePath.LSCPMyExpensesPage,
      });
    };

    return (
      <>
        <MultiLevelNavigationItem
          label="LS Compte Pro"
          icon={<CompteProFlat size="24" color={theme.colors.secondary.main} />}
          open
        >
          <NavigationSubList>
            <SubNavigationItem
              label="Mon compte professionnel"
              onClick={handleMyProAccountClick}
            />
            <SubNavigationItem
              label="Mes dernières transactions"
              onClick={handleMyLatestTransactionsClick}
            />
            <SubNavigationItem
              label="Générer une facture client"
              onClick={handleGenerateInvoiceClick}
            />
            <SubNavigationItem
              label="Gérer mes notes de frais"
              onClick={handleManageExpensesClick}
            />
          </NavigationSubList>
        </MultiLevelNavigationItem>
        {isCSSubWithLEStandaloneForSubscription && <ComptastartNavItem />}
      </>
    );
  }

  // No Active LS Compte Pro subscription found

  // TODO: remove LS Compta Basic related code as it won't be supported anymore (need product confirmation)
  const lsComptaBasicSub = currentLegalEntity.findLegacySubscription(
    LegacySubscriptionSlug.LSComptaBasic
  );

  // Just display the LS Compta if an active subscription is found
  if (lsComptaBasicSub)
    return (
      <NavigationItem
        label="LS Compta"
        icon={<AccountancyFlat size="24" />}
        path={generateAppPath(BuiltRoutePath.LSComptaBasicPage)}
      />
    );

  /** Otherwise, check to see whether we should display the Comptastart or not
   * @see https://app.clickup.com/t/2571097/TECH-43552
   * */

  const shouldDisplayComptastart =
    currentLegalEntity.isOneOfLegalStructureTypes([
      LegalStructureType.Commercial,
      LegalStructureType.RealEstate,
      LegalStructureType.Association,
      LegalStructureType.MicroOrAutoEnterprise,
    ]) || isCSSubWithLEStandaloneForSubscription;

  if (!shouldDisplayComptastart) return null;

  return <ComptastartNavItem />;
};

const ZenNavItem = () => {
  const currentLegalEntity = useLegalEntity();
  const { generateAppPath } = useAppRoutes();
  const [zenAccessURL, setZenAccessURL] = useState<string | null>(null);

  const getAccessURL = useCallback(async () => {
    const url = await currentLegalEntity.getZenAccessURL();
    setZenAccessURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntity?.id, currentLegalEntity.zenSub]);

  useEffect(() => {
    getAccessURL();
  }, [getAccessURL]);

  if (!zenAccessURL) return null;

  // Find the active LS Compte Pro subscription
  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  return (
    <MultiLevelNavigationItem
      label="Zen"
      icon={<CalendarFlat size="24" />}
      open={!accessibleLSCompteProSub}
    >
      <NavigationSubList>
        <SubNavigationItem
          label="Calendrier des obligations"
          path={generateAppPath(BuiltRoutePath.LegalsObligationsPage)}
        />
        <SubNavigationItem
          label="Optimisation de votre société"
          path={generateAppPath(BuiltRoutePath.AdministrativeDashboardPage)}
        />
        <SubNavigationItem
          label="Registre des décisions"
          path={generateAppPath(BuiltRoutePath.MandatoryRecordsPage)}
        />
        {/* ONLY display this sub menu item for SAS and SASU */}
        {(currentLegalEntity.isLegalStructure(LegalStructure.SAS) ||
          currentLegalEntity.isLegalStructure(LegalStructure.SASU)) && (
          <SubNavigationItem
            label="Registre des mouvements de titres"
            path={generateAppPath(BuiltRoutePath.TitlesRecordsPage)}
          />
        )}
      </NavigationSubList>
    </MultiLevelNavigationItem>
  );
};

const Navigation: FC = memo(() => {
  const segment = useSegment();

  const handleAssistanceItemClick = () => {
    segment.track("assistance: displayed", {
      from: "menu",
    });

    window.location.assign(
      `${process.env.REACT_APP_WEB_APP_DOMAIN}/compte/besoin-aide/`
    );
  };

  return (
    <Container>
      <NavigationList>
        <NavigationItem
          label="Démarches"
          icon={<FormalitiesFlat size="24" />}
          path={BuiltRoutePath.FormalitiesPage}
        />

        <LSCompteProOrComptastartNavItem />

        <ZenNavItem />

        <SOJNavItem />

        <NavigationItem
          label="Besoin d'aide ?"
          icon={<HeadphoneFlat size="24" />}
          // Temporarily redirect to the old webapp userspace instead
          // @see https://app.clickup.com/t/2571097/TECH-51487
          // path={BuiltRoutePath.LegalAssistancePage}
          onClick={handleAssistanceItemClick}
        />

        <NavigationItem
          label="Autres services"
          icon={<OtherServicesFlat size="24" />}
          path="other-services"
        />
      </NavigationList>
    </Container>
  );
});

export default Navigation;
