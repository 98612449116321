import { Button, Row, Title } from "@yolaw/ui-kit-components";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import styled, { css } from "styled-components";

const MainContainer = styled(Row)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
    border-radius: ${theme.borderRadius.s}px;
    padding: ${theme.spacing.xs}px;
    align-items: flex-start;

    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        flex-direction: column;
      }
    }
  `}
`;

const DiscoverAdministrativeDashboardBanner = () => {
  const { goToAdministrativeDashboard } = useZenRoutes();

  const onClick = () => {
    goToAdministrativeDashboard({
      searchParams: "?utm_source=legal obligation calendar done banner",
    });
  };

  return (
    <MainContainer>
      <Title type="H4">
        Vous êtes à jour dans vos obligations légales, vous pouvez optimiser
        votre société !
      </Title>
      <Button text="Découvrir" sizing="small" onClick={onClick} />
    </MainContainer>
  );
};

export default DiscoverAdministrativeDashboardBanner;
