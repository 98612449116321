import { AxiosResponse } from 'axios';
import zenApi from '../zenApi';

// Project onboarding
const get = (projectId: number): Promise<AxiosResponse<OnboardingQuestionnaire>> =>
  zenApi.get(`/projects/${projectId}/onboarding/`);

const patch = (
  projectId: number,
  data: Partial<OnboardingQuestionnaire>
): Promise<AxiosResponse<QuestionnaireAnswers>> =>
  zenApi.patch(`/projects/${projectId}/onboarding/`, data);

const OnboardingApiEndpoint = {
  get,
  patch
};

export default OnboardingApiEndpoint;
