import { Button } from "@yolaw/ui-kit-components";
import { useIsMobile } from "hooks";
import styled, { css } from "styled-components";
import { SUPPORT_CONTACT } from "utils/constants";

const Container = styled.div`
  ${({ theme }) => css`
    button {
      margin-bottom: ${theme.spacing.xxxs}px;
      > .disabled {
        color: ${theme.colors.secondary.main};
      }
    }
  `}
`;

const ButtonContactPhone = () => {
  const isMobile = useIsMobile();

  const handlePhoneButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    window.location.href = `tel:${SUPPORT_CONTACT.PHONE_NUMBER}`;
  };

  return (
    <Container>
      <Button
        variant="secondary"
        sizing="small"
        leftIcon="IconPhone"
        rightIcon="FlagFr"
        disabled={!isMobile}
        onClick={handlePhoneButtonClick}
      >
        {SUPPORT_CONTACT.PHONE_NUMBER}
      </Button>
    </Container>
  );
};

export default ButtonContactPhone;
