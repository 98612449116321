import styled from "styled-components";

import { PageContentHeader } from "pages/components";
import { PageContainer } from "pages/components/PageStyles";
import UserPasswordUpdateForm from "./UserPasswordUpdateForm";
import UserProfileUpdateForm from "./UserProfileUpdateForm";

const Section = styled.section`
  margin-top: ${(props) => props.theme.spacing.l}px;
`;

export const UserProfilePage = () => {
  return (
    <PageContainer>
      <PageContentHeader title="Mes informations" />
      <UserProfileUpdateForm />
      <Section>
        <UserPasswordUpdateForm />
      </Section>
    </PageContainer>
  );
};
