import TaskApiEndpoint from '../endpoints/tasks';
import { commonApiErrorHandler } from './errors';

const getAll = (projectId: Project['id']) => async (refresh?: boolean) => {
  try {
    const response = await TaskApiEndpoint.getAll(projectId)(refresh);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const getOne = (projectId: Project['id']) => async (taskId: TaskItem['id']) => {
  try {
    const response = await TaskApiEndpoint.getOne(projectId)(taskId);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const doAction =
  (projectId: Project['id']) =>
  async (taskId: TaskItem['id'], actionSlug: Task.Action.Slug, data?: Task.Action.Request.Data) => {
    try {
      const response = await TaskApiEndpoint.doAction(projectId)(taskId, actionSlug, data);
      return response.data;
    } catch (error) {
      return commonApiErrorHandler(error);
    }
  };

const TaskApiHandler = {
  getAll,
  getOne,
  doAction
};

export default TaskApiHandler;
