import LegacySubscription from "types/legacy-subscriptions";
import coreApiInstance from "../coreApi";

export const getSubscriptions = async () =>
  coreApiInstance.get<LegacySubscription.SubscriptionItem[]>("/my/subscriptions/");

export const getSubscriptionDetails = (subscriptionTypeSlug: string) =>
  coreApiInstance.get<LegacySubscription.SubscriptionDetails>(
    `/subscriptions/types/${subscriptionTypeSlug}/`
  );

export const getPlanDetails = (
  subscriptionTypeSlug: string,
  planSlug: string
) =>
  coreApiInstance.get<LegacySubscription.Plan>(
    `/subscriptions/types/${subscriptionTypeSlug}/plans/${planSlug}`
  );

export const initiateStripePayment = (
  data: LegacySubscription.StripePayment.InitialRequestData
) =>
  coreApiInstance.post<LegacySubscription.StripePayment.InitialRequestResponse>(
    "/subscriptions/payment/stripe/initiate/",
    data
  );

export const confirmStripePayment = (
  data: LegacySubscription.StripePayment.ConfirmRequestData
) =>
  coreApiInstance.post<LegacySubscription.StripePayment.SucceedResponse>(
    "/subscriptions/payment/stripe/confirm/",
    data
  );
