type Dictionary = Readonly<Record<string, Array<string>>>;

/** Simple FR definitions for EN words
 * - 1st element: singular or just a normal definition
 * - 2nd element: (optional) If there is a plural definition, put it here
 */
const en2fr: Dictionary = {
  day: ['jour', 'jours'],
  daily: ['quotidien'],
  week: ['semaine', 'semaines'],
  weekly: ['hebdomadaire'],
  month: ['mois', 'mois'],
  monthly: ['mensuel'],
  year: ['an', 'ans'],
  yearly: ['annuel']
};

const translate = (scope: string, plural = false): string => {
  // No definition
  if (!en2fr[scope]?.length) return scope;

  // Plural definition
  if (plural && en2fr[scope][1]) return en2fr[scope][1];

  // Singular definition
  return en2fr[scope][0];
};

const TranslatorUtils = {
  translate
};

export default TranslatorUtils;
