import { Button, Column } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.l}px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;
`;

export const FormSectionBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;
  max-width: 488px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    row-gap: ${(props) => props.theme.spacing.s}px;
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;
  border: 0;
`;

export const FormFooter = styled.div``;

export const FieldGroupRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex-direction: row;
    column-gap: ${(props) => props.theme.spacing.s}px;
  }
`;

export const OptinField = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.spacing.xs}px;

  > div:first-child {
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;

    > div {
      width: 20px;
      height: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const CancelButton = styled(Button).attrs({
  variant: "tertiary",
  sizing: "small",
})`
  color: ${(props) => props.theme.colors.secondary.main};

  > div {
    color: inherit;
    font-weight: normal;
    text-decoration: underline;

    > div {
      width: auto;
      height: auto;
    }
  }

  svg {
    width: 8px;
    height: 8px;
    rotate: 90deg;
  }
`;

export const ModalForm = styled(Column).attrs({
  as: "form",
})`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xs}px;

      @media (min-width: ${theme.breakpoints.l}px) {
        row-gap: ${theme.spacing.s}px;
        text-align: center;
      }
    }
  `}
`;
