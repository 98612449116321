import React from "react";
import { PopoverContext } from "./context";
import { PopoverOptions, usePopover } from "./usePopover";

const Popover = ({
  children,
  ...restProps
}: PopoverOptions & React.PropsWithChildren) => {
  const popover = usePopover({ ...restProps });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
};

export default Popover;
