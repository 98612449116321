import { styled } from "styled-components";

const LegalEntityBaseButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing.xxxs}px;
  width: 100%;
  padding: ${(props) => props.theme.spacing.xxxxs}px;
  border: none;
  background-color: ${(props) => props.theme.colors.common.white};
  color: ${(props) => props.theme.colors.secondary.main};
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;

  > svg {
    flex-shrink: 0;
  }

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: ${(props) => props.theme.borderRadius.xs}px;
  }
`;

export default LegalEntityBaseButton;
