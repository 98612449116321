import { UniversalBuilding } from "@yolaw/ui-kit-icons";
import { FC, memo } from "react";
import { styled } from "styled-components";

import { useApp, useSegment } from "hooks";
import { LegalEntity } from "types/legal-entities";
import LegalEntityBaseButton from "./LegalEntityBaseButton";

const Container = styled(LegalEntityBaseButton)`
  border-radius: ${(props) => props.theme.borderRadius.xs}px;
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.lightest};
  }
`;

type MenuButtonProps = {
  legalEntity: LegalEntity;
  onClickCallback?: () => void;
};

const MenuButton: FC<MenuButtonProps> = memo(
  ({ legalEntity, onClickCallback }) => {
    const segment = useSegment();
    const app = useApp();

    const onMenuButtonClick = () => {
      segment.track("legalentity: selected");
      app.setCurrentLegalEntity(legalEntity);
      onClickCallback?.();
    };

    return (
      <Container onClick={onMenuButtonClick}>
        <UniversalBuilding size="24" />
        <span>{legalEntity.name}</span>
      </Container>
    );
  }
);

export default MenuButton;
