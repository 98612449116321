/** Download file from the given URL */
const downloadFile = async (url: string, fileName: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(fileURL);
    });
};

export default downloadFile;
