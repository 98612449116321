import _isEmpty from "lodash/isEmpty";
import { Route, Routes, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useFluidLayout } from "hooks";
import { LoadingPage } from "pages/loading";
import TaskViewer from "pages/zen/components/TaskViewer";
import TaskCompleteConfirmation from "pages/zen/components/TaskViewer/TaskCompleteConfirmation";
import TaskWithNavigator from "pages/zen/components/TaskViewer/layouts/TaskWithNavigator";
import useTask from "pages/zen/hooks/useTask";
import { TPathParams } from "pages/zen/routes";
import { useEffect } from "react";

const TaskView = () => {
  useFluidLayout();
  const { taskId: taskIdFromUrl } = useParams<TPathParams>();

  const task = useTask(Number(taskIdFromUrl));

  useEffect(() => {
    return () => {
      // Dismiss any active toast when moving to other route
      toast.dismiss();
    }
  }, []);

  // Wait for the task fully loaded
  if (!task.id) return <LoadingPage />;

  if (!task.type)
    throw new Error("[TaskView] Bad Task format. Can not identify task type.");

  if (_isEmpty(task.details))
    throw new Error("[TaskView] Missing task details.");

  if (!task.isAccessible)
    throw new Error("[TaskView] You are not eligible to view this task !");

  if (task.task_completed_waiting) return <TaskCompleteConfirmation />;

  return (
    <Routes>
      <Route element={<TaskWithNavigator />}>
        <Route path="*" element={<TaskViewer task={task} />} />
      </Route>
    </Routes>
  );
};

export default TaskView;
