import {
  Button,
  Column,
  Row,
  Tag,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { useSegment } from "hooks";
import styled, { css } from "styled-components";
import LegacySubscription from "types/legacy-subscriptions";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import { PaymentModalStep } from "../context";
import { usePaymentModalContext } from "../hooks";

const PlanListContainer = styled(Row)`
  align-items: initial;
  justify-content: center;

  ${({ theme }) => css`
    gap: ${theme.spacing.l}px;

    .plan-item {
      box-shadow: ${theme.shadows.blue.small};
      border-radius: ${theme.borderRadius.m}px;
      padding: ${theme.spacing.xs}px;
      row-gap: ${theme.spacing.s}px;
      justify-content: flex-end;
      max-width: 320px;
    }

    .plan-item_info {
      > * {
        display: block;
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-wrap: wrap;
      row-gap: ${theme.spacing.m}px;

      .plan-item {
        width: 100%;
      }
    }
  `}
`;

type PlanItemProps = {
  plan: LegacySubscription.Plan;
  description: string;
  tagText?: string;
};
const PlanItem = ({ plan, description, tagText }: PlanItemProps) => {
  const segment = useSegment();

  const paymentModal = usePaymentModalContext();
  const { styles } = paymentModal.state.modalConfigs;

  const handlePlanSelect = () => {
    segment.track("paywall plan: selected", {
      plan: plan.slug,
      "subscription product": plan.type_slug,
    });
    paymentModal.action.setSelectedPlanSlug(plan.slug);
    paymentModal.action.setPaymentModalStep(PaymentModalStep.Payment);
  };

  return (
    <Column className="plan-item">
      <Column className="plan-item_info">
        {tagText && (
          <div>
            <Tag color={styles.tagColor}>{tagText}</Tag>
          </div>
        )}
        <Title type="H4">
          {NumberUtils.currencyFormat(plan?.price_et || 0)}&nbsp;<sup>HT</sup>
          &nbsp;/
          {TranslatorUtils.translate(plan?.period_unit)}
        </Title>
        <Text type="body">{description}</Text>
      </Column>
      <div>
        <Button
          rightIcon="ArrowRight"
          sizing="small"
          onClick={handlePlanSelect}
        >
          Choisir
        </Button>
      </div>
    </Column>
  );
};

export enum PlanSlug {
  Club = "club",
  Monthly = "monthly",
  Yearly = "yearly",
}

export type PlanSelectionProps = {
  displayPlans: Array<{ [slug in PlanSlug]?: Omit<PlanItemProps, "plan"> }>;
};

const PlanSelection = ({ displayPlans }: PlanSelectionProps) => {
  const paymentModal = usePaymentModalContext();

  const planItemsProps = displayPlans.reduce<PlanItemProps[]>(
    (accumulator, currentValue) => {
      Object.entries(currentValue).forEach(([slug, options]) => {
        const plan = paymentModal.action.getPlan(slug);
        if (plan) {
          accumulator.push({ ...options, plan });
        }
      });
      return accumulator;
    },
    []
  );

  return (
    <>
      <Title type="H3">Choisir ma formule</Title>

      <PlanListContainer>
        {planItemsProps.map((planItemProps) => (
          <PlanItem key={planItemProps.plan.id} {...planItemProps} />
        ))}
      </PlanListContainer>
    </>
  );
};

export default PlanSelection;
