import { Accent } from "@yolaw/ui-kit-components";
import LegacySubscriptionPaymentModal from "components/Modals/LegacySubscriptionPayment";
import { PaymentModalStep } from "components/Modals/LegacySubscriptionPayment/context";
import { ModalName } from "contexts/app";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import CSBenefitThumbnail1 from "./assets/cs-benefit-1.png";
import CSBenefitThumbnail2 from "./assets/cs-benefit-2.png";
import CSBenefitThumbnail3 from "./assets/cs-benefit-3.png";

export const CSSubscriptionPaymentModal = () => {
  return (
    <LegacySubscriptionPaymentModal
      modalName={ModalName.CSSubscriptionPayment}
      subscriptionTypeSlug={LegacySubscriptionSlug.ComptastartClementine}
      modalConfigs={{
        subscriptionDisplayName: "Comptastart",
        styles: {
          color: "primary.main",
          tagColor: "green",
        },
      }}
      stepsConfigs={{
        [PaymentModalStep.Introduction]: {
          title: (
            <span>
              Gérez votre comptabilité avec <Accent>Comptastart</Accent>
            </span>
          ),
          benefitItems: [
            {
              imgSrc: CSBenefitThumbnail1,
              title: "Logiciel de compta tout-en-un",
              description:
                "Facturation, TVA, notes de frais, indemnités kilométriques, bilan...",
            },
            {
              imgSrc: CSBenefitThumbnail2,
              title: "Zéro temps perdu",
              description:
                "Catégorisation automatique des dépenses, trésorerie en temps réel",
            },
            {
              imgSrc: CSBenefitThumbnail3,
              title: "Accompagnement quotidien",
              description:
                "Une équipe dédiée pour vous accompagner sur l'utilisation du logiciel",
            },
          ],
        },
        [PaymentModalStep.PlanSelection]: {
          displayPlans: [
            {
              yearly: {
                tagText: "ÉCONOMISEZ 150€/AN",
                description: "30 jours d'essai inclus, formule plus économique",
              },
              monthly: {
                description: "30 jours d'essai inclus, formule plus souple",
              },
            },
          ],
        },
      }}
    />
  );
};
