import styled, { css } from "styled-components";

const ActiveIndicator = styled.div<{ $percentage: number }>`
  position: absolute;
  height: 100%;
  border-radius: var(--bar-border-radius);

  ${({ theme, $percentage }) => css`
    width: ${$percentage}%;
    background-color: ${theme.colors.primary.light};
  `}
`;

const BarContainer = styled.div`
  height: 16px;
  width: 142px;
  position: relative;
  --bar-border-radius: 60px;

  ${({ theme }) => css`
    border-radius: var(--bar-border-radius);
    background-color: ${theme.colors.neutral.lightest};
  `}
`;

type ProgressBarProps = {
  percentage: number;
};
const ProgressBar = ({ percentage }: ProgressBarProps) => {
  return (
    <BarContainer>
      <ActiveIndicator $percentage={percentage} />
    </BarContainer>
  );
};

export default ProgressBar;
