import { AppContext } from "contexts";
import { useCallback, useContext, useEffect, useMemo } from "react";

export const useSegment = () => {
  const { state: appState } = useContext(AppContext.Context);
  const { currentLegalEntity, user } = appState;

  const identify = useCallback(() => {
    window.analytics.ready(() => {
      const analyticsUser = window.analytics.user();
      if (user && String(user.id) !== analyticsUser.id()) {
        const { id, email, first_name, last_name, is_staff } = user;
        window.analytics.identify(id, {
          email,
          first_name,
          last_name,
          is_staff,
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    identify();
  }, [identify]);

  const commonProperties = useMemo(() => {
    if (currentLegalEntity) {
      // const { id } = currentLegalEntity;
      return {
        // "legalentity id": id,
      };
    }
  }, [currentLegalEntity]);

  const track = useCallback(
    (eventName: string, eventProperties?: Record<string, any>) => {
      const properties = {
        ...commonProperties,
        ...eventProperties,
      };

      window.analytics.track(eventName, properties);
    },
    [commonProperties]
  );

  return {
    track,
  };
};

export default useSegment;
