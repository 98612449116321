import ApiService from "services/api";

export const getInvoices = async () => {
  try {
    const response = await ApiService.invoices.getInvoices();
    if (response?.status === 200) {
      return response.data;
    } else throw new Error("Can not get invoices");
  } catch (error) {
    throw error;
  }
};
