import { ColorPropValue } from "@yolaw/ui-kit-components/types/ui-kit-components/src/colors/types";
import { TagColor } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/Tag";
import React from "react";

import { CustomHTMLDialogElement } from "components/modal/Modal";
import { PaymentError, PaymentMethod, PaymentOption } from "services/payment";
import LegacySubscription from "types/legacy-subscriptions";
import LSUser from "types/user";

export enum PaymentModalStep {
  Introduction = "INTRODUCTION",
  PlanSelection = "PLAN_SELECTION",
  Payment = "PAYMENT",
}

export type PaymentModalContextState = {
  cardInputsValidity: boolean;
  currentModalStep: PaymentModalStep;
  isReadyToPay: boolean;
  modalConfigs: {
    subscriptionDisplayName: string;
    styles: {
      color: ColorPropValue;
      tagColor: TagColor;
    };
  };
  paymentError: PaymentError | null;
  paymentMethod: PaymentMethod;
  paymentOption: PaymentOption;
  savedPaymentMethods: LSUser.PaymentMethodItem[] | null;
  selectedCard: LSUser.PaymentMethodItem | null;
  selectedPlanSlug: LegacySubscription.Plan["slug"] | null;
  subscription: LegacySubscription.SubscriptionDetails | null;
  modalRef: React.MutableRefObject<CustomHTMLDialogElement | null> | null;
};

const initialState: PaymentModalContextState = {
  cardInputsValidity: false,
  currentModalStep: PaymentModalStep.Introduction,
  isReadyToPay: false,
  modalConfigs: {
    subscriptionDisplayName: "MISSING_SUBSCRIPTION_DISPLAY_NAME",
    styles: {
      color: "error.dark",
      tagColor: "red",
    },
  },
  paymentError: null,
  paymentMethod: PaymentMethod.Card,
  paymentOption: PaymentOption.NewPaymentMethod,
  savedPaymentMethods: null,
  selectedCard: null,
  selectedPlanSlug: null,
  subscription: null,
  modalRef: null,
};

export enum PaymentModalContextAction {
  SetCardInputValidity = "SET_CARD_INPUT_VALIDITY",
  SetIsReadyToPay = "SET_IS_READY_TO_PAY",
  SetModalConfigs = "SET_MODAL_CONFIGS",
  SetPaymentError = "SET_PAYMENT_ERROR",
  SetPaymentMethod = "SET_PAYMENT_METHOD",
  SetPaymentOption = "SET_PAYMENT_OPTION",
  SetSavedPaymentMethods = "SET_SAVED_PAYMENT_METHODS",
  SetSelectedCard = "SET_SELECTED_CARD",
  SetPaymentModalStep = "SET_PAYMENT_MODAL_STEP",
  SetSelectedPlanSlug = "SET_SELECTED_PLAN_SLUG",
  SetSubscriptionDetails = "SET_SUBSCRIPTION_DETAILS",
  SetModalRef = "SET_MODAL_REF",
}

type SetCardInputValidityAction = {
  type: PaymentModalContextAction.SetCardInputValidity;
  payload: PaymentModalContextState["cardInputsValidity"];
};

type SetIsReadyToPayAction = {
  type: PaymentModalContextAction.SetIsReadyToPay;
  payload: PaymentModalContextState["isReadyToPay"];
};

type SetModalConfigsAction = {
  type: PaymentModalContextAction.SetModalConfigs;
  payload: PaymentModalContextState["modalConfigs"];
};

type SetPaymentErrorAction = {
  type: PaymentModalContextAction.SetPaymentError;
  payload: PaymentModalContextState["paymentError"];
};

type SetPaymentMethodAction = {
  type: PaymentModalContextAction.SetPaymentMethod;
  payload: PaymentModalContextState["paymentMethod"];
};

type SetPaymentOptionAction = {
  type: PaymentModalContextAction.SetPaymentOption;
  payload: PaymentModalContextState["paymentOption"];
};

type SetSavedPaymentMethodsAction = {
  type: PaymentModalContextAction.SetSavedPaymentMethods;
  payload: LSUser.PaymentMethodItem[];
};

type SetSelectedCardAction = {
  type: PaymentModalContextAction.SetSelectedCard;
  payload: PaymentModalContextState["selectedCard"];
};

type SetPaymentModalStepAction = {
  type: PaymentModalContextAction.SetPaymentModalStep;
  payload: PaymentModalContextState["currentModalStep"];
};

type SetSelectedPlanSlugAction = {
  type: PaymentModalContextAction.SetSelectedPlanSlug;
  payload: PaymentModalContextState["selectedPlanSlug"];
};

type SetSubscriptionDetailsAction = {
  type: PaymentModalContextAction.SetSubscriptionDetails;
  payload: PaymentModalContextState["subscription"];
};

type SetModalRefAction = {
  type: PaymentModalContextAction.SetModalRef;
  payload: PaymentModalContextState["modalRef"];
};

type PaymentModalDispatchAction =
  | SetCardInputValidityAction
  | SetIsReadyToPayAction
  | SetModalConfigsAction
  | SetPaymentErrorAction
  | SetPaymentMethodAction
  | SetPaymentOptionAction
  | SetSavedPaymentMethodsAction
  | SetSelectedCardAction
  | SetPaymentModalStepAction
  | SetSelectedPlanSlugAction
  | SetSubscriptionDetailsAction
  | SetModalRefAction;

const reducer = (
  state: PaymentModalContextState,
  action: PaymentModalDispatchAction
): PaymentModalContextState => {
  switch (action.type) {
    case PaymentModalContextAction.SetCardInputValidity:
      return {
        ...state,
        cardInputsValidity: action.payload,
      };
    case PaymentModalContextAction.SetIsReadyToPay:
      return {
        ...state,
        isReadyToPay: action.payload,
      };
    case PaymentModalContextAction.SetModalConfigs:
      return {
        ...state,
        modalConfigs: {
          ...state.modalConfigs,
          ...action.payload,
        },
      };
    case PaymentModalContextAction.SetPaymentError:
      return {
        ...state,
        paymentError: action.payload,
      };
    case PaymentModalContextAction.SetPaymentMethod:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case PaymentModalContextAction.SetPaymentOption:
      return {
        ...state,
        paymentOption: action.payload,
        // If user select `PaymentOption.NewPaymentMethod`, remove `selectedCard` if any
        selectedCard:
          action.payload === PaymentOption.NewPaymentMethod
            ? null
            : state.selectedCard,
      };
    case PaymentModalContextAction.SetSavedPaymentMethods: {
      /** As of Aug 2024, we only display and use the default payment method
       * @see https://app.clickup.com/t/86bzu96yr
       */
      const visibleSavedPaymentMethods = action.payload.filter(
        (method) => method.is_default_payment_method
      );
      const defaultPaymentMethod =
        visibleSavedPaymentMethods.find(
          (method) => method.is_default_payment_method
        ) || null;

      return {
        ...state,
        savedPaymentMethods: visibleSavedPaymentMethods,
        // Find and set default payment method
        selectedCard: defaultPaymentMethod,
        paymentOption: defaultPaymentMethod
          ? PaymentOption.UseSavedCard
          : PaymentOption.NewPaymentMethod,
      };
    }
    case PaymentModalContextAction.SetSelectedCard:
      return {
        ...state,
        selectedCard: action.payload,
        // These properties should go together
        paymentOption: PaymentOption.UseSavedCard,
        paymentMethod: PaymentMethod.Card,
      };
    case PaymentModalContextAction.SetPaymentModalStep:
      return {
        ...state,
        currentModalStep: action.payload,
      };
    case PaymentModalContextAction.SetSelectedPlanSlug:
      return {
        ...state,
        selectedPlanSlug: action.payload,
      };
    case PaymentModalContextAction.SetSubscriptionDetails:
      return {
        ...state,
        subscription: action.payload,
      };
    case PaymentModalContextAction.SetModalRef:
      return {
        ...state,
        modalRef: action.payload,
      };
    default:
      throw new Error("[PaymentModalContext] unexpected action type");
  }
};

type PaymentModalContextType = {
  state: PaymentModalContextState;
  dispatch: React.Dispatch<PaymentModalDispatchAction>;
};

const Context = React.createContext<PaymentModalContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "PaymentModal";

const PaymentModalContext = {
  Context,
  initialState,
  reducer,
};

export default PaymentModalContext;
