import { Column, Text, Title } from "@yolaw/ui-kit-components";
import { AppContext } from "contexts";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppointmentLocationState } from "types/assistance";

const CalendlyFormWrapper = styled.div`
  max-width: 100%;
`;

const Appointment = () => {
  const { state: appState } = useContext(AppContext.Context);
  const { user } = appState;

  const location = useLocation();
  const {
    questionTheme = "",
    questionSubTheme = "",
    questionText = "",
  } = (location.state || {}) as AppointmentLocationState;

  const calendlyURL = `https://calendly.com/assistance-legalstart-equipe/entretien-aj-20-min/?email=${user?.email}&first_name=${user?.first_name}&last_name=${user?.last_name}&a1=${user?.phone}&a2=${questionTheme}&a3=${questionSubTheme}&a4=${questionText}&hide_event_type_details=1&hide_gdpr_banner=1&text_color=202450&primary_color=16a974`;

  useEffect(() => {
    // Load Calendly widget assets
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );

    if (head) {
      head.appendChild(script);
    }
    return () => {
      if (head) {
        head.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <Column>
        <Title type="H3" text="Entretien Assistance Juridique (20min)" />
        <Text
          color="neutral.dark"
          text="Nos juristes sont spécialiés dans les domaines du droit des sociétés, fiscal, social, etc. Pensez à renseigner la même adresse que dans l'étape précédente. "
        />
      </Column>
      <CalendlyFormWrapper>
        <div
          className="calendly-inline-widget"
          data-url={calendlyURL}
          style={{ minWidth: "320px", height: "1200px" }}
        ></div>
      </CalendlyFormWrapper>
    </>
  );
};

export default Appointment;
