import { Button } from "@yolaw/ui-kit-components";
import { compareAsc } from "date-fns";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext } from "contexts";
import { useSegment } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { BuiltRoutePath } from "services/router";
import { LegalEntity } from "types/legal-entities";
import WebinarBanner from "./WebinareBanner";
import { WEBINAR_URLS } from "./WebinareBanner/webinarURLs";

const LOCAL_STORAGE_DISMISSED_KEY = "ls_zen_webinar_banner_dismissed";

/** Object structure: `{ [path]: [dismissedAt] }` */
type DismissedEntity = {
  [path: string]: string;
};

type LocalStorageDismissedValue = Record<LegalEntity["id"], DismissedEntity>;

type ZenWebinarBannerProps = {
  context: string;
};

const ZenWebinarBanner = forwardRef<
  HTMLDivElement | null,
  ZenWebinarBannerProps
>(({ context }, ref) => {
  const segment = useSegment();
  const { generateAppPath } = useAppRoutes();
  const { state: appState } = useContext(AppContext.Context);
  const { currentLegalEntity } = appState;
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  const currentPath = window.location.pathname;

  const localStorageDismissedInfo: LocalStorageDismissedValue = useMemo(
    () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISSED_KEY) || "{}"),
    []
  );

  const dismissedInfoForCurrentPath: DismissedEntity | undefined =
    useMemo(() => {
      if (currentLegalEntity?.id) {
        return Object.entries(localStorageDismissedInfo).find(
          ([legalEntityId, info]) =>
            legalEntityId === String(currentLegalEntity.id) && info[currentPath]
        )?.[1];
      }
      return undefined;
    }, [currentPath, currentLegalEntity?.id, localStorageDismissedInfo]);

  const checkShouldShowBannerByPage = useCallback(() => {
    if (dismissedInfoForCurrentPath) {
      const today = new Date();
      const dismissedAt = dismissedInfoForCurrentPath[currentPath];

      switch (currentPath) {
        case BuiltRoutePath.FormalitiesPage:
          // There is a dismiss log <=> user dismissed it once
          setShouldShowBanner(false);
          break;
        case generateAppPath(BuiltRoutePath.LegalsObligationsPage):
          const isDismissedBeforeToday =
            compareAsc(today.setHours(0, 0, 0), dismissedAt) === 1;
          // Show the banner if it was dismissed before 12:00AM today
          setShouldShowBanner(isDismissedBeforeToday);
          break;
        default:
          setShouldShowBanner(true);
      }
    } else {
      setShouldShowBanner(true);
    }
  }, [currentPath, dismissedInfoForCurrentPath, generateAppPath]);

  useEffect(() => {
    checkShouldShowBannerByPage();
  }, [checkShouldShowBannerByPage]);

  if (!shouldShowBanner || !currentLegalEntity) return null;

  const dismissBanner = () => {
    segment.track("webinar banner: closed", {
      context,
      redirection_url: WEBINAR_URLS.ZEN,
    });
    // Set the current path with the current time
    localStorageDismissedInfo[currentLegalEntity.id] = {
      ...localStorageDismissedInfo[currentLegalEntity.id],
      [currentPath]: new Date().toISOString(),
    };

    // Store the information in localStorage
    localStorage.setItem(
      LOCAL_STORAGE_DISMISSED_KEY,
      JSON.stringify(localStorageDismissedInfo)
    );

    // Hide the banner
    setShouldShowBanner(false);
  };

  const openWebinarURL = () => {
    segment.track("webinar banner: clicked", {
      context,
      redirection_url: WEBINAR_URLS.ZEN,
    });
    window.open(WEBINAR_URLS.ZEN);
  };

  return (
    <div ref={ref}>
      <WebinarBanner
        testId="zen-webinar-banner"
        title="Découvrez Zen en live avec nos experts"
        subtitle="Nos experts vous montrent comment sécuriser vos aides Pôle emploi,
        obtenir le remboursement de vos frais de création, 
        et faire les bons choix fiscaux pour votre société."
        CTA={
          <Button
            variant="primary"
            sizing="small"
            text="Accéder au live"
            onClick={openWebinarURL}
          />
        }
        onClose={dismissBanner}
      />
    </div>
  );
});

export default ZenWebinarBanner;
