import { AxiosResponse } from 'axios';
import zenApi from '../zenApi';

const getAll =
  (projectId: Project['id']) =>
  (refresh?: boolean): Promise<AxiosResponse<TaskItem[]>> =>
    zenApi.get(`/projects/${projectId}/tasks/`, { params: { refresh } });

const getOne =
  (projectId: Project['id']) =>
  (taskId: TaskItem['id']): Promise<AxiosResponse<TaskItem>> =>
    zenApi.get(`/projects/${projectId}/tasks/${taskId}/`);

const patch =
  (projectId: Project['id']) =>
  (taskId: TaskItem['id'], data: Partial<TaskItem>): Promise<AxiosResponse<TaskItem>> =>
    zenApi.patch(`/projects/${projectId}/tasks/${taskId}/`, data);

const doAction =
  (projectId: Project['id']) =>
  (
    taskId: TaskItem['id'],
    actionSlug: Task.Action.Slug,
    data?: Task.Action.Request.Data
  ): Promise<AxiosResponse<Task.Action.Response.Data>> =>
    zenApi.post(`/projects/${projectId}/tasks/${taskId}/actions/${actionSlug}/`, data);

const TaskApiEndpoint = {
  getAll,
  getOne,
  patch,
  doAction
};

export default TaskApiEndpoint;
