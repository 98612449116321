import { BurgerPointFlat } from "@yolaw/ui-kit-icons";
import React, { useState } from "react";
import styled, { css, useTheme } from "styled-components";

import Popover from "components/Popover/Popover";
import PopoverContent from "components/Popover/PopoverContent";
import PopoverTrigger from "components/Popover/PopoverTrigger";
import DecisionViewContext from "../context";
import useDecisionViewContext from "../hooks/useDecisionViewContext";
import { ModalName } from "contexts/app";
import { useApp, useLegalEntity } from "hooks";

const Container = styled.div`
  background-color: white;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.lightest};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
  `}
`;

const OptionsButton = styled.button`
  flex-shrink: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  padding: ${(props) => props.theme.spacing.xxxs}px;

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.neutral.lightest};
  }
`;

const MenuItemButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.dark};
    width: 100%;
    padding: ${theme.spacing.xxxs}px;
    background-color: transparent;
    border: none;
    border-radius: ${theme.borderRadius.xs}px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.neutral.lightest};
    }
  `}
`;

type OptionsMenuContentProps = {
  document: DecisionDocument;
  closeMenu: VoidFunction;
};

const OptionsMenuContent = ({
  document,
  closeMenu,
}: OptionsMenuContentProps) => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const { openModal } = useDecisionViewContext();

  /* TODO: enable in next version. This feature is not included in MVP */
  // const openDocumentRenameModal = () => {
  //   closeMenu();
  //   openModal({
  //     id: DecisionViewContext.DocumentModal.DocumentRename,
  //     context: {
  //       document: document,
  //     },
  //   });
  // };

  const openDocumentDeleteModal = () => {
    closeMenu();

    if (!currentLegalEntity.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "e-records decisions",
          from: "decision option menu item",
        },
      });
      return;
    }

    openModal({
      id: DecisionViewContext.DocumentModal.DocumentDelete,
      context: {
        document: document,
      },
    });
  };

  const downloadDocument = () => {
    window.open(document.file_url, "_blank");
    closeMenu();
  };

  return (
    <Container>
      <MenuList>
        <MenuItem>
          <MenuItemButton onClick={downloadDocument}>
            Télécharger le document
          </MenuItemButton>
        </MenuItem>
        {/* TODO: enable in next version. This feature is not included in MVP */}
        {/* <MenuItem>
          <MenuItemButton onClick={openDocumentRenameModal}>
            Renommer le document
          </MenuItemButton>
        </MenuItem> */}
        <MenuItem>
          <MenuItemButton onClick={openDocumentDeleteModal}>
            Supprimer le document
          </MenuItemButton>
        </MenuItem>
      </MenuList>
    </Container>
  );
};

type DocumentOptionsMenuProps = {
  document: DecisionDocument;
};

const DocumentOptionsMenu = ({ document }: DocumentOptionsMenuProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Popover placement="left" open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <OptionsButton onClick={openMenu}>
            <BurgerPointFlat size="16" color={theme.colors.neutral.dark} />
          </OptionsButton>
        </PopoverTrigger>
        <PopoverContent>
          <OptionsMenuContent document={document} closeMenu={closeMenu} />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DocumentOptionsMenu;
