import { Column, Title } from "@yolaw/ui-kit-components";
import styled from "styled-components";
import LegalObligationTaskCard from "./LegalObligationTaskCard";
import { Arrow } from "@yolaw/ui-kit-icons";
import { useState } from "react";

const TRANSITION_DURATION = "200ms";
const TRANSITION_TIMING_FUNCTION = "ease";
const DEFAULT_TRANSITION_CONFIG = `${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION}`;

const SectionContainer = styled(Column).attrs({
  as: "section",
})``;

const StyledArrow = styled(Arrow)`
  transition: transform ${DEFAULT_TRANSITION_CONFIG};

  transform: rotate(90deg);

  ${SectionContainer}.expanded & {
    transform: rotate(270deg);
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.xxxs}px;
  cursor: pointer;
`;

const SectionBody = styled(Column)`
  transition: max-height ${DEFAULT_TRANSITION_CONFIG};

  ${SectionContainer}.expanded & {
    max-height: 3000px;
    overflow: visible;
  }
  ${SectionContainer}.collapsed & {
    overflow: hidden;
    max-height: 0;
  }
`;

type LegalObligationTaskSectionProps = {
  title: string;
  tasks: TaskItem[];
  defaultOpen?: boolean;
};

const LegalObligationTaskSection = ({
  title,
  tasks,
  defaultOpen = true,
}: LegalObligationTaskSectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  if (!tasks.length) return null;

  const handleToggleClick = () => setIsOpen(!isOpen);

  return (
    <SectionContainer className={isOpen ? "expanded" : "collapsed"}>
      <SectionTitle onClick={handleToggleClick}>
        <Title type="H4">
          {title} ({tasks.length})
        </Title>
        <StyledArrow size="14px" />
      </SectionTitle>
      <SectionBody>
        {tasks.map((task, index) => (
          <LegalObligationTaskCard key={index} task={task} />
        ))}
      </SectionBody>
    </SectionContainer>
  );
};

export default LegalObligationTaskSection;
