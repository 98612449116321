import { ToastContainer } from "react-toastify";
import styled, { css } from "styled-components";

const LSToastContainer = styled(ToastContainer).attrs({
  icon: false,
  hideProgressBar: true,
})`
  ${({ theme }) => css`
    --toastify-font-family: initial;
    --toastify-color-info: ${theme.colors.information.main};
    --toastify-color-error: ${theme.colors.error.main};
    --toastify-color-success: ${theme.colors.primary.main};
    --toastify-color-warning: ${theme.colors.warning.main};
    --toastify-text-color-light: ${theme.colors.neutral.main};
    --toastify-toast-width: 396px;

    &&&.Toastify__toast-container--top-right,
    &&&.Toastify__toast-container--bottom-right,
    &&&.Toastify__toast-container--top-left,
    &&&.Toastify__toast-container--bottom-left {
      .Toastify__toast {
        border-left: 6px solid;
      }
    }

    &&&.Toastify__toast-container {
      .Toastify__toast--info {
        border-color: var(--toastify-color-info);
      }
      .Toastify__toast--error {
        border-color: var(--toastify-color-error);
      }
      .Toastify__toast--success {
        border-color: var(--toastify-color-success);
      }
      .Toastify__toast--warning {
        border-color: var(--toastify-color-warning);
      }
    }

    .Toastify__close-button {
      opacity: 1;
    }
    .Toastify__close-button--light {
      color: ${theme.colors.neutral.main};
    }

    .Toastify__toast {
      column-gap: ${theme.spacing.s}px;
      border-radius: ${theme.borderRadius.xs}px;
      padding: ${theme.spacing.xs}px;
      box-shadow: ${theme.shadows.blue.small};
    }

    .Toastify__toast-body {
      flex: 1;
      padding: 0px;
    }
  `};
`;

export default LSToastContainer;
