import { Column } from "@yolaw/ui-kit-components";
import { useState } from "react";
import styled from "styled-components";

import SeeMoreToggleButton from "pages/components/SeeMoreToggleButton";
import { LIST_MAX_VISIBLE_ELEMENTS } from "utils/constants";
import useDecisionsContext from "../hooks/useDecisionsContext";
import DecisionCard from "./DecisionCard";
import DecisionsHeader from "./DecisionsHeader";
import EmptyListMessageBox from "./EmptyListMessageBox";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.l}px;

  .message-box-container {
    max-width: 600px;
    align-self: center;
  }
`;

const DecisionListContainer = styled(Column).attrs({
  as: "ul",
  className: "decision-list",
})`
  list-style: none;
  && {
    row-gap: 1rem;
  }
`;

const DecisionsSection = () => {
  const { decisionsByLegalEntity } = useDecisionsContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleDecisions = isExpanded
    ? decisionsByLegalEntity
    : decisionsByLegalEntity.slice(0, LIST_MAX_VISIBLE_ELEMENTS);

  const toggleListExpansion = () => setIsExpanded(!isExpanded);

  return (
    <SectionContainer>
      <DecisionsHeader />

      {decisionsByLegalEntity.length ? (
        <DecisionListContainer>
          {visibleDecisions.map((item, index) => (
            <DecisionCard key={index} decision={item} />
          ))}
          {decisionsByLegalEntity.length > LIST_MAX_VISIBLE_ELEMENTS && (
            <SeeMoreToggleButton
              text={isExpanded ? "Voir moins" : "Voir plus"}
              isOpen={isExpanded}
              onClick={toggleListExpansion}
            />
          )}
        </DecisionListContainer>
      ) : (
        <EmptyListMessageBox />
      )}
    </SectionContainer>
  );
};

export default DecisionsSection;
