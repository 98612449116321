import { Column, IconName, Row, Tag, Text } from "@yolaw/ui-kit-components";
import { TagColor } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/Tag";
import { ChevronRight } from "@yolaw/ui-kit-icons";
import { formatDate } from "date-fns/format";
import styled, { css } from "styled-components";
import useTask, { TaskItemExtended } from "../../hooks/useTask";
import useZenProject from "../../hooks/useZenProject";

const TagContainer = styled.div`
  ${({ theme }) => css`
    @container (min-width: ${theme.breakpoints.m}px) {
      min-width: 140px;
      display: flex;
      justify-content: center;
    }
  `}
`;

const TextContainer = styled(Column)`
  flex: 1;
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.xxxs}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.xxxxs}px;
      }
    }
  `}
`;

const ContentContainer = styled(Row)`
  flex: 1;

  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        align-items: flex-start;
        flex-direction: column;
        row-gap: ${theme.spacing.xxxs}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      && {
        align-items: center;
      }
    }
  `}
`;

const MainContainer = styled(Row)`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.xxs}px;
    padding: ${theme.spacing.xxs}px;
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    background-color: ${theme.colors.common.white};

    @media (min-width: ${theme.breakpoints.l}px) {
      &:hover {
        box-shadow: ${theme.shadows.blue.medium};
        cursor: pointer;
      }
    }
  `}
`;

const StyledTag = styled(Tag)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type TagConfigs = {
  color: TagColor;
  text: string;
  icon?: IconName;
};
const getTagConfigs = (
  task: TaskItemExtended,
  hasCompanySiren: boolean
): TagConfigs | null => {
  if (task.isPendingSiren)
    return { icon: "LockFlat", text: "En attente du SIREN", color: "gray" };

  if (!task.isAccessible)
    return { icon: "LockFlat", text: "ZEN", color: "green" };

  if (task.is_done) return { text: "C'est fait !", color: "green" };

  if (!hasCompanySiren)
    return { text: "À faire dès maintenant", color: "blue" };

  if (!task.due_date) return null;

  const formattedDueDate = formatDate(new Date(task.due_date), "dd/MM/yyyy");
  if (task.isOverDue)
    return {
      text: formattedDueDate,
      color: "red",
    };

  return {
    text: formattedDueDate,
    color: "blue",
  };
};

type LegalObligationTaskCardProps = {
  task: TaskItem;
};

const LegalObligationTaskCard = ({ task }: LegalObligationTaskCardProps) => {
  const project = useZenProject();
  const extendedTask = useTask(task.id);
  const {
    type: { title, subtitle },
  } = extendedTask;

  const tagConfigs = getTagConfigs(extendedTask, project.hasCompanySiren);

  return (
    <MainContainer onClick={extendedTask.handleTaskOpen}>
      <ContentContainer>
        <TagContainer>
          {tagConfigs ? (
            <StyledTag color={tagConfigs.color} icon={tagConfigs.icon}>
              {tagConfigs.text}
            </StyledTag>
          ) : (
            <div />
          )}
        </TagContainer>
        <TextContainer>
          <Text
            color="secondary.main"
            fontWeightVariant={extendedTask.isOverDue ? "bold" : "normal"}
          >
            {title}
          </Text>
          <Text color="neutral.main" type="SMALL">
            {subtitle}
          </Text>
        </TextContainer>
      </ContentContainer>
      <ChevronRight />
    </MainContainer>
  );
};

export default LegalObligationTaskCard;
