import { SeeMore } from "@yolaw/ui-kit-components";
import { ELEMENT_IDS } from "pages/zen/constants";
import { useEffect } from "react";
import styled, { useTheme } from "styled-components";

const SEE_MORE_BUTTON_HEIGHT = "80px";

const SeeMoreButtonContainer = styled.div`
  > div {
    height: ${SEE_MORE_BUTTON_HEIGHT};
  }
`;

const BelowTheFold = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

type MoreInfoProps = {
  seeMoreText: string;
  children: JSX.Element;
};

const MoreInfo = ({ seeMoreText, children }: MoreInfoProps): JSX.Element => {
  const theme = useTheme();
  useEffect(() => {
    const form = document.getElementById(ELEMENT_IDS.QUESTIONNAIRE.FORM);
    if (form) {
      const taskNavigator = document.getElementById(
        ELEMENT_IDS.TASK_VIEW.NAVIGATOR
      );
      form.style.minHeight = `calc(100vh - ${
        taskNavigator?.clientHeight || 0
      }px - ${SEE_MORE_BUTTON_HEIGHT} - ${theme.spacing.xs}px)`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToSeeMoreBlock = () => {
    const element = document.querySelector(`#below-the-fold`);
    element?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <>
      <SeeMoreButtonContainer>
        <SeeMore onClick={scrollToSeeMoreBlock} text={seeMoreText} visibility />
      </SeeMoreButtonContainer>

      <BelowTheFold id="below-the-fold">{children}</BelowTheFold>
    </>
  );
};

export default MoreInfo;
