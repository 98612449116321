import { Column, Text } from "@yolaw/ui-kit-components";
import React from "react";
import styled from "styled-components";

const PdfViewer = styled.object`
  height: 100%;
  width: 100%;

  > * {
    padding: ${({ theme }) => theme.spacing.s}px;
  }
`;

type DocumentViewProps =
  | {
      url: string;
      file?: never;
    }
  | {
      url?: never;
      file: File;
    };

const DocumentView = ({ url, file }: DocumentViewProps) => {
  let dataURL = url;

  if (file) {
    dataURL = URL.createObjectURL(file);
  }

  if (!url && !file) return null;

  const onFileLoad = () => {
    if (file && dataURL) {
      // avoid memory leak
      URL.revokeObjectURL(dataURL);
    }
  };

  return (
    <PdfViewer data={dataURL} type="application/pdf" onLoad={onFileLoad}>
      <Column>
        <Text type="body">Le fichier n'a pas pu être affiché.</Text>
        {url && (
          <a download target="_blank" href={url} rel="noreferrer">
            Cliquez ici pour télécharger!
          </a>
        )}
      </Column>
    </PdfViewer>
  );
};

export default React.memo(DocumentView);
