import React from "react";
import { ArrayUtils } from "utils/array";

enum DecisionModal {
  DecisionCreate,
  DecisionEdit,
  DecisionDelete,
  CertificateAndExportRegistries
}

type OpeningModal<ModalName> = {
  id: ModalName;
  context?: ModalName extends DecisionModal.DecisionCreate
    ? {
        decision: Decision;
      }
    : never;
};

type DecisionsContextState = {
  decisions: Decision[];
  loaded: boolean;
  loading: boolean;
  openingModal: OpeningModal<DecisionModal> | null;
};

const initialState: DecisionsContextState = {
  decisions: [],
  loaded: false,
  loading: false,
  openingModal: null,
};

enum ActionType {
  AddDecision,
  SetDecisions,
  DeleteDecision,
  UpdateDecision,
  SetOpeningModal,
  SetIsLoading,
}

type DecisionsDispatchAction =
  | {
      type: ActionType.AddDecision;
      payload: Decision;
    }
  | {
      type: ActionType.SetDecisions;
      payload: DecisionsContextState["decisions"];
    }
  | {
      type: ActionType.DeleteDecision;
      payload: Decision["id"];
    }
  | {
      type: ActionType.UpdateDecision;
      payload: Decision;
    }
  | {
      type: ActionType.SetOpeningModal;
      payload: DecisionsContextState["openingModal"];
    }
  | {
      type: ActionType.SetIsLoading;
      payload: DecisionsContextState["loading"];
    };

const reducer = (
  state: DecisionsContextState,
  action: DecisionsDispatchAction
) => {
  switch (action.type) {
    case ActionType.AddDecision:
      return {
        ...state,
        decisions: [action.payload].concat(state.decisions),
      };
    case ActionType.SetDecisions:
      return {
        ...state,
        decisions: action.payload,
        loaded: true,
        loading: false,
      };
    case ActionType.DeleteDecision:
      return {
        ...state,
        decisions: state.decisions.filter((d) => d.id !== action.payload),
      };
    case ActionType.UpdateDecision:
      return {
        ...state,
        decisions: ArrayUtils.updateElementById(
          state.decisions,
          action.payload.id,
          action.payload
        ),
      };
    case ActionType.SetOpeningModal:
      return {
        ...state,
        openingModal: action.payload,
      };
    case ActionType.SetIsLoading:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      throw new Error(
        "[DecisionsContext] unexpected action type: " + JSON.stringify(action)
      );
  }
};

type DecisionsContextType = {
  state: DecisionsContextState;
  dispatch: React.Dispatch<DecisionsDispatchAction>;
};

const Context = React.createContext<DecisionsContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "DecisionsContext";

export const DecisionsContext = {
  Context,
  ActionType,
  DecisionModal,
  initialState,
  reducer,
};

export default DecisionsContext;
