import ApiService from "services/api";
import LegacySubscription, {
  LegacySubscriptionSlug,
  LegacySubscriptionStatus,
} from "types/legacy-subscriptions";

export const getSubscriptions = async () => {
  try {
    const response = await ApiService.legacySubscriptions.getSubscriptions();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};

const ACTIVE_STATUSES = [
  LegacySubscriptionStatus.Active,
  LegacySubscriptionStatus.Trialing,
  LegacySubscriptionStatus.PausedCollection,
];

export const isActiveStatus = (subscriptionStatus?: LegacySubscriptionStatus | null) =>
  subscriptionStatus && ACTIVE_STATUSES.includes(subscriptionStatus);

// const _isActive = (subscription: Subscription.SubscriptionItem) =>
//   isActiveStatus(subscription.status);

/** Find ACTIVE subscription (with assumption that backend only provides active subscriptions) */
export const findLegacySubscription = (
  subscriptions: LegacySubscription.SubscriptionItem[],
  subSlugOrPrefix: LegacySubscriptionSlug | string,
) => {
  // const { isActive = true } = filters || {};
  return subscriptions.find(
    (sub) => sub.plan.type_slug.startsWith(subSlugOrPrefix)
    // Tempo remove the active filter with assumption that backend only provides active subscriptions
    // _isActive(sub) === isActive
  );
};

export const getSubscriptionDetails = async (
  subscriptionTypeSlug: string
): Promise<LegacySubscription.SubscriptionDetails | null> => {
  try {
    const response =
      await ApiService.legacySubscriptions.getSubscriptionDetails(
        subscriptionTypeSlug
      );
    return response?.data || null;
  } catch (error) {
    throw error;
  }
};

export const getPlanDetails = async (
  subscriptionTypeSlug: string,
  planSlug: string
) => {
  try {
    const response = await ApiService.legacySubscriptions.getPlanDetails(
      subscriptionTypeSlug,
      planSlug
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const initiateStripePayment = async (
  data: LegacySubscription.StripePayment.InitialRequestData
) => {
  try {
    const response = await ApiService.legacySubscriptions.initiateStripePayment(
      data
    );
    if (response?.status === 200) {
      return response.data;
    } else {
      throw new Error("Le paiement n'a pas pu être initié.");
    }
  } catch (error) {
    throw error;
  }
};

export const confirmStripePayment = async (
  data: LegacySubscription.StripePayment.ConfirmRequestData
) => {
  try {
    const response = await ApiService.legacySubscriptions.confirmStripePayment(
      data
    );
    if (response?.status === 200) {
      return response.data;
    } else {
      throw new Error("La vérification du paiement a échoué.");
    }
  } catch (error) {
    throw error;
  }
};
