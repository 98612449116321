import { Button, TextField } from "@yolaw/ui-kit-components";
import { CancelButton } from "components/FormStyles";
import Modal from "components/modal/Modal";
import React, { useEffect, useState } from "react";
import { DecisionsService } from "services";
import useDecisionViewContext from "../decision-view/hooks/useDecisionViewContext";
import useDocument from "../decision-view/hooks/useDocument";
import useDecision from "../hooks/useDecision";

type DecisionEditModalProps = {
  open: boolean;
};

const DocumentRenameModal: React.FC<DecisionEditModalProps> = ({ open }) => {
  const decision = useDecision();
  const { openingModal, closeModal } = useDecisionViewContext();
  const document = useDocument(openingModal?.context.document?.id || 0);

  const [newDocumentName, setNewDocumentName] = useState("");

  useEffect(() => {
    if (document?.name) {
      setNewDocumentName(document.name);
    }
  }, [document?.name]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDocumentName(event.target.value);
  };

  const changeDocumentName = () => {
    if (decision && document) {
      // TODO: check result + update context
      document.patch({
        name: newDocumentName,
      });

      // API Request
      DecisionsService.patchDocument(decision?.id, document?.id, {
        name: newDocumentName,
      });
    }
    closeModal();
  };

  const FooterContent = (
    <>
      <CancelButton
        formMethod="dialog"
        onClick={closeModal}
        leftIcon="ChevronDownFlat"
      >
        Annuler
      </CancelButton>
      <Button type="submit" onClick={changeDocumentName}>
        Valider
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      title="Renommer le document"
      FooterContent={FooterContent}
      className="document-name-edit-modal"
    >
      <TextField
        label="Nom du document"
        showLabel
        value={newDocumentName}
        onChange={handleInputChange}
      />
    </Modal>
  );
};

export default React.memo(DocumentRenameModal);
