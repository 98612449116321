import { Button, Label, Row, Tag } from "@yolaw/ui-kit-components";
import { formatDate } from "date-fns/format";
import React, { FC } from "react";
import styled, { css } from "styled-components";

import { useSegment } from "hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxs}px;
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};

    @media (min-width: ${theme.breakpoints.l}px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: ${(props) => props.theme.spacing.xs}px;
    }
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  flex: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Body = styled(Row)`
  align-items: center;
`;

const StyledTag = styled(Tag)`
  flex-shrink: 0;
`;

type InvoiceCardProps = {
  id: number;
  documentId: number | string;
  documentName: string;
  referenceDate: string;
  downloadUrl: string;
};

const InvoiceCard: FC<InvoiceCardProps> = ({
  id,
  documentId,
  documentName,
  referenceDate,
  downloadUrl,
}) => {
  const segment = useSegment();

  const paidAtTagText = `Date : ${formatDate(
    new Date(referenceDate),
    "dd/MM/yyyy"
  )}`;
  const sourceIdTagText = `n° de dossier : ${documentId}`;

  const onDownloadButtonClick = () => {
    segment.track("invoice: downloaded", { invoice_id: id });

    window.open(downloadUrl, "_blank");
  };

  return (
    <Container>
      <Header>
        <Label
          text={documentName}
          sizeVariant="LARGE"
          fontWeightVariant="normal"
        />
        <StyledTag color="orange">{sourceIdTagText}</StyledTag>
      </Header>
      <Body>
        <StyledTag color="blue">{paidAtTagText}</StyledTag>
        <Button
          type="button"
          variant="primary"
          sizing="small"
          text="Télécharger"
          onClick={onDownloadButtonClick}
        />
      </Body>
    </Container>
  );
};

export default React.memo(InvoiceCard);
