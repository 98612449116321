import { Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import { Bolt, CloseFlat } from "@yolaw/ui-kit-icons";
import styled, { css, useTheme } from "styled-components";
import avatarImg from "./assets/webinar-avatar.png";

const CloseButton = styled.div`
  cursor: pointer;
  min-width: 12px;
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      position: absolute;
      top: ${theme.spacing.xs}px;
      right: ${theme.spacing.xs}px;
    }
  `}
`;

const TextContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxxs}px;
      justify-content: center;
    }
  `}
`;

const TitleContainer = styled(Row)`
  ${({ theme }) => css`
    && {
      align-items: baseline;
      column-gap: ${theme.spacing.xxxs}px;
      justify-content: flex-start;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      svg {
        display: none;
      }
    }
  `}
`;

const BannerContainer = styled.div`
  display: flex;
  container-type: inline-size;
  position: relative;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
    padding: ${theme.spacing.xs}px;
    border-radius: ${theme.borderRadius.s}px;
    justify-content: space-between;

    .inner-banner-container {
      display: flex;
      justify-content: space-between;
      flex: 1;
      gap: ${theme.spacing.s}px;
    }

    .webinar-avatar {
      max-width: 80px;
      object-fit: contain;
    }

    .avatar-and-text-container,
    .avatar-and-title-container {
      display: flex;
      column-gap: ${theme.spacing.xs}px;
    }

    .banner-cta {
      align-self: center;
      flex-shrink: 0;
    }

    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column;
      align-items: center;
      column-gap: ${theme.spacing.xs}px;
      row-gap: ${theme.spacing.s}px;
      padding-top: ${theme.spacing.l}px;

      .inner-banner-container {
        flex-direction: column;
      }

      .avatar-and-text-container {
        flex-direction: column;
        row-gap: ${theme.spacing.xxxxs}px;
      }

      #subtitle-desktop {
        display: none;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      gap: ${theme.spacing.m}px;
      #subtitle-mobile {
        display: none;
      }
    }
  `}
`;

const Subtitle = ({
  id,
  children,
}: React.PropsWithChildren<{ id: string }>) => {
  return (
    <Text type="SMALL" id={id}>
      {children}
    </Text>
  );
};

type WebinarBannerProps = {
  title: string;
  subtitle: string;
  onClose: VoidFunction;
  CTA: JSX.Element;
  testId?: string;
};
export const WebinarBanner = ({
  title,
  subtitle,
  onClose,
  CTA,
  testId
}: WebinarBannerProps) => {
  const theme = useTheme();

  return (
    <BannerContainer data-testid={testId} >
      <div className="inner-banner-container">
        <div className="avatar-and-text-container">
          <div className="avatar-and-title-container">
            <img
              className="webinar-avatar"
              src={avatarImg}
              alt="webinar-avatar"
            />

            <TextContainer>
              <TitleContainer>
                <Bolt color={theme.colors.primary.main} />
                <Title type="H4">{title}</Title>
              </TitleContainer>
              <Subtitle id="subtitle-desktop">{subtitle}</Subtitle>
            </TextContainer>
          </div>
          <Subtitle id="subtitle-mobile">{subtitle}</Subtitle>
        </div>

        <div className="banner-cta">{CTA}</div>
      </div>

      <CloseButton onClick={onClose}>
        <CloseFlat size={"12"} color={theme.colors.neutral.main} />
      </CloseButton>
    </BannerContainer>
  );
};

export default WebinarBanner;
