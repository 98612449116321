import ApiService from "services/api";
import { LegalEntity } from "types/legal-entities";

export const getZenAccessURL = async (
  serviceId: NonNullable<LegalEntity["service_id"]>
) => {
  try {
    const response = await ApiService.services.getZenAccessURL(serviceId);
    if (response?.data) {
      return response.data.zen_redirect_url;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const triggerEvent = async (
  serviceId: NonNullable<LegalEntity["service_id"]>,
  eventSlug: string
) => {
  try {
    const response = await ApiService.services.triggerEvent(serviceId, eventSlug);
    if (response?.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
