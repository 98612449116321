import {
  Button,
  Column,
  Row,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import { EmailUtils } from "utils";
import { isValidEmail } from "utils/email";
import { getNextParam } from "../auth-utils";

const Container = styled(Column)`
  width: 100%;
`;

const Form = styled(Column).attrs({ as: "form" })`
  width: 100%;
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `};
`;

const Footer = styled(Row)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        flex-direction: column-reverse;
        align-items: stretch;
        text-align: center;
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        align-items: center;
        column-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;

type FormInputs = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const emailInParams = searchParams.get("email");
  const defaultEmail =
    emailInParams && isValidEmail(emailInParams) ? emailInParams : "";

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: defaultEmail,
      password: "",
    },
  });

  const { clearErrors, control, handleSubmit, formState, setError, watch } =
    formMethods;
  const emailValue = watch("email");
  const passwordValue = watch("password");

  const { errors, isDirty, isSubmitting, isSubmitSuccessful } = formState;

  const validationRules = {
    email: {
      required: "Champs ci-dessus requis",
      pattern: {
        value: EmailUtils.EMAIL_REGEX,
        message: "Veuillez respecter le format email __________@_____.__",
      },
    },
    password: {
      required: "Champs ci-dessus requis",
    },
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      const { success, error } = await AuthService.login(
        data.email,
        data.password
      );
      if (success) {
        const next = getNextParam();
        // Reload with the `next`
        window.location.assign(next);
      }
      if (error) {
        setError("root", {
          type: "custom",
          message: error.error || "Mot de passe incorrect.",
        });
        // Also set custom field error to highlight the fields
        setError("email", {
          type: "custom",
          message: "",
        });
        setError("password", {
          type: "custom",
          message: "",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // Clear root error when any field is changed
    clearErrors("root");
  }, [clearErrors, emailValue, passwordValue]);

  return (
    <Container>
      <Title text="Connectez-vous" type="H3" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Column>
          <Controller
            control={control}
            name="email"
            rules={{ ...validationRules?.email }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                label="Votre email"
                id="email"
                placeholder="Entrez votre email"
                showLabel
                disabled={!!defaultEmail || isSubmitting}
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ ...validationRules?.password }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                label="Votre mot de passe"
                type="password"
                id="password"
                placeholder="Entrez votre mot de passe"
                showLabel
                disabled={isSubmitting}
                hasError={!!errors.password}
                errorMessage={errors.password?.message}
                {...restOfField}
              />
            )}
          />
        </Column>
        {errors.root && (
          <Text
            type="small"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}
        <Footer>
          {emailValue && isValidEmail(emailValue) && !isSubmitting ? (
            <Link
              to={
                BuiltRoutePath.PasswordResetRequestPage + window.location.search
              }
              state={{
                email: emailValue,
              }}
            >
              Mot de passe oublié ?
            </Link>
          ) : (
            <Text color="neutral.light" fontWeightVariant="bold">
              Mot de passe oublié ?
            </Text>
          )}
          <Button
            type="submit"
            variant="primary"
            disabled={!isDirty || isSubmitting || isSubmitSuccessful}
            isLoading={isSubmitting}
          >
            Me connecter
          </Button>
        </Footer>
      </Form>
    </Container>
  );
};
