import { LegalEntity } from "types/legal-entities";
import coreApiInstance from "../coreApi";

export const getZenAccessURL = (
  serviceId: NonNullable<LegalEntity["service_id"]>
) =>
  coreApiInstance.get<ZenAccessURLResponse>(
    `/services/${serviceId}/zen_access/`
  );

export const triggerEvent = (serviceId: number, eventSlug: string) =>
  coreApiInstance.post(`/services/${serviceId}/trigger-event/${eventSlug}/`);
