import { Button } from "@yolaw/ui-kit-components";
import { useSegment } from "hooks";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import React from "react";

type CustomButtonProps = {
  buttonConfig: Task.ButtonConfigs;
};
const CustomButton = ({ buttonConfig }: CustomButtonProps) => {
  const task = useTask();
  const segment = useSegment();
  const project = useZenProject();
  const { action, configs } = buttonConfig;

  const handleButtonClick = async () => {
    if (!action) {
      throw Error(`button "action" is not provided`);
    }

    // FIXME: duplicated with the same event when using inside QuestionnaireViewer
    segment.track("zen task cta: clicked", {
      has_siren: project.hasCompanySiren,
      task_id: task.id,
      task_category: task.type.category,
      task_class: task.taskClass,
      task_slug: task.type.slug,
      task_type: task.type.kind,
      task_name: task.type.title,
      button_variant: configs.variant,
      button_label: configs.text,
    });
    return task.handleTaskAction(action);
  };

  return <Button {...configs} onClick={handleButtonClick} />;
};

export default React.memo(CustomButton);
