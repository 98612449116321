type ObjectWithId = {
  id: number;
};

const updateElementById = <T extends ObjectWithId>(
  array: T[],
  id: T["id"],
  updatedProperties: Partial<T>
): T[] => {
  const updatedArray = array.map((element) => {
    if (element.id === id) {
      return { ...element, ...updatedProperties };
    }

    return element;
  });

  return updatedArray;
};

export const ArrayUtils = {
  updateElementById,
};
