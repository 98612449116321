import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import styled from "styled-components";

import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import HourglassImage from "pages/zen/assets/svgs/$yolaw-icon-universal-inprogress.svg";

const BodyContainer = styled(Column)`
  align-items: center;
  text-align: center;
`;

const WaitingSirenModal = () => {
  const app = useApp();

  return (
    <Modal
      isOpen={app.openingModal?.name === ModalName.ZenWaitingForSiren}
      onClose={app.closeModal}
    >
      <BodyContainer>
        <>
          <img src={HourglassImage} width="80px" alt="hourglass" />

          <Title type="H2">En attente de votre numéro SIREN</Title>

          <Text type="BODY">
            Cette tâche sera disponible une fois que votre société sera créée.
          </Text>

          <Text type="BODY">
            Son contenu sera personnalisé selon les informations de votre
            société.
          </Text>

          <Button onClick={app.closeModal}>J’ai compris !</Button>
        </>
      </BodyContainer>
    </Modal>
  );
};

export default WaitingSirenModal;
