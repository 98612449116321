import {
  Button,
  Column,
  Row,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import { EmailUtils } from "utils";
import { ERROR_MESSAGES } from "utils/constants";
import { getNextParam } from "../auth-utils";

const Container = styled(Column)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.darker};
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `}
`;

const Footer = styled(Row)`
  @container (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    && {
      flex-direction: column-reverse;
      align-items: stretch;
      text-align: center;
    }
  }

  @container (min-width: ${({ theme }) => theme.breakpoints.m + 1}px) {
    && {
      align-items: center;
    }
  }
`;

type FormInputs = {
  email: string;
};

const EmailModificationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });
  const { control, formState, handleSubmit, setError } = formMethods;
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: FormInputs) => {
    if (!data.email) return;

    try {
      await AuthService.updateInitialEmail({
        email: data.email,
        next: getNextParam(),
      });

      // Update search params with the new email to use in the verification page
      searchParams.set("new_email", data.email);

      navigate(
        BuiltRoutePath.EmailVerificationPage + `?${searchParams.toString()}`,
        {
          replace: true,
          state: { disableInitialRequest: true },
        }
      );
    } catch (error: any) {
      setError("email", { message: error.message || ERROR_MESSAGES.DEFAULT });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Column>
          <Title text="Modifiez votre email" type="H3" color="secondary.dark" />
          <Text>
            Renseignez la nouvelle adresse email avec laquelle vous souhaitez
            vous connecter à votre compte.
          </Text>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Le champ ci-dessus est requis",
              pattern: {
                value: EmailUtils.EMAIL_REGEX,
                message:
                  "Veuillez respecter le format email __________@_____.__",
              },
            }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                placeholder="Entrez votre email"
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        <Footer>
          <Link
            to={BuiltRoutePath.EmailVerificationPage + window.location.search}
            replace
            state={{ disableInitialRequest: true, disableCounter: true }}
          >
            Annuler
          </Link>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Modifier l'email
          </Button>
        </Footer>
      </Container>
    </form>
  );
};

export default EmailModificationPage;
