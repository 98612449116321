import { ModalName } from "contexts/app";
import { isBefore } from "date-fns/isBefore";
import { useApp, useSegment } from "hooks";
import { useContext } from "react";
import ZenContext from "../context";
import ZenService from "../services/apis";
import useZenProject from "./useZenProject";
import useZenRoutes from "./useZenRoutes";

enum TaskClass {
  AdministrativeTask = "administrative_task",
  LegalObligationTask = "legal_obligation",
}

export type TaskItemExtended = {
  /** Whether user can open the task from dashboard or not?
   * - If it's not locked
   * - If it's locked but task type = document
   */
  isAccessible: boolean;
  /**  Not done yet and due date is before today */
  isOverDue: boolean;
  isPendingSiren: boolean;
  /** This is used for tracking only*/
  taskClass: TaskClass | undefined;
  displayTaskCompleteConfirmation: VoidFunction;
  handleTaskAction: (
    action: Task.Action.Slug,
    data?: Task.Action.Request.Data
  ) => Promise<void | Task.Action.Response.Data>;
  handleTaskOpen: VoidFunction;
} & TaskItem;

const useTask = (taskId?: number): TaskItemExtended => {
  const app = useApp();
  const {
    state: {
      info: { id: projectId },
      tasks,
    },
  } = useContext(ZenContext.Context);
  const project = useZenProject();
  const segment = useSegment();
  const {
    isOnboardingQuestionnaire,
    goToDashboard,
    goToTask,
    taskId: taskIdFromUrl,
  } = useZenRoutes();

  const tId = taskId || Number(taskIdFromUrl);

  if (!tId && !isOnboardingQuestionnaire) {
    console.error(
      "[Hooks] useTask: it looks like you are using this hook outside of a TaskView, please provide a task ID!",
      { tId }
    );
    throw new Error("Can not identify the task!");
  }

  const currentTask = project.getTaskById(tId);

  const isAccessible = Boolean(
    currentTask &&
      (!currentTask.is_locked || currentTask.type.kind === "document")
  );
  const isOverDue = Boolean(
    currentTask?.due_date &&
      !currentTask.is_done &&
      isBefore(currentTask.due_date, new Date())
  );
  const isPendingSiren = Boolean(
    currentTask &&
      !currentTask.is_done &&
      currentTask.type.need_siren === true &&
      !project.hasCompanySiren
  );

  const taskClass: TaskClass | undefined = (() => {
    if (!currentTask) return undefined;

    if (Object.values(project.administrativeTasks).flat().includes(currentTask))
      return TaskClass.AdministrativeTask;

    if (
      Object.values(project.legalObligationTasks).flat().includes(currentTask)
    )
      return TaskClass.LegalObligationTask;

    return undefined;
  })();

  const displayTaskCompleteConfirmation = () => {
    project.updateTask({
      ...(currentTask as TaskItem),
      is_done: true,
      task_completed_waiting: true,
    });
  };

  const handleTaskAction = async (
    actionSlug: Task.Action.Slug,
    data?: Task.Action.Request.Data
  ) => {
    const response = await ZenService.project(projectId)
      .task(tId)
      .doAction(actionSlug, data);

    switch (response.redirect_to) {
      case "dashboard":
        goToDashboard({
          navigateOptions: { replace: true },
          searchParams: "?utm_source=task",
        });
        break;
      case "next_task": {
        const tasksBeforeUpdate = tasks;
        project.setTasks(tasksBeforeUpdate.concat(response.generated_tasks));

        const shouldRedirectToTask = response.generated_tasks[0];
        if (shouldRedirectToTask) {
          return goToTask(shouldRedirectToTask.id, {
            navigateOptions: { replace: true },
          });
        } else {
          return displayTaskCompleteConfirmation();
        }
      }
      case "congrats":
        displayTaskCompleteConfirmation();
        break;
      case "external_url":
        window.open(response.redirect_url);
        break;

      case "document_generated_successfully":
      default:
        return response;
    }
  };

  const handleLockedTaskOpen = () => {
    const {
      type: { category, kind, slug, title },
      unlock_url,
    } = currentTask as TaskItem;

    const commonEventProperties = {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      has_siren: project.hasCompanySiren,
      task_category: category,
      task_class: taskClass,
      task_name: title,
      task_slug: slug,
      task_type: kind,
    };

    // Click on "waiting for Siren" task
    if (isPendingSiren) {
      segment.track("zen pending task: clicked", {
        ...commonEventProperties,
        pending: "siren",
      });
      app.openModal({
        name: ModalName.ZenWaitingForSiren,
        openedBy: {
          context: project.currentDashboard,
          from: "pending task",
        },
      });
      return;
    }

    if (unlock_url) {
      segment.track("zen locked task: clicked", {
        ...commonEventProperties,
        blocked_by: "aj", // FIXME: there is only AJ has unlock_url for now
      });
      window.location.assign(unlock_url);
      return;
    }

    // FIXME: need a better mechanism to check and decide unlock action
    if (project.isZenSubscriptionNeeded) {
      segment.track("zen locked task: clicked", {
        ...commonEventProperties,
        blocked_by: "zen",
      });
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: project.currentDashboard,
          from: "locked legal obligation task",
        },
      });
      return;
    }

    console.error("⚠️ Unlock action is not defined!");
  };

  const handleTaskOpen = () => {
    if (!currentTask) return;
    if (isPendingSiren || !isAccessible) {
      handleLockedTaskOpen();
      return;
    }

    goToTask(currentTask.id, {
      searchParams: new URLSearchParams({ utm_source: "dashboard" }),
    });
  };

  return {
    ...(currentTask as TaskItem),
    isAccessible,
    isOverDue,
    isPendingSiren,
    taskClass,
    displayTaskCompleteConfirmation,
    handleTaskAction,
    handleTaskOpen,
  };
};

export default useTask;
