import { useEffect, useState } from "react";

const useCountDown = () => {
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timer | null = null;
    if (remainingSeconds > 0) {
      intervalId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [remainingSeconds]);

  const start = (seconds: number) => {
    setRemainingSeconds(seconds);
  };

  return {
    remainingSeconds,
    isCounting: !!remainingSeconds,
    start,
  };
};

export default useCountDown;
