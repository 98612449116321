// CONFIGURATION

export const CONTENT_MAX_WIDTH = 1200; // px

export const ELEMENT_IDS = {
  APP_CONTAINER: 'lz-app',
  CONFETTI_ANIMATION_CONTAINER: 'confetti-animation-container',
  HEADER: {
    APP_HEADER: 'app-header',
    CENTER_BLOCK: 'header_center-block'
  },
  QUESTIONNAIRE: {
    FORM: 'questionnaire-form',
    NAVIGATOR: 'questionnaire-navigator',
    PAGE_CONTAINER: 'questionnaire-page-container'
  },
  DOC_GEN: {
    PREVIEW_CONTAINER: 'docgen-preview-container'
  },
  CONTACT_LAWYER_BUTTON: 'contact-lawyer-button',
  STICKY_BOTTOM_BAR: 'sticky-bottom-bar',
  TASK_VIEW: {
    NAVIGATOR: 'task-navigator'
  }
};

export const COOKIES_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken'
};

export const LOCAL_STORAGE_KEYS = {
  HAS_DOWNLOADED_KBIS: 'lz_has_downloaded_kbis',
  HAS_SEEN_ZEN_INTRO: 'lz_has_seen_zen_intro',
  LAST_PROJECT_ID: 'lz_last_project_id'
};

export const SEARCH_PARAMS = {
  USER_TOKEN: 'user_token',
  LS_USER_ID: 'user_id'
};

export const SUPPORT_CONTACT = {
  PHONE_NUMBER: '01 76 39 04 59'
};
