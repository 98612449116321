import { Button } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

type SeeMoreButtonProps = {
  isOpen: boolean;
};

const SeeMoreToggleButton = styled(Button).attrs({
  sizing: "small",
  variant: "tertiary",
  leftIcon: "ChevronDownFlat",
})<SeeMoreButtonProps>`
  && {
    padding-block: ${(props) => props.theme.spacing.xxxs}px;
  }

  svg {
    width: 18px;
    height: 18px;
    transition: rotate 0.2s ease;

    ${(props) =>
      props.isOpen &&
      css`
        rotate: 180deg;
      `};
  }
`;

export default SeeMoreToggleButton;
