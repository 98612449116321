import { Text } from "@yolaw/ui-kit-components";
import useSegment from "hooks/useSegment";
import { TaskItemExtended } from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import {
  isDocumentTask,
  isHubspotPageTask,
  isQuestionnaireTask,
  isWebinarTask,
} from "pages/zen/utils/tasks";
import { useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DocumentGenerationViewer from "./DocumentGeneration/DocumentGenerationViewer";
import HubspotPageViewer from "./HubspotPage";
import QuestionnaireViewer from "./Questionnaire";

type TaskViewerProps = {
  task: TaskItemExtended;
};

const TaskViewer = ({ task }: TaskViewerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const segment = useSegment();
  const project = useZenProject();

  useLayoutEffect(() => {
    document.body.style.setProperty("background-color", "white");
  }, []);

  useEffect(() => {
    segment.track("zen task: displayed", {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      has_siren: project.hasCompanySiren,
      task_name: task.type.title,
      task_id: task.id,
      task_type: task.type.kind,
      task_category: task.type.category,
      task_class: task.taskClass,
      task_slug: task.type.slug,
      from: searchParams.get("utm_source"),
    });

    // Clear search params after use to clean the visible URL
    setSearchParams([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taskType = task.type.kind;

  if (isQuestionnaireTask(task))
    return (
      <QuestionnaireViewer
        questions={(task.details as QuestionnaireTaskDetails).questionnaire}
      />
    );

  if (isHubspotPageTask(task)) return <HubspotPageViewer task={task} />;

  if (isDocumentTask(task))
    return <DocumentGenerationViewer taskId={task.id} />;

  if (isWebinarTask(task))
    return <Text color="error.main">{`[TaskType] unhandled ${taskType}`}</Text>;

  return <Text color="error.main">{`[TaskType] unknown ${taskType}`}</Text>;
};

export default TaskViewer;
