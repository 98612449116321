import { PathParams, PathTemplate, RoutePath } from "services/router";

export const PATH_SLUGS = {
  ZEN: RoutePath.Zen,
  ONBOARDING: "onboarding",
  TASK: "task",
};

export const PATH_PARAMS = {
  LEGAL_ENTITY_ID: PathParams.LegalEntityId,
  DASHBOARD_TYPE: "dashboardType",
  TASK_ID: "taskId",
} as const;

const PATH_TEMPLATES_BASE = `/${PathTemplate.ZenApp}/:${PATH_PARAMS.DASHBOARD_TYPE}`;

type TPathParamKeys = keyof typeof PATH_PARAMS;
export type TPathParams = (typeof PATH_PARAMS)[TPathParamKeys];

export const PATH_TEMPLATES = {
  BASE: PATH_TEMPLATES_BASE,
  ONBOARDING: `${PATH_TEMPLATES_BASE}/${PATH_SLUGS.ONBOARDING}`,
  TASK_VIEW: `${PATH_TEMPLATES_BASE}/${PATH_SLUGS.TASK}/:${PATH_PARAMS.TASK_ID}`,
};
