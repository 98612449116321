import React from 'react';

type QuestionnaireContextState = {
  QuestionnaireComponent: JSX.Element | null;
  resumePageSlug: string | null;
  currentPage?: QuestionnaireTimelinePage;
  timelinePages?: QuestionnaireTimelinePage[];
  onComplete?: () => void;
  /** Display an overlay loader to prevent interaction on task UI when waiting for a long (time consuming) action */
  loaderVisible: boolean;
};

const initialState: QuestionnaireContextState = {
  QuestionnaireComponent: null,
  resumePageSlug: null,
  currentPage: undefined,
  timelinePages: undefined,
  onComplete: () => null,
  loaderVisible: false
};

type SetQuestionnaireComponentAction = {
  type: 'SET_QUESTIONNAIRE_COMPONENT';
  payload: {
    component: Required<QuestionnaireContextState['QuestionnaireComponent']>;
    resumePageSlug: QuestionnaireContextState['resumePageSlug'];
  };
};

type SetTimelinePagesAction = {
  type: 'SET_TIMELINE_PAGES';
  payload: QuestionnaireTimelinePage[];
};

type SetCurrentPageAction = {
  type: 'SET_CURRENT_PAGE';
  payload: QuestionnaireTimelinePage;
};

type SetOnCompleteAction = {
  type: 'SET_ON_COMPLETE';
  payload: QuestionnaireContextState['onComplete'];
};

type SetLoaderVisibleAction = {
  type: 'SET_LOADER_VISIBLE';
  payload: boolean;
};

type QuestionnaireDispatchAction =
  | SetQuestionnaireComponentAction
  | SetTimelinePagesAction
  | SetCurrentPageAction
  | SetOnCompleteAction
  | SetLoaderVisibleAction;

const reducer = (state: QuestionnaireContextState, action: QuestionnaireDispatchAction) => {
  switch (action.type) {
    case 'SET_QUESTIONNAIRE_COMPONENT':
      return {
        ...state,
        QuestionnaireComponent: action.payload.component,
        resumePageSlug: action.payload.resumePageSlug
      };
    case 'SET_TIMELINE_PAGES':
      return {
        ...state,
        timelinePages: action.payload
      };
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload
      };
    case 'SET_ON_COMPLETE':
      return {
        ...state,
        onComplete: action.payload
      };
    case 'SET_LOADER_VISIBLE':
      return {
        ...state,
        loaderVisible: action.payload
      };
    default:
      throw new Error('unexpected action type');
  }
};

type QuestionnaireContextType = {
  state: QuestionnaireContextState;
  dispatch: React.Dispatch<QuestionnaireDispatchAction>;
};

const QuestionnaireContext = React.createContext<QuestionnaireContextType>({
  state: initialState,
  dispatch: () => null
});

QuestionnaireContext.displayName = 'Questionnaire';

export { QuestionnaireContext, reducer, initialState };
