import OnboardingApiHandler from './handlers/onboarding';
import ProjectApiHandler from './handlers/projects';
import TaskApiHandler from './handlers/tasks';

const taskService = (projectId: Project['id']) => (taskId: TaskItem['id']) => {
  return {
    getOne: () => TaskApiHandler.getOne(projectId)(taskId),
    doAction: (actionSlug: Task.Action.Slug, data?: Task.Action.Request.Data) =>
      TaskApiHandler.doAction(projectId)(taskId, actionSlug, data)
  };
};

const onboardingService = (projectId: Project['id']) => {
  return {
    get: () => OnboardingApiHandler.get(projectId),
    patch: (data: Partial<OnboardingQuestionnaire>) => OnboardingApiHandler.patch(projectId)(data),
    markAsDone: () => OnboardingApiHandler.markAsDone(projectId),
    updateAnswers: (answers: QuestionnaireAnswers) =>
      OnboardingApiHandler.updateAnswers(projectId)(answers)
  };
};

const projectService = (projectId: Project['id']) => {
  return {
    getInfo: () => ProjectApiHandler.getInfo(projectId),
    // Ratings
    logZenRatings: (data: ZenRatingData) => ProjectApiHandler.logZenRatings(projectId, data),
    // Onboarding
    onboarding: onboardingService(projectId),
    // Task
    getTasks: (refresh?: boolean) => TaskApiHandler.getAll(projectId)(refresh),
    task: taskService(projectId)
  };
};

const ZenApiService = {
  project: projectService
};

export default ZenApiService;
