import { Button, Row, Text, Title } from "@yolaw/ui-kit-components";
import { ServiceObligationJuridique2V3 } from "@yolaw/ui-kit-icons";
import { useIsMobile, useSegment } from "hooks";
import lottie, { AnimationItem } from "lottie-web";
import animationSrc from "pages/zen/assets/animations/animation_kbis_zen.json";
import useZenProject from "pages/zen/hooks/useZenProject";
import { downloadKbisFile } from "pages/zen/utils/project";
import { useEffect } from "react";
import { ServicesService } from "services";
import styled, { css } from "styled-components";
import PdfViewer from "./PdfViewer";

const Header = styled(Row)`
  ${({ theme }) => css`
    background: ${theme.colors.accentuation.light};
    padding: ${theme.spacing.s}px;

    @media (max-width: ${theme.breakpoints.m}px) {
      && {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
      }
    }
  `}
`;

const Body = styled.div`
  max-width: 720px;
  margin-inline: auto;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.l}px;
    }
  `}
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);

  &.downloaded-animation-on {
    ${Header}, ${Body} {
      opacity: 0;
    }
  }
`;

const UnlockAnimation = styled.div`
  display: none;
  position: absolute;
  margin: ${({ theme }) => theme.spacing.m}px 0;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 300px;
`;

const Subtitle = () => {
  return <Text>Vous pouvez télécharger votre Kbis</Text>;
};

type KbisPreviewerProps = {
  onDownloadCompleted: () => void;
};

const KbisPreviewer = ({ onDownloadCompleted }: KbisPreviewerProps) => {
  const project = useZenProject();
  const isMobile = useIsMobile();
  const segment = useSegment();

  useEffect(() => {
    segment.track("kbis: displayed", { context: project.currentDashboard });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.currentDashboard]);

  const hideAnimation = (animation: AnimationItem) => {
    animation.destroy();

    onDownloadCompleted();
  };

  const showDownloadedAnimation = () => {
    // Hide other content
    const previewerElement = document.getElementById("kbis-previewer");
    previewerElement?.classList.add("downloaded-animation-on");

    // Display animation
    const animatedElement = document.getElementById("unlock-animation");
    if (animatedElement) {
      animatedElement.style.setProperty("display", "block");

      const animation = lottie.loadAnimation({
        container: animatedElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: animationSrc,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax slice",
        },
      });

      animation.addEventListener("complete", () => hideAnimation(animation));
    }
  };

  const downloadKbis = () => {
    downloadKbisFile(project.info);

    if (project.info.service_id) {
      // Notify Core Services that Kbis has been downloaded
      ServicesService.triggerEvent(
        project.info.service_id,
        "user_has_downloaded_kbis"
      );
    }

    segment.track("kbis: downloaded", {
      context: project.currentDashboard,
      from: "zen",
    });

    // Start animation
    showDownloadedAnimation();
  };

  if (!project.info.kbis_url) return null;

  return (
    <MainContainer className="kbis-previewer" id="kbis-previewer">
      <UnlockAnimation id="unlock-animation" />
      <Header className="kbis-preview_header">
        <ServiceObligationJuridique2V3 size="54px" />
        <div style={{ flex: 1 }}>
          <Title type="H4">Félicitations, votre société est créée !</Title>
          {!isMobile && <Subtitle />}
        </div>
        {isMobile && <Subtitle />}

        <Button leftIcon="DownloadFlat" sizing="small" onClick={downloadKbis}>
          Télécharger mon Kbis
        </Button>
      </Header>
      <Body className="kbis-preview_body">
        <PdfViewer fileUrl={project.info.kbis_url} />
      </Body>
    </MainContainer>
  );
};

export default KbisPreviewer;
