import { Column, Spinner } from '@yolaw/ui-kit-components';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled(Column)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

const OverlayLoader = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

export default OverlayLoader;
