import { FC } from "react";

import Placeholder from "../../components/Placeholder";
import PlaceholderSvg from "../../assets/banner/placeholder.svg";

const InvoicesPlaceholder: FC = () => {
  return (
    <Placeholder
      title="Vous n'avez pas de facture pour le moment"
      description="Vos futures factures pourront être téléchargées ici"
      image={<img src={PlaceholderSvg} alt="" />}
    />
  );
};

export default InvoicesPlaceholder;
