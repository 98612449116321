import * as UIKit from '@yolaw/ui-kit-components';
import _get from 'lodash/get';
import { nanoid } from 'nanoid';
import React, { ReactNode } from 'react';

type UndefinedComponentProps = {
  component: string;
  children?: ReactNode;
};
const UndefinedComponent = ({ component }: UndefinedComponentProps) => {
  return <h2>{`-- ${component} not implemented --`}</h2>;
};

const commonComponents = {
  ...UIKit
};

export const componentBuilder = async (
  yamlContent: QuestionnaireComponents,
  extraComponents?: { [componentName: string]: React.ReactNode }
) => {
  const { component, elements, ...props } = yamlContent;

  props.key = nanoid();

  const Component = _get(
    { ...commonComponents, ...extraComponents },
    [component],
    UndefinedComponent
  );

  if (elements === undefined) {
    return <Component component={component} {...props} />;
  }

  const promises = elements.map((child: QuestionnaireComponents) =>
    componentBuilder(child, extraComponents).then((JsxElement) => JsxElement)
  );
  const children = await Promise.all(promises);

  return (
    <Component component={component} {...props}>
      {children}
    </Component>
  );
};
