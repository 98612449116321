import { Button, Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import { UniversalPerson1 } from "@yolaw/ui-kit-icons";
import { SvgIconProps } from "@yolaw/ui-kit-icons/types/types/SvgIconProps";
import styled, { css } from "styled-components";
import UniversalDevisbill from "./assets/$yolaw-icon-universal-devisbill.svg";
import UniversalDashboard from "./assets/$yolaw-icon-universal-dashboard.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  container-type: inline-size;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.m}px;
    background-color: ${theme.colors.tertiary.main};

    @container (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.xs}px;
    }
    @container (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.m}px;
      align-items: flex-start;
    }
  `}
`;

const CardList = styled(Row).attrs({ as: "ul" })`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        flex-direction: column;
      }
    }
    @container (min-width: ${theme.breakpoints.m}px) {
    }
  `}
`;

const InfoCardContainer = styled(Column).attrs({ as: "li" })`
  background-color: white;
  ${({ theme }) => css`
    flex: 1;
    border-radius: ${theme.borderRadius.s}px;
    padding: ${theme.spacing.xs}px;

    && {
      .icon-title-container {
        align-items: center;
        justify-content: flex-start;
      }
      .icon-container {
        min-width: 40px;
      }
    }
  `}
`;

type InfoCardProps = {
  Icon: ((props: SvgIconProps) => JSX.Element) | string;
  title: string;
  description: string;
};
const InfoCard = ({ Icon, title, description }: InfoCardProps) => {
  const CardIcon =
    typeof Icon === "string" ? (
      <img src={Icon} alt="card-icon" />
    ) : (
      <Icon size={"40"} />
    );

  return (
    <InfoCardContainer>
      <Row className="icon-title-container">
        <div className="icon-container">{CardIcon}</div>
        <Title type="H4">{title}</Title>
      </Row>
      <Text color="neutral.darker">{description}</Text>
    </InfoCardContainer>
  );
};

export const LSComptaProInfo = () => {
  const openLSComptaProURL = () =>
    window.open("https://www.legalstart.fr/ls-compta-expertise-comptable/");

  return (
    <MainContainer>
      <Column>
        <Title type="H3">
          Besoin d’un expert-comptable ? Passez à LS Compta Pro
        </Title>
        <Text>
          Une relation repensée : votre interlocuteur LS Compta est disponible
          au téléphone et par mail
        </Text>
      </Column>
      <Button
        variant="secondary"
        text="Découvrir LS Compta PRO"
        onClick={openLSComptaProURL}
      />

      <CardList>
        <InfoCard
          Icon={UniversalPerson1}
          title="Suivi avec un expert-comptable"
          description="Il vous aidera dans la gestion quotidienne de votre entreprise."
        />
        <InfoCard
          Icon={UniversalDevisbill}
          title="Réalisation de vos obligations"
          description="Comptabilité : Bilan, liasse fiscale, déclaration de TVA, etc..."
        />
        <InfoCard
          Icon={UniversalDashboard}
          title="Plateforme intelligente en ligne"
          description="Divisez par trois le temps dédié à la gestion financière et à la comptabilité."
        />
      </CardList>
    </MainContainer>
  );
};

export default LSComptaProInfo;
